import { FC, ReactNode } from 'react'

import './SpinnerPM.css'

type SPMProps = {
  children?: ReactNode
}
const SpinnerPM: FC<SPMProps> = ({ children }) => (
  <div className='spinner-pm text-secondary-darker'>
    <div className='spinner-content'>{children}</div>
  </div>
)

export default SpinnerPM
