import { useState, useCallback } from 'react'

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen])
  const closeModal = useCallback(() => setIsModalOpen(false), [setIsModalOpen])
  const toggleModal = useCallback(() => setIsModalOpen(prevValue => !prevValue), [setIsModalOpen])

  return {
    isModalOpen,
    openModal,
    closeModal,
    toggleModal
  }
}

export default useModal
