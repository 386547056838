const countryCodeToCountryNameMap: Record<string, Record<string, string>> = {
  de: {
    'en-us': 'Germany',
    'de-de': 'Deutschland',
    'ch-de': 'Deutschland',
    'fr-fr': 'Allemagne',
    'ch-fr': 'Allemagne',
    'it-it': 'Germania'
  },
  ch: {
    'en-us': 'Switzerland',
    'de-de': 'Schweiz',
    'ch-de': 'Schweiz',
    'fr-fr': 'Suisse',
    'ch-fr': 'Suisse',
    'it-it': 'Svizzera'
  },
  fr: {
    'en-us': 'France',
    'de-de': 'Frankreich',
    'ch-de': 'Frankreich',
    'fr-fr': 'France',
    'ch-fr': 'France',
    'it-it': 'Francia'
  },
  it: {
    'en-us': 'Italy',
    'de-de': 'Italien',
    'ch-de': 'Italien',
    'fr-fr': 'Italie',
    'ch-fr': 'Italie',
    'it-it': 'Italia'
  }
}

export type CountryCodes = 'de' | 'ch' | 'fr' | 'it'

export const getCountryName = (countryCode: string, localeCode: string) => {
  const country = countryCode?.toLowerCase()?.trim() || 'de'
  const language = localeCode?.toLowerCase()?.trim() || 'en-us'

  return countryCodeToCountryNameMap?.[country]?.[language] ?? countryCode
}
