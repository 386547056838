import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'
import { useSearchParams } from 'react-router-dom'

import config from 'config/index'
import useLocalStorage from 'hooks/useLocalStorage'

export interface IPrivacyConsent {
  readonly isCookieConsentGiven: boolean
  readonly isCookieConsentDefinedByUser: boolean
  readonly isPassedByQueryParam?: boolean
}

export type TSetConsent = Dispatch<SetStateAction<IPrivacyConsent | null>>

export interface IPrivacyContext extends IPrivacyConsent {
  readonly setConsent: TSetConsent
  readonly isCookieConsentModalVisible: boolean
  readonly setIsCookieConsentModalVisible: Dispatch<SetStateAction<boolean>>
}

export const PrivacyContext = createContext<IPrivacyContext | null>(null)

type PCPProps = {
  readonly children: ReactNode
}

export const PrivacyProvider: FC<PCPProps> = ({ children }) => {
  const [searchParams] = useSearchParams()
  const telemetryConsentSearchParam = searchParams.get('tc')

  const [consent, setConsent] = useLocalStorage<IPrivacyConsent>(config.telemetryConsentKey, {
    isCookieConsentGiven: false,
    isCookieConsentDefinedByUser: false
  })

  const { isCookieConsentGiven, isCookieConsentDefinedByUser } = consent

  const [isCookieConsentModalVisible, setIsCookieConsentModalVisible] = useState(
    () => !isCookieConsentGiven && !isCookieConsentDefinedByUser
  )

  useEffect(() => {
    if (telemetryConsentSearchParam === 'true' && !isCookieConsentGiven)
      setConsent(() => ({
        isCookieConsentGiven: true,
        isCookieConsentDefinedByUser: false,
        isPassedByQueryParam: true
      }))
  }, [isCookieConsentGiven, telemetryConsentSearchParam, setConsent])

  return (
    <PrivacyContext.Provider
      value={{
        setConsent,
        ...consent,
        isCookieConsentModalVisible,
        setIsCookieConsentModalVisible
      }}
    >
      {children}
    </PrivacyContext.Provider>
  )
}

export const usePrivacyContext = () => {
  const context = useContext(PrivacyContext)

  if (context === null) {
    throw new Error('usePrivacyContext must be used within PrivacyProvider')
  }
  return context
}
