import { FC, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import { FaRegCheckCircle } from 'react-icons/fa'
import { RxCrossCircled } from 'react-icons/rx'
import { BiErrorAlt } from 'react-icons/bi'

import type { CheckoutConfirmationCta, CheckoutConfirmationStatus } from 'typings/checkoutApi'
import { useAuthContext } from 'context/auth'
import { useTelemetryContext } from 'context/insights'
import { useAppContext } from 'context/app'
import { useCheckoutContext } from 'context/checkout'
import { containerVariants, contentVariants } from './CheckoutConfirmationPage.motion'
import ConfirmationPageActions from 'components/checkout/ConfirmationPageActions'

const CheckoutConfirmationPage: FC = () => {
  const [searchParams] = useSearchParams()
  const { t: translated } = useTranslation()
  const { isCustomer, partnerId } = useAuthContext()
  const { appInsights } = useTelemetryContext()
  const { shopId, cartId } = useAppContext()
  const { goesToVirtualWarehouse } = useCheckoutContext()

  const checkoutConfirmationStatus = searchParams.get('status') as CheckoutConfirmationStatus
  const ctaSearchParam = searchParams.get('cta') as CheckoutConfirmationCta | null

  useEffect(() => {
    if (!shopId || !cartId || !partnerId || !checkoutConfirmationStatus || !appInsights) return

    appInsights?.trackEvent({
      name: 'CheckoutPaymentConfirmation',
      properties: {
        isTeamPartner: !isCustomer,
        customerId: partnerId,
        shopId,
        cartId,
        status: checkoutConfirmationStatus,
        cta: ctaSearchParam
      }
    })
  }, [
    appInsights,
    cartId,
    checkoutConfirmationStatus,
    ctaSearchParam,
    isCustomer,
    partnerId,
    shopId
  ])

  const icon =
    checkoutConfirmationStatus === 'success' ? (
      <FaRegCheckCircle className='text-success' />
    ) : checkoutConfirmationStatus === 'cancel' ? (
      <RxCrossCircled className='text-warn' />
    ) : (
      <BiErrorAlt className='text-error' />
    )

  const title =
    goesToVirtualWarehouse && checkoutConfirmationStatus === 'success'
      ? translated('Congratulations!')
      : goesToVirtualWarehouse && checkoutConfirmationStatus === 'cancel'
      ? translated('Virtual Warehouse commitment cancelled.')
      : checkoutConfirmationStatus === 'success'
      ? translated('Thank you for your purchase!')
      : checkoutConfirmationStatus === 'failure'
      ? translated('Your purchase has failed.')
      : checkoutConfirmationStatus === 'cancel'
      ? translated('You have cancelled your purchase.')
      : translated('Something went wrong')

  const subtitle =
    goesToVirtualWarehouse && checkoutConfirmationStatus === 'success'
      ? translated(
          'You have products in your virtual warehouse inventory. Create baskets and sell products.'
        )
      : goesToVirtualWarehouse && checkoutConfirmationStatus === 'cancel'
      ? null
      : checkoutConfirmationStatus === 'success'
      ? translated('You will receive your confirmation by email shortly.')
      : checkoutConfirmationStatus === 'failure'
      ? null
      : checkoutConfirmationStatus === 'cancel'
      ? null
      : translated('Try submitting your order again!')

  return (
    <motion.div
      className={
        'flex-1 container mx-auto p-2 sm:p-4 overflow-hidden ' + 'grid place-content-center'
      }
      variants={containerVariants}
    >
      <motion.div
        className={
          'w-full max-w-7xl mx-auto md:py-6 sm:px-6 lg:px-8 overflow-hidden ' + 'grid items-center'
        }
        variants={contentVariants}
      >
        <div className='text-center'>
          <div className='mb-8 grid place-content-center text-9xl'>{icon}</div>
          {title ? <h1 className='text-xl font-bold'>{title}</h1> : null}
          {subtitle ? <p className='my-4'>{subtitle}</p> : null}
          <ConfirmationPageActions />
        </div>
      </motion.div>
    </motion.div>
  )
}

export default CheckoutConfirmationPage
