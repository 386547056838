// based on:
// https://blog.logrocket.com/using-localstorage-react-hooks/

import { useEffect, useState } from 'react'

//----------------------------------------------------------------------------//

type TStoredFn = <T = string>(key: string, initialValue: T) => T
type TPersistFn = <T = string>(key: string, value: T) => T
type TRemoveFn = (key: string) => void

const getValueFromLocalStorage: TStoredFn = (key, initialValue) => {
  if (typeof window === 'undefined') return initialValue
  const saved = window.localStorage.getItem(key)
  return saved ? JSON.parse(saved) : initialValue
}

const saveValueInLocalStorage: TPersistFn = (key, value) => {
  if (typeof window === 'undefined') return value
  window.localStorage.setItem(key, JSON.stringify(value))
  return value
}

const removeLocalStorageValue: TRemoveFn = key => {
  if (typeof window === 'undefined') return null
  if (window.localStorage.getItem(key)) window.localStorage.removeItem(key)
  return null
}

//----------------------------------------------------------------------------//

/**
 * local storage hook to easialy store and retrive a value
 * @param {string} key
 * @param {T} initialValue
 */
const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [value, setValue] = useState<T | null>(() => getValueFromLocalStorage(key, initialValue))

  useEffect(() => {
    if (value == null) {
      removeLocalStorageValue(key)
    } else {
      saveValueInLocalStorage(key, value)
    }
  }, [key, value])

  return [value, setValue] as [T, typeof setValue]
}

export default useLocalStorage
