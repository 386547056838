import { FC, useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { BsFillShieldLockFill } from 'react-icons/bs'

import { useAuthContext } from 'context/auth'

import ScreenCover from 'components/common/ScreenCover'
import SpinnerPM from 'components/common/SpinnerPM'

type ProtectedRouteProps = {
  readonly children: JSX.Element
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate()
  const { localeCode: localeCodePathParam } = useParams()
  const {
    getUser,
    isAuthenticated,
    isAuthLoading,
    setUserData,
    signIn,
    removeUser,
    userManagerLanguage,
    updateAuthLanguage
  } = useAuthContext()
  const hasRetrievedUserDataRef = useRef(false)

  useEffect(() => {
    // prevent auth attempts until the language has been updated in the user manager
    if (localeCodePathParam && userManagerLanguage !== localeCodePathParam) {
      return updateAuthLanguage(localeCodePathParam)
    }

    // attemp authentication & render content if successful or kick user out if auth fails
    ;(async () => {
      // use hasRetrievedUserDataRef to prevent signin from being called multiple times
      if (!isAuthenticated && !isAuthLoading && !hasRetrievedUserDataRef.current) {
        hasRetrievedUserDataRef.current = true
        try {
          const user = await getUser()
          if (user?.access_token && user?.expired === false) return setUserData(user)
          await signIn()
        } catch (error) {
          console.error('error on PR: ', error)
          removeUser()
        }
      }
    })()
  }, [
    getUser,
    isAuthLoading,
    isAuthenticated,
    localeCodePathParam,
    navigate,
    removeUser,
    setUserData,
    signIn,
    updateAuthLanguage,
    userManagerLanguage
  ])

  return isAuthenticated ? (
    children
  ) : (
    <ScreenCover>
      <SpinnerPM>
        <BsFillShieldLockFill size={25} />
      </SpinnerPM>
    </ScreenCover>
  )
}

export default ProtectedRoute
