export const getCheckoutStep = (pathname: string) => {
  if (pathname.endsWith('/summary')) {
    return 'summary'
  } else if (pathname.endsWith('/mm')) {
    return 'midnightMadness'
  } else if (pathname.endsWith('/address')) {
    return 'address'
  } else if (pathname.endsWith('/delivery')) {
    return 'delivery'
  } else if (pathname.endsWith('/destination')) {
    return 'destination'
  } else if (pathname.endsWith('/confirmation')) {
    return 'payment'
  } else {
    return 'cart'
  }
}
