// references:
// https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-framework-extensions?tabs=react

import { createContext, FC, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import {
  ApplicationInsights,
  ITelemetryItem,
  ITelemetryPlugin
} from '@microsoft/applicationinsights-web'
import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js'

import { usePrivacyContext } from 'context/privacy'

interface ITelemetryContext {
  readonly appInsights: ApplicationInsights | undefined
}

type AICPProps = {
  readonly children: ReactNode
}

const appInsightsAppId =
  (process.env.REACT_APP_AI_APP_ID ?? 'unknown') +
  (window?.location?.hostname === 'localhost' ? ' - localhost' : '')
const instrumentationKey = process.env.REACT_APP_AI_KEY ?? ''

// MS Application Insights must be re-initialized if it was previously terminated with `.unload()`
const getAppInsights = () => {
  const reactPlugin = new ReactPlugin()

  return {
    reactPlugin,
    appInsights: new ApplicationInsights({
      config: {
        instrumentationKey,
        appId: appInsightsAppId,
        enableAutoRouteTracking: true,
        // type-casting ReactPlugin to ITelemetryPlugin is necessary because of a bug:
        // https://github.com/microsoft/applicationinsights-react-js/issues/32
        // TODO: please remove the forced type-casting when the bug is fixed!!!
        extensions: [reactPlugin as unknown as ITelemetryPlugin],
        extensionConfig: {}
      }
    })
  }
}

export const TelemetryContext = createContext<ITelemetryContext | null>(null)

export const TelemetryProvider: FC<AICPProps> = ({ children }) => {
  const { isCookieConsentGiven } = usePrivacyContext()
  const reactPluginInstanceRef = useRef<ReactPlugin>(new ReactPlugin())
  const appInsightsInstanceRef = useRef<ApplicationInsights>()
  const [isAppInsightsRunning, setIsAppInsightsRunning] = useState(false)

  useEffect(() => {
    if (isCookieConsentGiven && !isAppInsightsRunning) {
      // the previously initialized React plugin must be torn down
      reactPluginInstanceRef.current.teardown()
      // MS Application Insights & all its plugins must be re-initialized every time it is started
      const { reactPlugin, appInsights } = getAppInsights()
      reactPluginInstanceRef.current = reactPlugin
      appInsightsInstanceRef.current = appInsights
      appInsightsInstanceRef.current.loadAppInsights()
      appInsightsInstanceRef.current.addTelemetryInitializer((env: ITelemetryItem) => {
        env.tags = env.tags || []
        env.tags['ai.cloud.role'] = appInsightsAppId
      })
      setIsAppInsightsRunning(true)
      if (process.env.NODE_ENV === 'development')
        console.info(
          '%cMSAI%c initialized',
          'color: cornflowerblue; font-weight: bold; font-size: 0.75rem;',
          'color: teal;'
        )
    } else if (!isCookieConsentGiven && isAppInsightsRunning && appInsightsInstanceRef.current) {
      appInsightsInstanceRef.current.unload(true, () => {
        reactPluginInstanceRef.current.teardown()
        appInsightsInstanceRef.current = undefined
        setIsAppInsightsRunning(false)
        if (process.env.NODE_ENV === 'development')
          console.info(
            '%cMSAI%c terminated',
            'color: cornflowerblue; font-weight: bold; font-size: 0.75rem;',
            'color: tomato;'
          )
      })
    }
  }, [isAppInsightsRunning, isCookieConsentGiven])

  return (
    <TelemetryContext.Provider
      value={{
        appInsights: appInsightsInstanceRef.current
      }}
    >
      <AppInsightsContext.Provider value={reactPluginInstanceRef.current}>
        {children}
      </AppInsightsContext.Provider>
    </TelemetryContext.Provider>
  )
}

export const useTelemetryContext = () => {
  const context = useContext(TelemetryContext)

  if (context === null) {
    throw new Error('useTelemetryContext must be used within TelemetryProvider')
  }
  return context
}
