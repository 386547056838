import { FC, FormEvent, useCallback, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js'

import type { ILocale } from 'typings/languageTypes'

import { showToast } from 'components/layout/ToastNotification'
import CheckIcon from 'components/common/icons/CheckIcon'
import Modal from 'components/common/Modal'
import BasicButton from 'components/common/buttons/BasicButton'

type LSProps = {
  isOpen: boolean
  onClose?: () => void
  locales: ILocale[]
}

const LanguageSelection: FC<LSProps> = ({ isOpen, onClose, locales }) => {
  const navigate = useNavigate()
  const { hash, pathname, search } = useLocation()
  const { localeCode: localeCodePathParam } = useParams()
  const { t: translated } = useTranslation()
  const appInsights = useAppInsightsContext()

  const [codeSelectedLocale, setCodeSelectedLocale] = useState<string | null>(() =>
    typeof localeCodePathParam === 'string' &&
    locales?.some(
      ({ code: languageListCode }) =>
        languageListCode?.toLowerCase() === localeCodePathParam?.toLowerCase()
    )
      ? localeCodePathParam
      : locales?.[0]?.code
  )

  const handleFormSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      if (localeCodePathParam == null || localeCodePathParam === codeSelectedLocale) return
      if (!codeSelectedLocale) {
        return showToast({
          type: 'error',
          title: translated('Select a language')
        })
      }

      appInsights.trackEvent({
        name: 'LocaleSelection',
        properties: { previousLocale: localeCodePathParam, nextLocale: codeSelectedLocale }
      })

      let newPath = pathname.replace(localeCodePathParam, codeSelectedLocale)
      if (search) newPath += search
      if (hash) newPath += hash
      navigate(newPath)
      onClose?.()
    },
    [
      appInsights,
      codeSelectedLocale,
      hash,
      localeCodePathParam,
      navigate,
      onClose,
      pathname,
      search,
      translated
    ]
  )

  return (
    <Modal isOpen={isOpen} onClose={onClose} size='sm' title={translated('Select a language')}>
      <form className='w-full box-border grid grid-cols-2 gap-2 pt-1' onSubmit={handleFormSubmit}>
        {locales.map(({ code, name }) => {
          const isSelectedLocale = codeSelectedLocale?.toLowerCase() === code.toLowerCase()
          return (
            <div key={code}>
              <input
                type='radio'
                checked={isSelectedLocale}
                name={`locale-option-${code}`}
                id={`locale-option-${code}`}
                className='sr-only peer'
                onChange={() => setCodeSelectedLocale(code)}
              />
              <label
                htmlFor={`locale-option-${code}`}
                className={
                  'flex items-center justify-between p-2 px-3 rounded border' +
                  ' text-left font-normal' +
                  (isSelectedLocale
                    ? ' border-gray-400 bg-gray-100'
                    : ' border-gray-300 hover:border-gray-400 hover:bg-gray-50') +
                  ' peer-focus:ring  peer-focus-visible:ring' +
                  ' cursor-pointer'
                }
              >
                {name}
                {isSelectedLocale && <CheckIcon className='w-3' />}
              </label>
            </div>
          )
        })}

        <BasicButton className='mt-4 col-span-2 font-normal' type='submit'>
          {translated('Confirm')}
        </BasicButton>
      </form>
    </Modal>
  )
}

export default LanguageSelection
