import { Fragment, PropsWithChildren } from 'react'

import { Listbox, Transition } from '@headlessui/react'
import { HiCheck, HiChevronUpDown } from 'react-icons/hi2'

type SelectProps<T> = {
  readonly options: T[]
  readonly selected: T
  readonly setSelected: (selectedOption: T) => void
  readonly className?: string
}

const Select = <T extends string | number>({
  options,
  selected,
  setSelected,
  className
}: PropsWithChildren<SelectProps<T>>) => {
  return (
    <Listbox value={selected} onChange={setSelected}>
      <div className={'relative' + (className ? ` ${className}` : '')}>
        <Listbox.Button
          className={
            'relative w-full py-1 pl-3 pr-8' +
            ' rounded-lg bg-white shadow text-sm' +
            ' focus:outline-none focus-visible:border-indigo-500' +
            ' focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300' +
            ' cursor-default text-left'
          }
        >
          <span className='block truncate'>{selected}</span>

          <span className='pointer-events-none absolute inset-y-0 right-2 flex items-center'>
            <HiChevronUpDown className='h-5 w-5 text-gray-400' aria-hidden='true' />
          </span>
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options
            className={
              'absolute max-h-60 w-full mt-1 py-1 overflow-auto' +
              ' rounded-md bg-white text-sm shadow-lg' +
              ' ring-1 ring-black ring-opacity-5 focus:outline-none'
            }
          >
            {options.map((option, index) => (
              <Listbox.Option
                key={index}
                value={option}
                className={({ active }) =>
                  'relative cursor-default select-none py-2 pl-6 pr-4' +
                  (active ? ' bg-secondary text-secondary-darkest' : ' text-gray-900')
                }
              >
                {({ selected }) => (
                  <>
                    <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                      {option}
                    </span>

                    {selected ? (
                      <span className='absolute inset-y-0 left-0 flex items-center pl-2 text-primary'>
                        <HiCheck className='h-3 w-3' aria-hidden='true' />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  )
}

export default Select
