export const containerVariants = {
  hidden: {
    opacity: 0,
    transition: { when: 'afterChildren', duration: 0.1 }
  },
  visible: {
    opacity: 1,
    transition: { when: 'beforeChildren', duration: 0.1, staggerChildren: 0.5 }
  }
}

export const mainContentVariants = {
  hidden: {
    opacity: 0,
    transition: { when: 'afterChildren', duration: 0.1 }
  },
  visible: {
    opacity: 1,
    transition: { when: 'beforeChildren', duration: 0.1 }
  }
}
