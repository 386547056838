export const formatDate = (date: Date, locale: string) =>
  date.toLocaleString(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  })

export const formatTime = (date: Date, locale: string) =>
  date.toLocaleString(locale, {
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short'
  })
