import type { IPromoBanner } from 'typings/shopApi'
import PMIWeb from '@pmi.web/ui'

const PromoBanner = ({ data }: { data: IPromoBanner[] }) => {
  return (
    <PMIWeb.Components.BannerCarousel autoplay>
      {data?.map(banner => (
        <PMIWeb.Components.Banner
          key={banner.id}
          title={banner.title}
          description={banner.details}
          countdownTo={banner?.countdown ? new Date(banner.countdown) : undefined}
          // countdownRenderer={p => <BannerCountdownRenderer {...p} />}
          // onCta={() => onBannerCtaClick(banner.id)}
          ctaLabel={banner?.cta?.label}
          ctaUrl={banner?.cta?.url}
        />
      ))}
    </PMIWeb.Components.BannerCarousel>
  )
}

export default PromoBanner
