import { FC } from 'react'
import { Navigate, Outlet, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import type { CheckoutConfirmationStatus } from 'typings/checkoutApi'
import { formatPrice } from 'utils/priceUtils'
import { getCheckoutStep } from 'utils/checkoutUtils'
import { useCheckoutContext } from 'context/checkout'
import { useAuthContext } from 'context/auth'

import { footerVariants } from './CheckoutLayout.motion'
import PersistentPortal from 'components/common/PersistentPortal'
import ScreenCover from 'components/common/ScreenCover'
import SpinnerPM from 'components/common/SpinnerPM'
import CloudDownloadIcon from 'components/common/icons/CloudDownloadIcon'
import VWCheckoutSteps from 'components/layout/VWCheckoutSteps'
import MessageBox from 'components/common/MessageBox'
import AnimatedNumber from 'components/effects/AnimatedNumber'
import PMPoints from 'components/common/PMPoints'

const VWCheckoutLayout: FC = () => {
  const location = useLocation()
  const { localeCode: localeCodePathParam, vcartId: vcartIdPathParam } = useParams()
  const { t: translated } = useTranslation()
  const [searchParams] = useSearchParams()
  const { isCustomer } = useAuthContext()
  const {
    cartStatus,
    isBasketLoading,
    isBasketQueryError,
    basketQueryError,
    cartSubtotal,
    totalPaymentDue,
    cartCurrency,
    cartPoints,
    affiliateDiscount
  } = useCheckoutContext()

  const checkoutConfirmationStatus = searchParams.get('status') as CheckoutConfirmationStatus
  const isSummaryPage = location.pathname.endsWith('/summary')
  const isConfirmationPage = location.pathname.endsWith('/confirmation')
  const totalAmountToDisplay = isSummaryPage ? totalPaymentDue : cartSubtotal

  if (isBasketLoading)
    return (
      <ScreenCover>
        <SpinnerPM>
          <CloudDownloadIcon />
        </SpinnerPM>
      </ScreenCover>
    )

  if (isBasketQueryError)
    return basketQueryError?.status === 404 ? (
      <div className='w-full grid place-content-center'>
        <MessageBox type='error' text={basketQueryError.title} showIcon={true} />
      </div>
    ) : basketQueryError?.status === 403 ? (
      <div className='w-full grid place-content-center'>
        <MessageBox
          type='error'
          text={translated('You are not authorized to access this shopping basket.')}
          showIcon={true}
        />
      </div>
    ) : (
      <div className='w-full grid place-content-center'>
        <MessageBox type='error' text={JSON.stringify(basketQueryError, null, 2)} showIcon={true} />
      </div>
    )

  /**
   * If the basket has already been paid or canceled, show the user only the cart content page
   */
  if (
    ['paymentAuthorized', 'canceled'].includes(cartStatus ?? '') &&
    !checkoutConfirmationStatus &&
    getCheckoutStep(location.pathname) !== 'cart'
  )
    return (
      <Navigate
        to={{
          pathname: `/${localeCodePathParam}/vcart/${vcartIdPathParam}`,
          search: location.search
        }}
        replace={true}
      />
    )

  /**
   * Users can be on confirmation page only if cart status is 'paymentPending' or 'paymentAuthorized'
   */
  if (isConfirmationPage && ['open', 'canceled'].includes(cartStatus ?? ''))
    return (
      <Navigate
        to={{
          pathname: `/${localeCodePathParam}/vcart/${vcartIdPathParam}`,
          search: location.search
        }}
        replace={true}
      />
    )

  /**
   * Skip the midnight madness step / page / route, because it does not exist in the VW checkout flow
   * Redirection away from the midnight-madness step depends on whether the user navigated to this
   * step forward, from the previous or backward, from the succeeding step. Information about which step the user
   * came from is passed through the react-router state property.
   */
  if (location.pathname?.endsWith('mm')) {
    console.info(
      'redirecting from midnight madness page, because midnight madness step does not exist in the VW checkout flow'
    )
    return (
      <Navigate
        to={{
          // if the user came from the "checkout address" step, skip this one and go on to the "checkout summary" step
          // otherwise, go back to the "checkout address" step
          pathname: location?.state?.source === 'checkout-address' ? './summary' : './address',
          search: location.search
        }}
        replace={true}
      />
    )
  }

  return (
    <>
      <PersistentPortal containerElementSelector='#header-additional-content'>
        <VWCheckoutSteps />
      </PersistentPortal>

      <Outlet />

      {!isConfirmationPage && (
        <PersistentPortal containerElementSelector='#sticky-footer'>
          <motion.div
            className={
              'w-full max-w-3xl mx-auto overflow-hidden' +
              ' py-2 sm:py-3 px-3' +
              ' space-y-2 sm:space-y-3' +
              ' bg-secondary'
            }
            variants={footerVariants}
          >
            {/* --------------- totals: start --------------- */}
            <div>
              <div className='flex justify-between items-center'>
                <span>{translated('Total')}</span>
                <AnimatedNumber
                  value={formatPrice(totalAmountToDisplay, cartCurrency, localeCodePathParam ?? '')}
                  className='font-bold'
                />
              </div>
              {!!affiliateDiscount && (
                <p className='mt-0 text-right text-sm text-gray-500'>
                  {translated(
                    'Including a one-time discount of {{discountAmount}} on eligible products',
                    {
                      discountAmount: `${affiliateDiscount}%`
                    }
                  )}
                </p>
              )}
            </div>

            {isCustomer ? null : (
              <div className='flex justify-between items-center'>
                <span>{translated('Points')}</span>
                <PMPoints value={cartPoints} />
              </div>
            )}
            {/* --------------- totals: end --------------- */}

            {/* --------------- terms & conditions: start --------------- */}
            <div id='footer-terms' className='w-full' />
            {/* --------------- terms & conditions: end --------------- */}

            {/* --------------- button container: start --------------- */}
            <div id='footer-buttons' className='w-full' />
            {/* --------------- button container: end --------------- */}
          </motion.div>
        </PersistentPortal>
      )}
    </>
  )
}

export default VWCheckoutLayout
