import { FC } from 'react'
import { motion } from 'framer-motion'

import AnimatedDigit from './AnimatedDigit'

type ANProps = {
  value: number | string
  className?: string
}

const isSingleDigit = (s: string) => /\d/.test(s)

const AnimatedNumber: FC<ANProps> = ({ value, className }) => (
  <motion.span
    layout
    className={'relative h-full inline-flex overflow-hidden' + (className ? ` ${className}` : '')}
  >
    {String(value)
      .split('')
      .map((numChar, index) =>
        isSingleDigit(numChar) ? (
          <AnimatedDigit key={`digit-${index}-${numChar}`} digit={numChar} />
        ) : (
          <span key={`separator-${index}-${numChar}`}>{numChar}</span>
        )
      )}
  </motion.span>
)

export default AnimatedNumber
