import { FC, useState } from 'react'

import { usePrivacyContext } from 'context/privacy/PrivacyContext'

import Modal from 'components/common/Modal'
import CookiePrompt from './CookiePrompt'
import CookieSettings from './CookieSettings'

const CookieConsentModal: FC = () => {
  const { isCookieConsentModalVisible } = usePrivacyContext()
  const [displayCookieSettings, setDisplayCookieSettings] = useState(false)

  return (
    <Modal
      contentClassName='transition-all'
      size='lg'
      allowEasyClose={false}
      isOpen={isCookieConsentModalVisible}
    >
      {displayCookieSettings ? (
        <CookieSettings />
      ) : (
        <CookiePrompt setDisplayCookieSettings={setDisplayCookieSettings} />
      )}
    </Modal>
  )
}

export default CookieConsentModal
