import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useSearchParams } from 'react-router-dom'

export type ThemeType = 'fitline' | 'pm'
export const themeOptionList: ThemeType[] = ['fitline', 'pm']

export interface IThemeContext {
  readonly theme: ThemeType
  readonly setTheme: Dispatch<ThemeType>
}

type TPProps = {
  readonly children?: ReactNode
}

const ThemeContext = createContext<IThemeContext | null>(null)

const defaultTheme = 'fitline'
const themeColor = {
  fitline: '#cd0039',
  pm: '#004b8d'
}
const getIconDirNameBasedOnTheme = (theme: ThemeType) => (theme === 'fitline' ? 'fl' : 'pm')
const isValidTheme = (allegedTheme: string): allegedTheme is ThemeType =>
  ['fitline', 'pm'].includes(allegedTheme)

export const ThemeProvider: FC<TPProps> = ({ children }) => {
  const [searchParams] = useSearchParams()
  const themeQueryParam = searchParams.get('theme')
  const themeOverride = isValidTheme(themeQueryParam ?? '') ? (themeQueryParam as ThemeType) : null

  // Initiate the theme based on the relevant query param override; otherwise default to 'fitline'
  const [theme, setTheme] = useState<ThemeType>(themeOverride ?? defaultTheme)

  useEffect(() => {
    if (theme) {
      // set `data-pmiweb-theme` attribute used to set custom CSS variables
      const bodyElement = document.getElementsByTagName('body').item(0)
      if (bodyElement) bodyElement.setAttribute('data-pmiweb-theme', theme)

      // update "theme-color" <meta> tag
      const themeMetaTag = document.querySelector('meta[name="theme-color"]')
      if (themeMetaTag) themeMetaTag.setAttribute('content', themeColor[theme])

      // Update "description" <meta> tag
      const descriptionMetaElement = document.querySelector('meta[name="description"]')
      if (descriptionMetaElement)
        descriptionMetaElement.setAttribute(
          'content',
          theme === 'fitline' ? 'FitLine Shop' : 'PM Shop'
        )

      // update favicon.ico
      const faviconLinkElement = document.querySelector('link[rel="icon"]')
      if (faviconLinkElement)
        faviconLinkElement.setAttribute('href', `/${getIconDirNameBasedOnTheme(theme)}/favicon.ico`)

      // update Apple touch icon
      const appleIconLinkElement = document.querySelector('link[rel="apple-touch-icon"]')
      if (appleIconLinkElement)
        appleIconLinkElement.setAttribute(
          'href',
          `/${getIconDirNameBasedOnTheme(theme)}/logo-192.png`
        )

      // update manifest.json
      const manifestLinkElement = document.querySelector('link[rel="manifest"]')
      if (manifestLinkElement)
        manifestLinkElement.setAttribute(
          'href',
          `/${getIconDirNameBasedOnTheme(theme)}/logo-192.png`
        )

      // set <title> tag
      const titleElement = document.querySelector('head title')
      if (titleElement) titleElement.textContent = theme === 'fitline' ? 'FitLine Shop' : 'PM Shop'
    }
  }, [theme])

  /**
   * The theme query param always overrides the app-state value
   */
  useEffect(() => {
    if (themeQueryParam && isValidTheme(themeQueryParam) && theme !== themeQueryParam) {
      setTheme(themeQueryParam as ThemeType)
    }
  }, [theme, themeQueryParam])

  const updateTheme = useCallback(
    (newTheme: ThemeType) => {
      // if the theme is already the pm-blue version, then keep it
      if (theme === 'pm') return
      setTheme(newTheme)
    },
    [theme]
  )

  const themeProviderValue = useMemo(() => ({ theme, setTheme: updateTheme }), [theme, updateTheme])
  return <ThemeContext.Provider value={themeProviderValue}>{children}</ThemeContext.Provider>
}

export const useThemeContext = () => {
  const context = useContext(ThemeContext)

  if (context === null) {
    throw new Error('useThemeContext must be used within ThemeProvider')
  }

  return context
}
