import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Popover, Transition } from '@headlessui/react'
import { HiOutlineUserCircle } from 'react-icons/hi'
import { RiLoginCircleLine, RiLogoutCircleRLine } from 'react-icons/ri'

import config from 'config/index'
import useModal from 'hooks/useModal'
import { useAuthContext } from 'context/auth'
import { useAppContext } from 'context/app'

import BoxIcon from 'components/common/icons/BoxIcon'
import LanguageIcon from 'components/common/icons/LanguageIcon'
import LanguageSelection from 'components/language/LanguageSelection'
import LoginModal from 'components/auth/LoginModal'

const UserMenu: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    localeCode: localeCodePathParam,
    shopId: shopIdPathParam,
    cartId: cartIdPathParam
  } = useParams()
  const { t: translated } = useTranslation()
  const {
    isAuthenticated,
    isCustomer,
    signOut,
    userInitials,
    userFullName,
    userEmail,
    partnerId,
    isLoginModalOpen,
    setIsLoginModalOpen
  } = useAuthContext()
  const { configuredLanguages } = useAppContext()
  const {
    isModalOpen: isLanguageSelectionModalOpen,
    closeModal: closeLanguageSelectionModal,
    toggleModal: toggleLanguageSelectionModal
  } = useModal()

  const isMissingProfileData = !userFullName && !partnerId && !userEmail

  return (
    <>
      <Popover as='div' className='z-20 relative inline-block text-left'>
        <Popover.Button
          className={
            'h-10 w-10 ' +
            'flex justify-center items-center ' +
            'rounded-full bg-primary font-bold text-white ' +
            'hover:bg-secondary hover:text-primary'
          }
        >
          {userInitials?.length ? (
            userInitials
          ) : (
            <HiOutlineUserCircle size={28} className='font-light' />
          )}
        </Popover.Button>

        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Popover.Panel
            className={
              'absolute right-0 w-60 mt-2 origin-top-right' +
              ' rounded-md border border-secondary bg-white shadow-lg'
            }
          >
            <div
              className={
                'relative overflow-hidden' +
                ' flex flex-col items-center gap-1 py-4 px-2' +
                ' border-b border-secondary' +
                ' text-center'
              }
            >
              <div
                className={
                  'z-0 absolute -top-[47.5rem]' +
                  ' h-[50rem] w-[50rem]' +
                  ' rounded-full bg-secondary'
                }
              />

              <div className='z-10 mb-2 p-px rounded-full bg-secondary-darker text-white font-light'>
                <HiOutlineUserCircle size={35} className='font-light' />
              </div>

              {!isAuthenticated ? (
                <p className='z-10 text-base text-gray-500 font-light'>
                  {translated('Not logged in')}
                </p>
              ) : isMissingProfileData ? (
                <p className='z-10 text-base text-gray-500 font-light'>
                  {translated('No profile data')}
                </p>
              ) : (
                <>
                  {userFullName ? <p className='z-10 text-base font-bold'>{userFullName}</p> : null}
                  {partnerId ? <p className='z-10 text-sm'>{partnerId}</p> : null}
                  {userEmail ? (
                    <p className='z-10 w-full text-sm font-light truncate'>{userEmail}</p>
                  ) : null}
                </>
              )}
            </div>

            <nav className='navigation'>
              <ul className='divide-y divide-secondary'>
                {/* link to orders visible 1) only when authenticated, 2) only on shop pages and not on checkout pages */}
                {isAuthenticated && !!shopIdPathParam && !cartIdPathParam ? (
                  <li className='p-1'>
                    <Popover.Button
                      onClick={() => {
                        if (isCustomer) {
                          navigate({
                            pathname: `/${localeCodePathParam}/shop/${shopIdPathParam}/orders`,
                            search: location.search
                          })
                        } else {
                          window.open(`${config.officeAppUrl}/account/orders`, '_blank')
                        }
                      }}
                      className={
                        'w-full flex justify-center items-center gap-1 rounded-md px-2 py-2' +
                        ' bg-transparent hover:bg-secondary text-sm'
                      }
                    >
                      <BoxIcon className='h-5 w-5 text-primary' />
                      {translated('My orders')}
                    </Popover.Button>
                  </li>
                ) : null}

                <li className='p-1'>
                  <button
                    onClick={toggleLanguageSelectionModal}
                    className={
                      'w-full flex justify-center items-center gap-1 rounded-md px-2 py-2 ' +
                      'bg-transparent hover:bg-secondary text-sm'
                    }
                  >
                    <LanguageIcon className='h-6 w-6 text-primary' />
                    {translated('Select a language')}
                  </button>
                </li>

                {isAuthenticated ? (
                  <li className='p-1'>
                    <button
                      onClick={signOut}
                      className={
                        'w-full flex justify-center items-center gap-1.5 rounded-md px-2 py-2 ' +
                        'bg-transparent hover:bg-secondary text-sm'
                      }
                    >
                      <RiLogoutCircleRLine className='h-5 w-5 text-primary' />
                      {translated('Sign out')}
                    </button>
                  </li>
                ) : (
                  <li className='p-1'>
                    <button
                      onClick={() => setIsLoginModalOpen(true)}
                      className={
                        'w-full flex justify-center items-center gap-1.5 rounded-md px-2 py-2 ' +
                        'bg-transparent hover:bg-secondary text-sm'
                      }
                    >
                      <RiLoginCircleLine className='h-5 w-5 text-primary' />
                      {translated('Sign in')}
                    </button>
                  </li>
                )}
              </ul>
            </nav>
          </Popover.Panel>
        </Transition>
      </Popover>

      <LoginModal isOpen={isLoginModalOpen} onClose={() => setIsLoginModalOpen(false)} />

      <LanguageSelection
        isOpen={isLanguageSelectionModalOpen}
        onClose={closeLanguageSelectionModal}
        locales={configuredLanguages}
      />
    </>
  )
}

export default UserMenu
