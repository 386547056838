import { FC, useCallback, useReducer, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { usePrivacyContext } from 'context/privacy/PrivacyContext'

import ChevronDownSemiboldIcon from 'components/common/icons/ChevronDownSemiboldIcon'
import ToggleSwitch from 'components/common/ToggleSwitch'
import BasicButton from 'components/common/buttons/BasicButton'
import CookieIntroduction from './CookieIntroduction'
import CookieDetails from './CookieDetails'

type CSProps = {
  readonly className?: string
}

const CookieSettings: FC<CSProps> = ({ className }) => {
  const { t: translated } = useTranslation()
  const {
    isCookieConsentDefinedByUser,
    isCookieConsentGiven,
    setConsent,
    setIsCookieConsentModalVisible
  } = usePrivacyContext()

  const [isOpen, toggleOpen] = useReducer(state => !state, false)
  const [isTelemetryAllowed, setIsTelemetryAllowed] = useState(() =>
    isCookieConsentDefinedByUser ? isCookieConsentGiven : true
  )

  const handleConfirmation = useCallback(() => {
    setConsent({
      isCookieConsentDefinedByUser: true,
      isCookieConsentGiven: isTelemetryAllowed
    })
    setIsCookieConsentModalVisible(false)
  }, [isTelemetryAllowed, setConsent, setIsCookieConsentModalVisible])

  return (
    <div className={'text-base flex flex-col gap-4' + (className ? ` ${className}` : '')}>
      <div className='portrait:max-h-[30rem] portrait:overflow-scroll portrait:pr-2 flex flex-col gap-4'>
        <div className='text-center font-bold'>{translated('Privacy overview')}</div>

        <CookieIntroduction />

        <div className=''>
          <div className={'flex items-center'}>
            <div className='flex justify-between items-center w-full bg-body py-2 px-3'>
              <button
                className='flex gap-2 items-center cursor-pointer peer-focus-visible:ring'
                onClick={toggleOpen}
              >
                <ChevronDownSemiboldIcon
                  className={`w-2 transition-all text-base ${isOpen ? 'rotate-0' : '-rotate-90'}`}
                />
                <span className='text-sm'>{translated('Analytics')}</span>
              </button>

              <ToggleSwitch
                className='gap-2 text-sm'
                label={isTelemetryAllowed ? translated('Enabled') : translated('Disabled')}
                labelPosition='left'
                checked={isTelemetryAllowed}
                onChange={() => setIsTelemetryAllowed(state => !state)}
              />
            </div>
          </div>

          <CookieDetails expanded={isOpen} />
        </div>
      </div>

      <div className='flex justify-end'>
        <BasicButton className='py-2 px-4 bg-primary text-white' onClick={handleConfirmation}>
          {translated('Save and apply')}
        </BasicButton>
      </div>
    </div>
  )
}

export default CookieSettings
