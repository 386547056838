import { FC, memo, useCallback } from 'react'
import { Link, To, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { FaCartPlus } from 'react-icons/fa'
import { ImSpinner2 } from 'react-icons/im'

import { formatPrice, getUnitPrice } from 'utils/priceUtils'
import { useAuthContext } from 'context/auth'

import { showToast } from 'components/layout/ToastNotification'
import AnimatedNumber from 'components/effects/AnimatedNumber'
import PMPoints from 'components/common/PMPoints'

type ProductCardProps = {
  name?: string
  description?: string
  imageUrl?: string
  points?: number
  currencyCode?: string
  finalPrice?: number
  basePrice?: number
  inStock?: boolean
  isPromotional?: boolean
  maxAmount: number
  label?: string
  productInfoLink: To
  onPurchaseAction?: () => void
  isCartContentLoading: boolean
  isCartUploading: boolean
  amountInCart?: number
  amountInUnits?: number
  unit?: string
}

const ProductCard: FC<ProductCardProps> = memo(
  ({
    name,
    imageUrl,
    points,
    currencyCode,
    finalPrice,
    basePrice,
    inStock,
    isPromotional,
    maxAmount,
    label,
    productInfoLink,
    onPurchaseAction,
    isCartUploading,
    isCartContentLoading,
    amountInCart,
    amountInUnits,
    unit
  }) => {
    const { localeCode: localeCodePathParam } = useParams()
    const { t: translated } = useTranslation()
    const { isAuthenticated, setIsLoginModalOpen } = useAuthContext()

    const labelText = !inStock ? translated('Sold out') : label
    const isPriceDiscounted = !!basePrice && !!finalPrice && finalPrice < basePrice
    const basePriceDisplayed =
      basePrice && currencyCode
        ? formatPrice(basePrice, currencyCode, localeCodePathParam ?? '')
        : null
    const finalPriceDisplayed =
      finalPrice && currencyCode
        ? formatPrice(finalPrice, currencyCode, localeCodePathParam ?? '')
        : null
    const isMaxAmountReached = (amountInCart ?? 0) >= maxAmount
    const unitPriceDisplayed =
      finalPrice && amountInUnits && currencyCode && localeCodePathParam && unit
        ? getUnitPrice({
            price: finalPrice,
            amountInUnits,
            currencyCode,
            localeCode: localeCodePathParam,
            unitSymbol: unit
          })
        : null

    const handleAddToCart = useCallback(() => {
      if (!isAuthenticated) setIsLoginModalOpen(true)

      if (!inStock)
        return showToast({
          type: 'warning',
          title: translated('This product is out of stock.')
        })

      if (isMaxAmountReached)
        return showToast({
          type: 'warning',
          title: translated('You can purchase up to {{maxNumber}} units of this product.', {
            maxNumber: maxAmount
          })
        })

      onPurchaseAction?.()
    }, [
      inStock,
      isAuthenticated,
      isMaxAmountReached,
      maxAmount,
      onPurchaseAction,
      setIsLoginModalOpen,
      translated
    ])

    const isCartButtonDisabled =
      isAuthenticated && (isCartUploading || isCartContentLoading || isMaxAmountReached)

    return (
      <div
        className={
          'relative h-[340px] w-42 rounded-lg pt-2 pb-4 px-0 bg-white' +
          ' flex flex-col justify-between' +
          ' border border-secondary' +
          ' overflow-clip' +
          ' shadow-md' +
          ' hover:shadow-xl active:shadow-md' +
          ' transition-shadow duration-200'
        }
      >
        {isPromotional && (
          <div className='z-10 absolute top-0 left-0 right-0 h-8 grid place-content-center'>
            <div className='z-0 absolute inset-0 bg-fitline-red opacity-80' />
            <p className='z-10 text-center bold uppercase text-white opacity-80'>Promo</p>
          </div>
        )}

        <Link className='block w-full overflow-visible' to={productInfoLink}>
          <img className='h-40 w-40 mt-0 mb-2 mx-auto' src={imageUrl} alt={name} />

          {name && <p className='h-12 px-1 line-clamp-2 text-center'>{name}</p>}
        </Link>

        <div className={'px-2 grid grid-cols-3 items-end'}>
          <div className='col-span-2 space-y-0'>
            <p>
              <PMPoints value={points} customClasses={'inline-block mx-auto'} />
            </p>

            <div className='mt-2 overflow-hidden'>
              {isPriceDiscounted && basePriceDisplayed && (
                <p className='min-h-0 leading-normal text-xs font-regular text-silver line-through'>
                  {basePriceDisplayed}
                </p>
              )}

              {finalPriceDisplayed && <p className='text-base font-bold'>{finalPriceDisplayed}</p>}

              {unitPriceDisplayed && (
                <p className='min-h-0 leading-normal text-xs font-regular text-silver'>
                  {unitPriceDisplayed}
                </p>
              )}
            </div>
          </div>

          <div className='col-span-1 grid justify-items-end place-items-end'>
            <button
              onClick={handleAddToCart}
              disabled={isCartButtonDisabled}
              className={
                'relative h-11 w-11 grid place-content-center' +
                (isCartButtonDisabled ? ' text-silver' : ' text-primary')
              }
            >
              <div className='absolute inset-0 grid place-content-center'>
                <ImSpinner2
                  size={28}
                  className={
                    'animate-spin' + (isCartUploading ? ' z-20 opacity-100' : ' z-0 opacity-0')
                  }
                />
              </div>

              <div
                className={
                  'z-10 absolute inset-0 h-10 w-10 grid place-content-center' +
                  ' rounded-full border-solid border border-transparent' +
                  (isCartUploading ? ' opacity-20' : ' opacity-100') +
                  (isCartUploading || isMaxAmountReached || !inStock
                    ? ' cursor-not-allowed hover:border-transparent focus:border-transparent'
                    : ' cursor-pointer hover:border-primary focus:border-primary')
                }
              >
                <FaCartPlus size={24} className={'z-0 -ml-0.5'} />

                {amountInCart ? (
                  <span
                    className={
                      'z-10 absolute -top-1 -right-1 flex justify-center align-middle h-5 min-w-5 px-1' +
                      ' rounded-full bg-secondary text-sm'
                    }
                  >
                    <AnimatedNumber value={amountInCart} />
                  </span>
                ) : null}
              </div>
            </button>
          </div>
        </div>

        {labelText && (
          <div
            className={
              'absolute top-4 left-4' +
              ' py-1 px-1.5' +
              ' text-xs text-primary font-bold' +
              ' rounded border-solid border-2 border-primary'
            }
          >
            {labelText}
          </div>
        )}
      </div>
    )
  }
)

ProductCard.displayName = 'ProductCard'

export default ProductCard
