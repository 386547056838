import { FC, useMemo, useState } from 'react'
import {
  Navigate,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import { AiOutlineShop } from 'react-icons/ai'
import { BsListCheck } from 'react-icons/bs'
import { MdOutlinePayment, MdShoppingCartCheckout } from 'react-icons/md'
import { SlDirections } from 'react-icons/sl'
import { TbTruckDelivery } from 'react-icons/tb'
import { TfiLocationPin } from 'react-icons/tfi'

import type { StepType } from 'components/common/StepLine'

import type { CheckoutConfirmationStatus } from 'typings/checkoutApi'
import { formatPrice } from 'utils/priceUtils'
import { getCheckoutStep } from 'utils/checkoutUtils'
import { useAppContext } from 'context/app'
import { useCheckoutContext } from 'context/checkout'
import { useAuthContext } from 'context/auth'

import { footerVariants } from './CheckoutLayout.motion'
import PersistentPortal from 'components/common/PersistentPortal'
import ScreenCover from 'components/common/ScreenCover'
import SpinnerPM from 'components/common/SpinnerPM'
import CloudDownloadIcon from 'components/common/icons/CloudDownloadIcon'
import MessageBox from 'components/common/MessageBox'
import StepLine from 'components/common/StepLine'
import AnimatedNumber from 'components/effects/AnimatedNumber'
import PMPoints from 'components/common/PMPoints'
import InfoSolidIcon from 'components/common/icons/InfoSolidIcon'
import Modal from 'components/common/Modal'

type StepName =
  | 'shop'
  | 'cart'
  | 'destination'
  | 'delivery'
  | 'address'
  | 'midnightMadness'
  | 'summary'
  | 'payment'

const CheckoutLayout: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { localeCode: localeCodePathParam, cartId: cartIdPathParam } = useParams()
  const [searchParams] = useSearchParams()
  const { t: translated } = useTranslation()
  const { shopId } = useAppContext()
  const { isCustomer } = useAuthContext()
  const {
    allowsVirtualWarehouseDelivery,
    askAboutMidnightMadness,
    cartStatus,
    isBasketLoading,
    isBasketQueryError,
    basketQueryError,
    cartSubtotal,
    totalPaymentDue,
    cartCurrency,
    cartPoints,
    goesToVirtualWarehouse,
    affiliateDiscount,
    basketPromoCode
  } = useCheckoutContext()

  const [isVWTotalPointModalVisible, setIsVWTotalPointModalVisible] = useState(false)
  const [isVWTotalPriceModalVisible, setIsVWTotalPriceModalVisible] = useState(false)

  const steps: StepType<StepName>[] = useMemo(
    () =>
      [
        {
          id: 'shop',
          label: translated('Shop'),
          icon: <AiOutlineShop size={20} />,
          click: shopId
            ? () =>
                navigate({
                  pathname: `/${localeCodePathParam}/shop/${shopId}/products`,
                  search: location.search
                })
            : null
        },
        {
          id: 'cart',
          label: translated('Cart'),
          icon: <MdShoppingCartCheckout size={20} />,
          click: () =>
            navigate({
              pathname: `/${localeCodePathParam}/cart/${cartIdPathParam}`,
              search: location.search
            })
        },
        {
          id: 'destination',
          label: translated('Destination'),
          icon: <SlDirections size={20} />,
          click: () =>
            navigate({
              pathname: `/${localeCodePathParam}/cart/${cartIdPathParam}/destination`,
              search: location.search
            })
        },
        {
          id: 'delivery',
          label: translated('Delivery'),
          icon: <TbTruckDelivery size={20} />,
          click: () =>
            navigate({
              pathname: `/${localeCodePathParam}/cart/${cartIdPathParam}/delivery`,
              search: location.search
            })
        },
        {
          id: 'address',
          label: translated('Address'),
          icon: <TfiLocationPin size={20} />,
          click: () =>
            navigate({
              pathname: `/${localeCodePathParam}/cart/${cartIdPathParam}/address`,
              search: location.search
            })
        },
        {
          id: 'midnightMadness',
          label: translated('Point allocation'),
          icon: <span style={{ fontSize: 20 }}>Ⓟ</span>,
          click: () =>
            navigate({
              pathname: `/${localeCodePathParam}/cart/${cartIdPathParam}/mm`,
              search: location.search
            })
        },
        {
          id: 'summary',
          label: translated('Summary'),
          icon: <BsListCheck size={20} />,
          click: () =>
            navigate({
              pathname: `/${localeCodePathParam}/cart/${cartIdPathParam}/summary`,
              search: location.search
            })
        },
        {
          id: 'payment',
          label: translated('Payment'),
          icon: <MdOutlinePayment size={20} />,
          click: () => null
        }
      ].filter(step =>
        (step.id === 'destination' && !allowsVirtualWarehouseDelivery) ||
        (step.id === 'midnightMadness' && !askAboutMidnightMadness)
          ? false
          : true
      ) as StepType<StepName>[],
    [
      allowsVirtualWarehouseDelivery,
      askAboutMidnightMadness,
      cartIdPathParam,
      localeCodePathParam,
      location.search,
      navigate,
      shopId,
      translated
    ]
  )

  const checkoutConfirmationStatus = searchParams.get('status') as CheckoutConfirmationStatus
  const isSummaryPage = location.pathname.endsWith('/summary')
  const isConfirmationPage = location.pathname.endsWith('/confirmation')
  const totalAmountToDisplay = isSummaryPage ? totalPaymentDue : cartSubtotal

  if (isBasketLoading)
    return (
      <ScreenCover>
        <SpinnerPM>
          <CloudDownloadIcon />
        </SpinnerPM>
      </ScreenCover>
    )

  if (isBasketQueryError)
    return basketQueryError?.status === 404 ? (
      <div className='w-full grid place-content-center'>
        <MessageBox type='error' text={basketQueryError.title} showIcon={true} />
      </div>
    ) : basketQueryError?.status === 403 ? (
      <div className='w-full grid place-content-center'>
        <MessageBox
          type='error'
          text={translated('You are not authorized to access this shopping basket.')}
          showIcon={true}
        />
      </div>
    ) : (
      <div className='w-full grid place-content-center'>
        <MessageBox type='error' text={JSON.stringify(basketQueryError, null, 2)} showIcon={true} />
      </div>
    )

  /**
   * If the basket has already been paid or canceled, show the user only the cart content page
   */
  if (
    ['paymentAuthorized', 'canceled'].includes(cartStatus ?? '') &&
    !checkoutConfirmationStatus &&
    getCheckoutStep(location.pathname) !== 'cart'
  )
    return (
      <Navigate
        to={{
          pathname: `/${localeCodePathParam}/cart/${cartIdPathParam}`,
          search: location.search
        }}
        replace={true}
      />
    )

  /**
   * If the current cart has already been paid for, the only checkout step that the user can still
   * view is the success confirmation page. When attempting to view any other step, redirect to the
   * home page of the shop.
   */
  if (
    (!isConfirmationPage && !['open', 'paymentPending'].includes(cartStatus ?? '')) ||
    (isConfirmationPage &&
      cartStatus === 'paymentAuthorized' &&
      checkoutConfirmationStatus !== 'success')
  )
    return (
      <Navigate to={shopId ? `/${localeCodePathParam}/shop/${shopId}` : '/404'} replace={true} />
    )

  /**
   * Users can be on confirmation page only if cart status is 'paymentPending' or 'paymentAuthorized'
   */
  if (isConfirmationPage && ['open', 'canceled'].includes(cartStatus ?? ''))
    return (
      <Navigate
        to={{
          pathname: `/${localeCodePathParam}/cart/${cartIdPathParam}`,
          search: location.search
        }}
        replace={true}
      />
    )

  /**
   * If this basket configuration does not allow virtual warehouse delivery, skip the destination selection step.
   * Redirection away from the destination step depends on whether the user navigated to the destination
   * step forward, from the previous or backward, from the succeeding step. Information about which step the user
   * came from is passed through the react-router state property.
   */
  if (location.pathname?.endsWith('destination') && !allowsVirtualWarehouseDelivery) {
    return (
      <Navigate
        to={{
          // if the user came from the "cart-content" step, skip this one and go on to the "shipping" step
          // otherwise, go back to the "cart-content" step
          pathname: location?.state?.source === 'cart-content' ? './delivery' : '.',
          search: location.search
        }}
        replace={true}
      />
    )
  }

  /**
   * If this basket configuration does not allow midnight madness, skip the point allocation choice step.
   * Redirection away from the midnight-madness step depends on whether the user navigated to this
   * step forward, from the previous or backward, from the succeeding step. Information about which step the user
   * came from is passed through the react-router state property.
   */
  if (location.pathname?.endsWith('mm') && !askAboutMidnightMadness) {
    console.info('redirecting from midnight madness page, because midnight madness not enabled')
    return (
      <Navigate
        to={{
          // if the user came from the "checkout address" step, skip this one and go on to the "checkout summary" step
          // otherwise, go back to the "checkout address" step
          pathname: location?.state?.source === 'checkout-address' ? './summary' : './address',
          search: location.search
        }}
        replace={true}
      />
    )
  }

  return (
    <>
      <PersistentPortal containerElementSelector='#header-additional-content'>
        <nav className='relative w-screen pt-3'>
          <StepLine
            steps={steps}
            activeStep={getCheckoutStep(location.pathname)}
            customClasses='container max-w-3xl mx-auto mt-1 sm:mt-0'
          />
        </nav>
      </PersistentPortal>

      <Outlet />

      {!isConfirmationPage && (
        <PersistentPortal containerElementSelector='#sticky-footer'>
          <motion.div
            className={
              'w-full max-w-3xl mx-auto overflow-hidden' +
              ' py-2 sm:py-3 px-3' +
              ' space-y-2 sm:space-y-3' +
              ' bg-secondary'
            }
            variants={footerVariants}
          >
            {/* --------------- promo code: start --------------- */}
            <div id='promo-code' className='w-full' />
            {/* --------------- promo code: end --------------- */}

            {/* --------------- totals: start --------------- */}
            <div id='footer-totals' className='w-full'>
              <div>
                <div className='flex justify-between items-center'>
                  <span>{translated('Total')}</span>
                  {goesToVirtualWarehouse ? (
                    <span className='flex items-center justify-center gap-1'>
                      <AnimatedNumber
                        value={formatPrice(
                          totalAmountToDisplay,
                          cartCurrency,
                          localeCodePathParam ?? ''
                        )}
                        className='font-bold'
                      />
                      <button className='flex' onClick={() => setIsVWTotalPriceModalVisible(true)}>
                        <InfoSolidIcon
                          className={
                            'h-4 w-4 md:h-5 md:w-5 flex-none fill-current self-start text-blue-400'
                          }
                        />
                      </button>
                    </span>
                  ) : (
                    <AnimatedNumber
                      value={formatPrice(
                        totalAmountToDisplay,
                        cartCurrency,
                        localeCodePathParam ?? ''
                      )}
                      className='font-bold'
                    />
                  )}
                </div>

                {!!affiliateDiscount && basketPromoCode ? (
                  <p className='mt-0 text-right text-sm text-gray-500'>
                    {translated(
                      'Including a {{discountAmount}} customer promo code discount on eligible products',
                      {
                        discountAmount: `${affiliateDiscount}%`
                      }
                    )}
                  </p>
                ) : affiliateDiscount ? (
                  <p className='mt-0 text-right text-sm text-gray-500'>
                    {translated(
                      'Including a one-time discount of {{discountAmount}} on eligible products',
                      {
                        discountAmount: `${affiliateDiscount}%`
                      }
                    )}
                  </p>
                ) : null}
              </div>

              {isCustomer ? null : (
                <div className='flex justify-between items-center'>
                  <span>{translated('Points')}</span>
                  {goesToVirtualWarehouse ? (
                    <span className='flex items-center justify-center gap-1'>
                      <PMPoints value={cartPoints} />
                      <button className='flex' onClick={() => setIsVWTotalPointModalVisible(true)}>
                        <InfoSolidIcon
                          className={
                            'h-4 w-4 md:h-5 md:w-5 flex-none fill-current self-start text-blue-400'
                          }
                        />
                      </button>
                    </span>
                  ) : (
                    <PMPoints value={cartPoints} />
                  )}
                </div>
              )}
            </div>
            {/* --------------- totals: end --------------- */}

            {/* --------------- terms & conditions: start --------------- */}
            <div id='footer-terms' className='w-full' />
            {/* --------------- terms & conditions: end --------------- */}

            {/* --------------- button container: start --------------- */}
            <div id='footer-buttons' className='w-full' />
            {/* --------------- button container: end --------------- */}
          </motion.div>
        </PersistentPortal>
      )}

      {/* --------------- virtual-warehouse explanation modal: start --------------- */}
      {!isConfirmationPage && (
        <>
          <Modal
            contentClassName='transition-all'
            size='lg'
            allowEasyClose={true}
            onClose={() => setIsVWTotalPointModalVisible(false)}
            isOpen={isVWTotalPointModalVisible}
            title={translated('Points earned for Virtual Warehouse orders')}
          >
            <MessageBox
              type='info'
              text={
                <ul className='list-disc pl-4'>
                  <li>
                    {translated(
                      'This is the total number of points that you can earn for your commitment.'
                    )}
                  </li>
                  <li>
                    {translated(
                      'Every time you sell products to your customers, you will earn a proportionate amount of points.'
                    )}
                  </li>
                  <li>
                    {translated(
                      'The unsold products will be automatically sold to you, and you will earn a proportionate amount of points.'
                    )}
                  </li>
                </ul>
              }
              className='mb-4'
              showIcon={true}
            />
          </Modal>

          <Modal
            contentClassName='transition-all'
            size='lg'
            allowEasyClose={true}
            onClose={() => setIsVWTotalPriceModalVisible(false)}
            isOpen={isVWTotalPriceModalVisible}
            title={translated('Virtual Warehouse order charges')}
          >
            <MessageBox
              type='info'
              text={translated(
                'This is the full price of your commitment. You are not going to be charged now. The unsold products will be shipped to you, and you will be charged for them.'
              )}
              className='mb-4'
              showIcon={true}
            />
          </Modal>
        </>
      )}
      {/* --------------- virtual-warehouse explanation modal: end --------------- */}
    </>
  )
}

export default CheckoutLayout
