import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { formatPrice } from 'utils/priceUtils'
import RadioInputIcon from 'components/common/icons/RadioInputIcon'

type DMCProps = {
  id: string
  name: string
  details: string
  price: number
  currencyCode: string
  isSelected: boolean
  handleSelection: () => void
  customClasses?: string
}

const DeliveryMethodCard: FC<DMCProps> = ({
  id,
  name,
  details,
  price,
  currencyCode,
  isSelected,
  handleSelection,
  customClasses
}) => {
  const { localeCode: localeCodePathParam } = useParams()

  return (
    <label
      htmlFor={`delivery-method-${id}`}
      className={
        'w-96 max-w-full relative py-4 pl-4 pr-6' +
        ' grid grid-cols-[min-content_1fr] items-center gap-4' +
        ' rounded-md border-2' +
        ' transition duration-200' +
        (isSelected ? ' border-secondary-darker' : ' border-secondary') +
        ' hover:cursor-pointer hover:bg-secondary-lighter' +
        ' peer-focus-visible:ring' +
        ` ${customClasses}`
      }
    >
      <input
        type='radio'
        name='delivery-method'
        id={`delivery-method-${id}`}
        value={id}
        checked={isSelected}
        onChange={handleSelection}
        className='sr-only peer'
      />

      {<RadioInputIcon size={20} selected={isSelected} />}

      <div className='content'>
        <div className='flex justify-between gap-2 text-base'>
          <p className='font-normal'>{name}</p>

          <div className='font-bold text-gray-600'>
            {price > 0 ? formatPrice(price, currencyCode, localeCodePathParam ?? '') : null}
          </div>
        </div>

        {details && (
          <p className='mt-2 text-sm font-light tracking-wide text-gray-500'>{details}</p>
        )}
      </div>
    </label>
  )
}

export default DeliveryMethodCard
