import { FC, useEffect, useRef, useState } from 'react'
import { motion } from 'framer-motion'

type NCProps = {
  digit: string
}

const digitList = Array.from({ length: 10 }, (v, i) => i).reverse()

const AnimatedDigit: FC<NCProps> = ({ digit }) => {
  const columnContainer = useRef<HTMLDivElement>(null)
  const [position, setPosition] = useState(0)

  useEffect(() => {
    if (columnContainer.current)
      setPosition(columnContainer.current?.clientHeight * parseInt(digit, 10))
  }, [digit])

  return (
    <span className='relative' ref={columnContainer}>
      {/* this invisible placeholder provides the necessary dimensions to the container */}
      <span className='invisible z-0 opacity-0'>0</span>

      <motion.span
        animate={{
          y: position
        }}
        className='block absolute bottom-0 h-[1000%]'
      >
        {digitList.map(num => (
          <span key={num} className='inline-block h-[10%] text-inherit'>
            {num}
          </span>
        ))}
      </motion.span>
    </span>
  )
}

export default AnimatedDigit
