const scrollBlockClassName = 'scroll-block'
const defaultModalContainerElementStyle = 'position: relative; z-index: 90000;'

const selectBodyElement = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined')
    return document.querySelector('body')
  return null
}

export const bodyElement = selectBodyElement()

export const isBodyScrollDisabled = () => bodyElement?.classList?.contains(scrollBlockClassName)

export const disableBodyScroll = () => bodyElement?.classList?.add(scrollBlockClassName)
export const enableBodyScroll = () => bodyElement?.classList?.remove(scrollBlockClassName)

export const createModalContainer = () => {
  if (typeof window === 'undefined' || typeof document === 'undefined') {
    return console.error('Cannot create modal anchor')
  }

  const existingModalContainerElement = document.querySelector('#modal')
  if (existingModalContainerElement == null) {
    const bodyElement = document.getElementsByTagName('body')[0]
    const newModalContainerElement = document.createElement('div')
    newModalContainerElement.setAttribute('id', 'modal')
    newModalContainerElement.setAttribute('style', defaultModalContainerElementStyle)
    bodyElement.appendChild(newModalContainerElement)
  } else {
    const modalContainerStyle = existingModalContainerElement.getAttribute('style')
    if (!modalContainerStyle?.includes('position') || !modalContainerStyle?.includes('z-index')) {
      existingModalContainerElement.setAttribute(
        'style',
        modalContainerStyle
          ? `${modalContainerStyle} ${defaultModalContainerElementStyle}`
          : defaultModalContainerElementStyle
      )
    }
  }
}
