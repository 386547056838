import { FC, memo, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Navigation, Thumbs } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

import './ProductImageDisplay.css'

import type { Swiper as ISwiper } from 'swiper'
import type { IMedia } from 'typings/shopApi'

type PIDProps = {
  images: IMedia[]
}

const ProductImageDisplay: FC<PIDProps> = memo(({ images }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<ISwiper | null>(null)

  return images?.length > 1 ? (
    <div
      style={{
        width: '240px'
      }}
    >
      <Swiper
        loop={true}
        navigation={true}
        modules={[FreeMode, Navigation, Thumbs]}
        grabCursor={true}
        thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
        className='product-image-slider'
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image.url} alt='product image' />
          </SwiperSlide>
        ))}
      </Swiper>

      <Swiper
        modules={[FreeMode, Navigation, Thumbs]}
        onSwiper={setThumbsSwiper}
        watchSlidesProgress={true}
        freeMode={true}
        loop={true}
        slidesPerView={4}
        spaceBetween={4}
        className='product-image-thumb-list mt-1'
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <div className='product-image-thumb-item'>
              <img src={image.url} alt='product thumbnail' />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  ) : (
    <img className='block h-60 w-60' src={images[0].url} alt='product image' />
  )
})

ProductImageDisplay.displayName = 'ProductImageDisplay'

export default ProductImageDisplay
