import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import type { LocaleCodeType } from 'typings/languageTypes'
import {
  codeDefaultLocale,
  codeUnknownLocale,
  loadLanguageListTranslations,
  loadLanguageTranslations,
  supportedLanguageCodes
} from 'localization/.'
import translatedContentEnglish from 'localization/translations/en-US.json'
import { useAppContext } from 'context/app'
import { useAuthContext } from 'context/auth'

export interface II18nextLocalizationContext {
  readonly appLocale?: string
}

i18next
  // pass the i18n instance to react-i18next
  .use(initReactI18next)
  // init options: https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: translatedContentEnglish
      }
    },
    fallbackLng: {
      default: [codeDefaultLocale]
    },
    keySeparator: false,
    interpolation: {
      escapeValue: false
    }
    // debug: process.env.NODE_ENV === 'development'
  })

export const i18n = i18next

export const LocalizationContext = createContext<II18nextLocalizationContext | null>(null)

type LCPProps = {
  readonly children: ReactNode
}

export const LocalizationProvider: FC<LCPProps> = ({ children }) => {
  const { localeCode: localeCodePathParam } = useParams()
  const { userManagerLanguage, updateAuthLanguage } = useAuthContext()
  const { configuredLanguages } = useAppContext()

  const isValidLanguageCodeInUrl = supportedLanguageCodes.includes(
    localeCodePathParam as LocaleCodeType
  )
  const lowerCaseLocaleCodeFromUrl = localeCodePathParam
    ? localeCodePathParam?.toLowerCase()
    : codeUnknownLocale?.toLowerCase()

  const [appLocale, setAppLocale] = useState<string>()
  // string formed by concatenating the sorted list of the locale codes of the loaded languages
  const [loadedLocaleSet, setLoadedLocaleSet] = useState<Set<LocaleCodeType>>(
    new Set([codeDefaultLocale])
  )

  useEffect(() => {
    const localesNotYetLoaded = configuredLanguages.filter(
      languageFromConfiguration => !loadedLocaleSet.has(languageFromConfiguration.code)
    )

    if (localesNotYetLoaded?.length === 0) return
    ;(async () => {
      try {
        await loadLanguageListTranslations(configuredLanguages)
        setLoadedLocaleSet(prevSet => {
          const newSet = new Set(prevSet)
          localesNotYetLoaded.forEach(locale => newSet.add(locale.code))
          return newSet
        })
      } catch (error) {
        console.error('failed to load translations')
      }
    })()
  }, [loadedLocaleSet, configuredLanguages])

  // handle the manual change of the language code in the URL path parameters
  useEffect(() => {
    if (!lowerCaseLocaleCodeFromUrl?.length || lowerCaseLocaleCodeFromUrl === codeUnknownLocale)
      return

    // if the already applied language is the same as the one in the URL, ignore
    if (
      lowerCaseLocaleCodeFromUrl === appLocale &&
      lowerCaseLocaleCodeFromUrl === i18next.language.toLowerCase()
    )
      return

    // if it is a new and valid/eligible language code, apply it
    if (
      configuredLanguages?.some(
        ({ code: languageListCode }) =>
          languageListCode?.toLowerCase() === lowerCaseLocaleCodeFromUrl
      )
    ) {
      const [language, country] = lowerCaseLocaleCodeFromUrl?.split('-') ?? []
      const newAppLocaleCode = `${language}-${country.toUpperCase()}`

      setAppLocale(newAppLocaleCode)
      loadLanguageTranslations(newAppLocaleCode as LocaleCodeType).then(() =>
        i18next
          .changeLanguage(newAppLocaleCode)
          .catch(error =>
            console.error(`Failed to update i18next language to ${newAppLocaleCode}. `, error)
          )
      )
    }
  }, [appLocale, configuredLanguages, lowerCaseLocaleCodeFromUrl])

  useEffect(() => {
    if (
      !!localeCodePathParam &&
      isValidLanguageCodeInUrl &&
      userManagerLanguage !== localeCodePathParam
    ) {
      updateAuthLanguage(localeCodePathParam)
    }
  }, [localeCodePathParam, isValidLanguageCodeInUrl, userManagerLanguage, updateAuthLanguage])

  console.log({ userManagerLanguage, localeCodePathParam })

  return (
    <LocalizationContext.Provider
      value={{
        appLocale
      }}
    >
      {children}
    </LocalizationContext.Provider>
  )
}

export const useLocalizationContext = () => {
  const context = useContext(LocalizationContext)
  if (context === null)
    throw new Error('useLocalizationContext must be used within LocalizationProvider')
  return context
}
