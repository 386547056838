import config from 'config'

import type { IOrder, IOrderListResponse } from 'typings/orderTypes'
import type { IApiError, IGenericServerError } from 'typings/errorTypes'

const orderApiUrl = config.orderApiUrl

export const fetchShopOrders = async ({
  shopId,
  accessToken,
  pageNumber,
  pageSize
}: {
  shopId: string
  accessToken: string
  pageNumber?: number | string
  pageSize?: number | string
}): Promise<IOrderListResponse> => {
  let url = `${orderApiUrl}?shopId=${shopId}`
  if (pageNumber) url += `&page=${pageNumber}`
  if (pageSize) url += `&perPage=${pageSize}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en-US'
    }
  })

  if (response.ok) return response.json() as unknown as IOrderListResponse
  throw (await response.json()) as IApiError | IGenericServerError
}

export const fetchOrderById = async ({
  orderId,
  accessToken
}: {
  orderId: string
  accessToken: string
}): Promise<IOrder> => {
  const url = `${orderApiUrl}/${orderId}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en-US'
    }
  })

  if (response.ok) return response.json() as unknown as IOrder
  throw (await response.json()) as IApiError | IGenericServerError
}
