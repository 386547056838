import type { IApiError } from 'typings/errorTypes'
import type {
  CartDeliveryValidationError,
  GetBasketExpansionParameter,
  IBasicBasket,
  IBasicBasketItem,
  IBasketCreationSettings,
  IBasketWithDeliveryAndItemDetails,
  IBasketWithItemDetails,
  ICartContentValidationError,
  IDeliveryMethodErrorResponse,
  IDeliveryMethodPayload,
  IPrePaymentValidationPayload,
  IGlobalCartValidationSuccess,
  IVirtualWarehouseBasket,
  IApplyPromoCodeResponse,
  IGetPromoCodeInfoResponse
} from 'typings/checkoutApi'
import config from 'config'

const checkoutApiUrl = config.checkoutApiUrl

// create a new shopping cart for a given shop
export const createBasket = async (
  shopId: string,
  accessToken: string,
  settings: IBasketCreationSettings
): Promise<IBasicBasket> => {
  const response = await fetch(`${checkoutApiUrl}/baskets/?shopId=${shopId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en-US'
    },
    body: JSON.stringify(settings)
  })

  if (response.ok) return response.json()
  throw await response.json()
}

// fetch the list of shopping carts available for a given shop ID
export const fetchBasketList = async (
  shopId: string,
  accessToken: string,
  localeCode?: string
): Promise<IBasicBasket[]> => {
  let url = `${checkoutApiUrl}/baskets/?shopId=${shopId}`
  if (localeCode) url += `&locale=${localeCode}`

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en-US'
    }
  })

  if (response.ok) return response.json()
  throw await response.json()
}

// fetch the data of a public shopping cart created in the virtual warehouse
// This is a public endpoint. If access token sent, will respond with 404.
export const fetchVirtualWarehouseBasket = async (
  basketId: string,
  localeCode?: string
): Promise<IVirtualWarehouseBasket> => {
  const url = new URL(`${checkoutApiUrl}/baskets/${basketId}/checkout`)
  if (localeCode) url.searchParams.set('locale', localeCode)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'en-US'
    }
  })

  if (response.ok) return response.json()
  throw (await response.json()) as Error
}

// fetch the shopping cart content in the shop
export const fetchBasketContent = async (
  basketId: string,
  accessToken: string,
  localeCode?: string,
  expansions?: GetBasketExpansionParameter[]
): Promise<IBasicBasket | IBasketWithItemDetails | IBasketWithDeliveryAndItemDetails> => {
  const url = new URL(`${checkoutApiUrl}/baskets/${basketId}`)
  if (localeCode) url.searchParams.set('locale', localeCode)
  if (expansions?.length)
    expansions.forEach(expansion => url.searchParams.append('expand', expansion))

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en-US'
    }
  })

  if (response.ok) return response.json()
  throw await response.json()
}

// fetch the shopping cart content in throughout the check-out process
export const fetchCheckoutCartContent = async (
  basketId: string,
  accessToken: string,
  localeCode?: string
): Promise<IBasketWithDeliveryAndItemDetails> => {
  const url = new URL(`${checkoutApiUrl}/baskets/${basketId}/checkout`)
  if (localeCode) url.searchParams.set('locale', localeCode)

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en-US'
    }
  })

  if (response.ok) return response.json() as unknown as IBasketWithDeliveryAndItemDetails
  throw (await response.json()) as Error
}

export const setBasketContent = async (
  basketId: string,
  accessToken: string,
  items: IBasicBasketItem[],
  localeCode: string
): Promise<IBasketWithItemDetails> => {
  const url = new URL(`${checkoutApiUrl}/baskets/${basketId}/items`)
  url.searchParams.set('expand', 'items')
  if (localeCode) url.searchParams.set('locale', localeCode)

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en-US'
    },
    body: JSON.stringify({
      operation: 'set',
      items
    })
  })

  if (response.ok) return response.json()
  throw await response.json()
}

// request back-end validation of already existing/stored shopping cart
// cart content already stored on the back-end; no need to submit content payload
export const validateBasketContent = async (
  basketId: string,
  accessToken: string
): Promise<boolean> => {
  const url = `${checkoutApiUrl}/baskets/${basketId}/validation`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en-US'
    }
  })

  // if validation successful, response status is 200, but no payload is returned
  if (response.ok) return true
  throw (await response.json()) as ICartContentValidationError
}

// last validation of the complete cart before proceeding to payment
export const validateCompleteCart = async (
  basketId: string,
  accessToken: string,
  payload: IPrePaymentValidationPayload,
  localeCode: string
): Promise<IGlobalCartValidationSuccess> => {
  const url = `${checkoutApiUrl}/baskets/${basketId}/checkout?locale=${localeCode}`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en-US'
    },
    body: JSON.stringify(payload)
  })

  if (response.ok) return response.json() as unknown as IGlobalCartValidationSuccess
  throw (await response.json()) as CartDeliveryValidationError
}

export const submitDeliveryMethod = async (
  basketId: string,
  accessToken: string,
  delivery: IDeliveryMethodPayload,
  localeCode: string
): Promise<IBasketWithDeliveryAndItemDetails> => {
  const url = `${checkoutApiUrl}/baskets/${basketId}/checkout/delivery?locale=${localeCode}`

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en-US'
    },
    body: JSON.stringify(delivery)
  })

  if (response.ok) return response.json() as unknown as IBasketWithDeliveryAndItemDetails
  throw (await response.json()) as IDeliveryMethodErrorResponse
}

export const fetchPromoCodeInfo = async (code: string): Promise<IGetPromoCodeInfoResponse> => {
  const response = await fetch(`${checkoutApiUrl}/promocodes/${code}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'en-US'
    }
  })

  if (response.ok) return response.json() as unknown as IGetPromoCodeInfoResponse
  throw (await response.json()) as IApiError
}

export const applyPromoCode = async ({
  basketId,
  accessToken,
  promoCode,
  localeCode
}: {
  basketId: string
  accessToken: string
  promoCode: string
  localeCode?: string
}): Promise<IApplyPromoCodeResponse> => {
  const payload = { code: promoCode, ...(localeCode ? { locale: localeCode } : {}) }

  const response = await fetch(`${checkoutApiUrl}/baskets/${basketId}/promocodes`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Accept-Language': 'en-US'
    },
    body: JSON.stringify(payload)
  })

  if (response.ok) return response.json() as unknown as IApplyPromoCodeResponse
  throw await response.json()
}
