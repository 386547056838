import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrivacyContext } from 'context/privacy'
import BasicButton from 'components/common/buttons/BasicButton'

type CPProps = {
  readonly className?: string
  readonly setDisplayCookieSettings: Dispatch<SetStateAction<boolean>>
}

export const CookiePrompt: FC<CPProps> = ({ className, setDisplayCookieSettings }) => {
  const { t: translated } = useTranslation()
  const { setIsCookieConsentModalVisible, setConsent } = usePrivacyContext()

  const handleAcceptAll = useCallback(() => {
    setConsent({
      isCookieConsentDefinedByUser: true,
      isCookieConsentGiven: true
    })
    setIsCookieConsentModalVisible(false)
  }, [setConsent, setIsCookieConsentModalVisible])

  return (
    <div
      className={
        'pt-4 pb-6 px-8 flex flex-col gap-4 text-sm md:text-base' +
        (className ? ` ${className}` : '')
      }
    >
      <p>
        {translated(
          'This website uses cookies to ensure you get the best experience on our website.'
        )}
      </p>

      <div className='flex flex-col sm:flex-row sm:justify-center gap-4'>
        <button className='p-2 underline' onClick={() => setDisplayCookieSettings(true)}>
          {translated('Cookie settings')}
        </button>

        <BasicButton className='py-2 px-4 bg-primary text-white' onClick={handleAcceptAll}>
          {translated('Accept all')}
        </BasicButton>
      </div>
    </div>
  )
}

export default CookiePrompt
