import { FC } from 'react'

export type TIconType =
  | 'menu'
  | 'cross'
  | 'sign-in'
  | 'sign-out'
  | 'arrow-corner'
  | 'chevron-down'
  | 'chevron-down-semibold'
  | 'check'
  | 'spinner'
  | 'search'
  | 'launch'
  | 'arrow-back'
  | 'triangle-sm-down'
  | 'calendar'
  | 'clock'
  | 'location-marker'
  | 'media-play'
  | 'media-pause'
  | 'success'
  | 'warning'
  | 'information'

export interface IIconProps {
  readonly name: TIconType
  readonly className?: string
}

const icons: { [key in TIconType]: (extraClassName: string) => JSX.Element } = {
  menu: (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 20 20' className={extraClassName}>
      <path d='M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z'></path>
    </svg>
  ),
  cross: (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 20 20' className={extraClassName}>
      <path d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'></path>
    </svg>
  ),
  'sign-in': (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 512 512' className={extraClassName}>
      <path d='M184 83.5l164.5 164c4.7 4.7 4.7 12.3 0 17L184 428.5c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17l132-131.4H12c-6.6 0-12-5.4-12-12v-10c0-6.6 5.4-12 12-12h279.9L160 107.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.6-4.7 12.2-4.7 16.9 0zM512 400V112c0-26.5-21.5-48-48-48H332c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h132c8.8 0 16 7.2 16 16v288c0 8.8-7.2 16-16 16H332c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h132c26.5 0 48-21.5 48-48z'></path>
    </svg>
  ),
  'sign-out': (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 512 512' className={extraClassName}>
      <path d='M48 64h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-8.8 0-16 7.2-16 16v288c0 8.8 7.2 16 16 16h132c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48zm279 19.5l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l132 131.4H172c-6.6 0-12 5.4-12 12v10c0 6.6 5.4 12 12 12h279.9L320 404.4c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l164.5-164c4.7-4.7 4.7-12.3 0-17L344 83.5c-4.7-4.7-12.3-4.7-17 0z'></path>
    </svg>
  ),
  'arrow-corner': (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 9 9' className={extraClassName}>
      <path d='M8.343 0H1.777v1.313h4.981L0 8.071.929 9l6.758-6.758v4.98H9V0h-.657z'></path>
    </svg>
  ),
  'chevron-down': (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 448 512' className={extraClassName}>
      <path d='M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z'></path>
    </svg>
  ),
  'chevron-down-semibold': (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 448 512' className={extraClassName}>
      <path d='M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z'></path>
    </svg>
  ),
  check: (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 12 12' className={extraClassName}>
      <path d='M10.293,1.293,4,7.586,1.707,5.293A1,1,0,0,0,.293,6.707l3,3a1,1,0,0,0,1.414,0l7-7a1,1,0,0,0-1.414-1.414Z'></path>
    </svg>
  ),
  spinner: (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 512 512' className={extraClassName}>
      <path d='M296 48c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm-40 376c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm248-168c0-22.091-17.909-40-40-40s-40 17.909-40 40 17.909 40 40 40 40-17.909 40-40zm-416 0c0-22.091-17.909-40-40-40S8 233.909 8 256s17.909 40 40 40 40-17.909 40-40zm20.922-187.078c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c0-22.092-17.909-40-40-40zm294.156 294.156c-22.091 0-40 17.909-40 40s17.909 40 40 40c22.092 0 40-17.909 40-40s-17.908-40-40-40zm-294.156 0c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40z'></path>
    </svg>
  ),
  search: (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 512 512' className={extraClassName}>
      <path d='M508.5 468.9L387.1 347.5c-2.3-2.3-5.3-3.5-8.5-3.5h-13.2c31.5-36.5 50.6-84 50.6-136C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c52 0 99.5-19.1 136-50.6v13.2c0 3.2 1.3 6.2 3.5 8.5l121.4 121.4c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17zM208 368c-88.4 0-160-71.6-160-160S119.6 48 208 48s160 71.6 160 160-71.6 160-160 160z'></path>{' '}
    </svg>
  ),
  launch: (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 12 12' className={extraClassName}>
      <path d='M2,12H8a2,2,0,0,0,2-2V9A1,1,0,0,0,8,9v1H2V4H3A1,1,0,0,0,3,2H2A2,2,0,0,0,0,4v6A2,2,0,0,0,2,12Z'></path>
      <path d='M11.5,0h-5a.5.5,0,0,0-.354.854L7.939,2.646,5.293,5.293A1,1,0,1,0,6.707,6.707L9.354,4.061l1.792,1.793A.5.5,0,0,0,11.5,6a.512.512,0,0,0,.191-.038A.5.5,0,0,0,12,5.5V.5A.5.5,0,0,0,11.5,0Z'></path>
    </svg>
  ),
  'arrow-back': (extraClassName: string) => (
    <svg fill='currentColor' viewBox='0 0 12 12' className={extraClassName}>
      <path d='M11,5H3.414L5.707,2.707A1,1,0,0,0,4.293,1.293l-4,4a1,1,0,0,0,0,1.416l4,4A1,1,0,1,0,5.707,9.293L3.414,7H11a1,1,0,0,0,0-2Z' />
    </svg>
  ),
  'triangle-sm-down': (extraClassName: string) => (
    <svg viewBox='0 0 16 16' className={extraClassName}>
      <g fill='currentColor'>
        <polygon points='3,5 8,11 13,5 '></polygon>
      </g>
    </svg>
  ),
  calendar: (extraClassName: string) => (
    <svg className={extraClassName} fill='none' stroke='currentColor' viewBox='0 0 24 24'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z'
      ></path>
    </svg>
  ),
  clock: (extraClassName: string) => (
    <svg className={extraClassName} fill='none' stroke='currentColor' viewBox='0 0 24 24'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z'
      ></path>
    </svg>
  ),
  'location-marker': (extraClassName: string) => (
    <svg className={extraClassName} fill='none' stroke='currentColor' viewBox='0 0 24 24'>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z'
      ></path>
      <path
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='2'
        d='M15 11a3 3 0 11-6 0 3 3 0 016 0z'
      ></path>
    </svg>
  ),
  'media-play': (extraClassName: string) => (
    <svg className={extraClassName} fill='currentColor' viewBox='0 0 48 48'>
      <g>
        <path d='M24,47C11.31738,47,1,36.68262,1,24S11.31738,1,24,1s23,10.31738,23,23S36.68262,47,24,47z M24,5 C13.52344,5,5,13.52344,5,24s8.52344,19,19,19s19-8.52344,19-19S34.47656,5,24,5z' />
        <path d='M19,33c-0.16211,0-0.32422-0.03906-0.47168-0.11816C18.20312,32.70801,18,32.36914,18,32V16 c0-0.36914,0.20312-0.70801,0.52832-0.88184c0.32422-0.17383,0.71973-0.15332,1.02637,0.0498l12,8 C31.83301,23.35352,32,23.66602,32,24s-0.16699,0.64648-0.44531,0.83203l-12,8C19.3877,32.94336,19.19434,33,19,33z' />
      </g>
    </svg>
  ),
  'media-pause': (extraClassName: string) => (
    <svg className={extraClassName} fill='currentColor' viewBox='0 0 48 48'>
      <g>
        <path d='M19,33L19,33c-1.10457,0-2-0.89543-2-2V17c0-1.10457,0.89543-2,2-2h0c1.10457,0,2,0.89543,2,2v14 C21,32.10457,20.10457,33,19,33z'></path>
        <path d='M29,33L29,33c-1.10457,0-2-0.89543-2-2V17c0-1.10457,0.89543-2,2-2h0c1.10457,0,2,0.89543,2,2v14 C31,32.10457,30.10457,33,29,33z'></path>
        <path d='M24,47C11.31738,47,1,36.68262,1,24S11.31738,1,24,1s23,10.31738,23,23S36.68262,47,24,47z M24,5 C13.52344,5,5,13.52344,5,24s8.52344,19,19,19s19-8.52344,19-19S34.47656,5,24,5z'></path>
      </g>
    </svg>
  ),
  success: (extraClassName: string) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`h-6 w-6 ${extraClassName}`}
      viewBox='0 0 16 16'
    >
      <path
        d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M7,11.4L3.6,8L5,6.6l2,2l4-4L12.4,6L7,11.4z'
        fill='currentColor'
      />
    </svg>
  ),
  warning: (extraClassName: string) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`h-6 w-6 ${extraClassName}`}
      viewBox='0 0 16 16'
    >
      <path
        d='M15.8,12.526,9.483.88A1.668,1.668,0,0,0,8.8.2,1.693,1.693,0,0,0,6.516.88L.2,12.526A1.678,1.678,0,0,0,1.686,15H14.314a1.7,1.7,0,0,0,.8-.2,1.673,1.673,0,0,0,.687-2.274ZM8,13a1,1,0,1,1,1-1A1,1,0,0,1,8,13ZM9,9.5a.5.5,0,0,1-.5.5h-1A.5.5,0,0,1,7,9.5v-4A.5.5,0,0,1,7.5,5h1a.5.5,0,0,1,.5.5Z'
        fill='currentColor'
      />
    </svg>
  ),
  information: (extraClassName: string) => (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={`h-5 w-5 ${extraClassName}`}
      viewBox='0 0 16 16'
    >
      <path
        d='M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M9,12H7V7h2V12z M8,6C7.4,6,7,5.6,7,5s0.4-1,1-1 s1,0.4,1,1S8.6,6,8,6z'
        fill='currentColor'
      />
    </svg>
  )
}

export const Icon: FC<IIconProps> = ({ name, className }) => {
  const IconFn = icons[name]
  return IconFn ? IconFn(className ?? '') : null
}

export default Icon
