import { PropsWithChildren, useEffect, useRef } from 'react'

export type StepType<T> = {
  id: T
  label: string
  icon: JSX.Element
  click: null | (() => void)
}

type SLProps<T> = {
  steps: StepType<T>[]
  activeStep: T
  scrollIntoViewOnLoad?: boolean
  customClasses?: string
}

const StepLine = <T,>({
  steps,
  activeStep,
  scrollIntoViewOnLoad = true,
  customClasses
}: PropsWithChildren<SLProps<T>>) => {
  const activeStepRef = useRef<HTMLButtonElement>(null)
  const indexOfActiveStep = steps.findIndex(step => step.id === activeStep)

  useEffect(() => {
    if (scrollIntoViewOnLoad && activeStepRef.current) {
      activeStepRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }, [activeStepRef, scrollIntoViewOnLoad])

  return (
    <div className={'relative w-full overflow-x-auto ' + customClasses}>
      <ul className={'relative flex justify-stretch'}>
        {steps.map(({ id, label, icon, click }, index) => {
          const isCurrentStep = index === indexOfActiveStep
          const isCompletedStep = index < indexOfActiveStep
          const isFutureStep = index > indexOfActiveStep
          const isFirstStep = index === 0
          const isLastStep = index === steps.length - 1

          return (
            <li
              key={id as string}
              className={
                'group shrink-0 grow' + ' flex flex-col justify-evenly items-stretch gap-1'
              }
            >
              <div
                className={
                  'w-full basis-20 shrink-0 grow' +
                  ' flex justify-center items-center gap-1 md:gap-2'
                }
              >
                {/* --------------- left-side line: start --------------- */}
                <span
                  className={
                    'basis-6 shrink-0 grow' +
                    ' bg-transparent border-t-2 border-secondary-darker' +
                    (isFutureStep ? ' border-dotted' : ' border-solid') +
                    (isFirstStep ? ' opacity-0' : ' opacity-100')
                  }
                />
                {/* --------------- left-side line: end --------------- */}

                <button
                  className={
                    'z-10 h-9 w-9 shrink-0 grid place-content-center' +
                    ' rounded-full transition-colors duration-300' +
                    ' border-2' +
                    (isCurrentStep
                      ? ' border-primary bg-secondary text-primary cursor-default'
                      : isCompletedStep
                      ? ' border-transparent bg-secondary text-primary cursor-pointer'
                      : ' border-secondary-darker bg-secondary-lighter text-secondary-darker') +
                    (isCompletedStep ? ' hover:bg-primary hover:text-white' : '') +
                    ' disabled:cursor-default'
                  }
                  onClick={() => (isCompletedStep && click ? click() : null)}
                  disabled={isFutureStep}
                  ref={isCurrentStep ? activeStepRef : null}
                >
                  {icon}
                </button>

                {/* --------------- right-side line: start --------------- */}
                <span
                  className={
                    'basis-6 shrink-0 grow' +
                    ' bg-transparent border-t-2 border-secondary-darker' +
                    (isCurrentStep || isFutureStep ? ' border-dotted' : ' border-solid') +
                    (isLastStep ? ' opacity-0' : ' opacity-100')
                  }
                />
                {/* --------------- right-side line: end --------------- */}
              </div>

              {/* --------------- label: start --------------- */}
              <div
                className={
                  'z-0 relative -top-6 overflow-x-visible' +
                  ' opacity-0 group-hover:top-0 group-hover:opacity-100' +
                  ' transition-all duration-300'
                }
              >
                {/* --- invisible placeholder: start --- */}
                <p className='invisible opacity-0 w-0 h-0'>step</p>
                {/* --- invisible placeholder: end --- */}
                {/* --- visible label: start --- */}
                {/* The actual label has an absolute position to prevent it from affecting the width of the step */}
                <p className='absolute top-0 left-1/2 -translate-x-1/2 text-primary text-xs whitespace-nowrap'>
                  {label}
                </p>
                {/* --- visible label: end --- */}
              </div>
              {/* --------------- label: end --------------- */}
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default StepLine
