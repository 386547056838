import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AnimatePresence, motion } from 'framer-motion'

import { AiOutlineMail } from 'react-icons/ai'
import { BsFillPencilFill } from 'react-icons/bs'
import { FiPhone } from 'react-icons/fi'
import { ImSpinner2 } from 'react-icons/im'
import { MdOutlineSearch } from 'react-icons/md'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import { RxMobile } from 'react-icons/rx'
import { TfiLocationPin } from 'react-icons/tfi'

import type { InputFieldNames, ServerSideAddressFieldNames } from 'typings/addressFormTypes'
import type { IGenericServerError } from 'typings/errorTypes'
import type {
  DeliveryMethodError,
  IBasketWithDeliveryAndItemDetails,
  IDeliveryMethodErrorResponse
} from 'typings/checkoutApi'

import { getCountryName } from 'utils/locationUtils'
import { submitDeliveryMethod } from 'services/checkout'
import { useAddressForm } from 'hooks/useAddressForm'
import { useCheckoutContext } from 'context/checkout'
import { useAuthContext } from 'context/auth'
import { useTelemetryContext } from 'context/insights'
import { useAppContext } from 'context/app'

import { containerVariants, contentVariants } from './DeliveryAddressPage.motion'
import { showToast } from 'components/layout/ToastNotification'
import PersistentPortal from 'components/common/PersistentPortal'
import Button from 'components/common/buttons/Button'
import TextInput from 'components/common/TextInput'
import DeliveryMethodCard from 'components/delivery/DeliveryMethodCard'
import TranslatedErrorBox from 'components/common/TranslatedErrorBox'
import VWInfo from 'components/vw/VWInfo'

const formFieldIdMap = new Map<ServerSideAddressFieldNames, InputFieldNames>([
  ['addressLine1', 'delivery-address-01'],
  ['addressLine2', 'delivery-address-02'],
  ['city', 'delivery-city'],
  ['countryCode', 'delivery-country-code'],
  ['county', 'delivery-county'],
  ['district', 'delivery-district'],
  ['email', 'delivery-email'],
  ['federalState', 'delivery-state'],
  ['streetPob', 'delivery-po-box'],
  ['zipCode', 'delivery-zip-code']
])

const DeliveryAddressPage: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {
    cartId: cartIdPathParam,
    localeCode: localeCodePathParam,
    vcartId: vcartIdPathParam
  } = useParams()
  const queryClient = useQueryClient()
  const { accessToken, isCustomer, partnerId } = useAuthContext()
  const { t: translated } = useTranslation()
  const { appInsights } = useTelemetryContext()
  const { shopId } = useAppContext()
  const {
    defaultAddress,
    pickupDeliveryMethods,
    nonPickupDeliveryMethods,
    isPickupSelected,
    idOfSelectedShippingMethod,
    setIdOfSelectedShippingMethod,
    goesToVirtualWarehouse,
    isPaid,
    isCanceled,
    checkoutCartContentQueryKey
  } = useCheckoutContext()
  const searchInputRef = useRef<HTMLInputElement>(null)
  const hasFormBeenPopulatedRef = useRef(false)
  const { addressFormFields, isAddressFormValid, addressFormDispatch } = useAddressForm()
  const [deliveryMethodError, setDeliveryMethodError] = useState<DeliveryMethodError>()
  const [hasCustomAddress, setHasCustomAddress] = useState(false)
  const [pickupSearchInput, setPickupSearchInput] = useState('')

  const cartId = cartIdPathParam || vcartIdPathParam || ''

  const filteredPickupDeliveryMethods = useMemo(
    () =>
      pickupDeliveryMethods.filter(method =>
        method?.localizedLabels?.[0]?.name
          ?.toLowerCase()
          ?.includes(pickupSearchInput?.toLocaleLowerCase())
      ),
    [pickupDeliveryMethods, pickupSearchInput]
  )

  const deliveryAddress = useMemo(
    () =>
      hasCustomAddress
        ? {
            addressLine1: addressFormFields?.get('delivery-address-01')?.value ?? '',
            addressLine2: addressFormFields?.get('delivery-address-02')?.value ?? '',
            city: addressFormFields?.get('delivery-city')?.value ?? '',
            countryCode: addressFormFields?.get('delivery-country-code')?.value ?? '',
            // county: addressFormFields?.get('delivery-county')?.value ?? '',
            // district: addressFormFields?.get('delivery-district')?.value ?? '',
            email: addressFormFields?.get('delivery-email')?.value ?? '',
            // federalState: addressFormFields?.get('delivery-state')?.value ?? '',
            mobile1: addressFormFields?.get('delivery-mobile-01')?.value ?? '',
            // mobile2: addressFormFields?.get('delivery-mobile-02')?.value ?? '',
            phone1: addressFormFields?.get('delivery-phone-01')?.value ?? '',
            // phone2: addressFormFields?.get('delivery-phone-02')?.value ?? '',
            streetPob: addressFormFields?.get('delivery-po-box')?.value ?? '',
            zipCode: addressFormFields?.get('delivery-zip-code')?.value ?? ''
          }
        : defaultAddress,
    [addressFormFields, defaultAddress, hasCustomAddress]
  )

  const submitDeliveryMethodMutation = useMutation<
    IBasketWithDeliveryAndItemDetails,
    IDeliveryMethodErrorResponse | IGenericServerError
  >({
    mutationFn: () =>
      submitDeliveryMethod(
        cartId,
        accessToken ?? '',
        {
          goesToVirtualWarehouse,
          selectedDeliveryMethodId: idOfSelectedShippingMethod ?? '',
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          address: deliveryAddress!
        },
        localeCodePathParam ?? ''
      ),
    onSuccess: responseData => {
      appInsights?.trackEvent({
        name: 'CheckoutDeliverySubmission',
        properties: {
          isTeamPartner: !isCustomer,
          customerId: partnerId,
          shopId,
          cartId,
          deliveryMethod: idOfSelectedShippingMethod,
          hasCustomAddress,
          status: 'success'
        }
      })
      queryClient.setQueryData(checkoutCartContentQueryKey, responseData)
      navigate(
        { pathname: '../mm', search: location.search },
        { state: { source: 'checkout-address' } }
      )
    },
    onError: deliveryMethodValidationError => {
      appInsights?.trackEvent({
        name: 'CheckoutDeliverySubmission',
        properties: {
          isTeamPartner: !isCustomer,
          customerId: partnerId,
          shopId,
          cartId,
          deliveryMethod: idOfSelectedShippingMethod,
          hasCustomAddress,
          status: 'error',
          error: deliveryMethodValidationError
        }
      })
      if ('globalValidationResults' in deliveryMethodValidationError) {
        setDeliveryMethodError(deliveryMethodValidationError.globalValidationResults)
      } else {
        showToast({
          type: 'error',
          title: translated('Failed to submit delivery method.'),
          message: JSON.stringify(deliveryMethodValidationError)
        })
      }
    }
  })

  // as soon as form data is fetched from the back-end, fill the mandatory, read-only country field
  useEffect(() => {
    if (hasFormBeenPopulatedRef.current) return
    if (!defaultAddress) return

    addressFormDispatch({
      type: 'populateFormFields',
      payload: [
        {
          id: formFieldIdMap.get('countryCode') as InputFieldNames,
          value: defaultAddress.countryCode
        }
      ]
    })
    hasFormBeenPopulatedRef.current = true
  }, [addressFormDispatch, defaultAddress, localeCodePathParam])

  const handleTextInputChange = useCallback(
    (id: InputFieldNames) => (value: string) =>
      addressFormDispatch({
        type: 'setInputValue',
        payload: { id, value }
      }),
    [addressFormDispatch]
  )

  const isSelectedDeliveryMethodShippingType =
    nonPickupDeliveryMethods?.find(method => method.id === idOfSelectedShippingMethod)?.type ===
    'shipment'

  const isFormComplete =
    !!idOfSelectedShippingMethod &&
    (!isSelectedDeliveryMethodShippingType || !hasCustomAddress || isAddressFormValid)

  const handleDeliveryMethodSubmission = useCallback(() => {
    if (!deliveryAddress) return setDeliveryMethodError('missing_delivery_address')
    if (isFormComplete && !submitDeliveryMethodMutation.isLoading) {
      submitDeliveryMethodMutation.mutate()
    }
  }, [deliveryAddress, isFormComplete, submitDeliveryMethodMutation])

  return !isSelectedDeliveryMethodShippingType && !isPickupSelected ? (
    <Navigate
      to={{
        pathname: '../delivery',
        search: location.search
      }}
      replace={true}
    />
  ) : (
    <motion.div
      className={
        'w-full max-w-3xl mx-auto sm:p-4' +
        ' flex-1 overflow-hidden' +
        ' flex flex-col' +
        ' bg-white' +
        ' border border-secondary rounded-t-md' +
        ' shadow-md'
      }
      variants={containerVariants}
    >
      <VWInfo isVisible={goesToVirtualWarehouse} />

      {/* --------------- error box: start --------------- */}
      <TranslatedErrorBox errorMessage={deliveryMethodError} shouldFocus={true} />
      {/* --------------- error box: end --------------- */}

      {/* --------------- main content: start --------------- */}
      <motion.div className={'flex-1 container p-4'} variants={contentVariants}>
        <div className='w-full space-y-4 mb-4 md:mb-6'>
          <h3 className='text-center text-base font-bold leading-6 text-gray-700'>
            {isPickupSelected ? translated('Pick-up location') : translated('Shipping address')}
          </h3>

          {isPickupSelected && (
            <label
              className={
                'h-10 w-96 max-w-full mx-auto px-3' +
                ' flex justify-between items-center gap-2' +
                ' rounded-full border-2 border-secondary-darker bg-white'
              }
              htmlFor='pickup-location-search-input'
            >
              <MdOutlineSearch size={24} className='text-primary animate-pulse' />

              <input
                type='text'
                className={
                  'w-full' +
                  ' bg-transparent' +
                  ' placeholder:text-gray-400' +
                  ' focus:outline-none focus:ring-transparent focus-visible:ring'
                }
                placeholder={translated('Search pick-up location')}
                ref={searchInputRef}
                value={pickupSearchInput}
                onChange={event => {
                  setPickupSearchInput(event.target.value)
                  if (idOfSelectedShippingMethod) setIdOfSelectedShippingMethod(undefined)
                }}
                name='pickup-location-search-input'
                id='pickup-location-search-input'
              />
            </label>
          )}

          {/* --------------- pick-up method options: start --------------- */}
          {isPickupSelected && (
            <form className={'w-full mx-auto space-y-4'}>
              {filteredPickupDeliveryMethods.map(deliveryMethod => (
                <DeliveryMethodCard
                  key={deliveryMethod.id}
                  id={deliveryMethod.id}
                  name={deliveryMethod.localizedLabels[0].name}
                  details={deliveryMethod.localizedLabels[0].info}
                  price={deliveryMethod.cost.final}
                  currencyCode={deliveryMethod.cost.currencyCode}
                  isSelected={idOfSelectedShippingMethod === deliveryMethod.id}
                  handleSelection={() => setIdOfSelectedShippingMethod(deliveryMethod.id)}
                  customClasses='mx-auto'
                />
              ))}
            </form>
          )}
          {/* --------------- pick-up method options: end --------------- */}

          {/* --------------- address panels: start --------------- */}
          {isSelectedDeliveryMethodShippingType && (
            <div className='relative w-full overflow-hidden'>
              <AnimatePresence mode='popLayout'>
                {isSelectedDeliveryMethodShippingType && hasCustomAddress ? (
                  // --------------- shipping address panel: start ---------------
                  <motion.div
                    className='w-full space-y-2 md:space-y-4'
                    key='shipping-address-form'
                    initial={{ x: '100%' }}
                    animate={{ x: '0%' }}
                    exit={{ x: '100%' }}
                    transition={{
                      tension: 190,
                      friction: 70,
                      mass: 0.4
                    }}
                  >
                    <button
                      className='ml-2 sm:ml-0 flex items-center text-primary'
                      onClick={() => setHasCustomAddress(false)}
                    >
                      <RiArrowLeftSLine size={24} className='inline-block' />
                      <span className=''>{translated('Use default address')}</span>
                    </button>

                    <form
                      id='shipping-address-form'
                      className={
                        'w-full py-2' +
                        ' flex flex-col justify-start sm:justify-evenly items-center sm:items-stretch gap-2'
                      }
                    >
                      {Array.from(addressFormFields.entries()).map(
                        ([inputFieldId, { label, value, errors, readOnly, required }]) => (
                          <TextInput<InputFieldNames>
                            key={inputFieldId}
                            id={inputFieldId}
                            label={label}
                            value={
                              inputFieldId === 'delivery-country-code'
                                ? getCountryName(value, localeCodePathParam ?? '') ?? value
                                : value
                            }
                            errors={errors}
                            changeHandler={handleTextInputChange(inputFieldId)}
                            customWrapperClasses='w-full md:px-1'
                            required={required}
                            readOnly={readOnly}
                          />
                        )
                      )}
                    </form>
                  </motion.div>
                ) : (
                  // --------------- shipping address panel: end ---------------

                  // --------------- default address panel: start ---------------
                  <motion.div
                    className='w-full space-y-2 md:space-y-4'
                    initial={{ x: '-100%' }}
                    animate={{ x: '0%' }}
                    exit={{ x: '-100%' }}
                    transition={{
                      tension: 190,
                      friction: 70,
                      mass: 0.4
                    }}
                    key='billing-address-form'
                  >
                    <div
                      className={
                        'w-96 max-w-full mx-auto space-y-1 py-4 px-6' +
                        ' rounded-md border-2' +
                        ' border-secondary' +
                        ' text-sm'
                      }
                    >
                      <div className='grid grid-cols-[1.25rem_1fr] gap-2 items-start'>
                        <div className='justify-self-start'>
                          <TfiLocationPin size={18} className='inline-block' />
                        </div>

                        <div className='inline-block'>
                          {defaultAddress?.addressLine1 && <p>{defaultAddress?.addressLine1}</p>}
                          {defaultAddress?.addressLine2 && <p>{defaultAddress?.addressLine2}</p>}
                          {defaultAddress?.streetPob && <p>{defaultAddress?.streetPob}</p>}
                          <p>
                            {defaultAddress?.city && <span>{defaultAddress?.city},&nbsp;</span>}
                            {defaultAddress?.zipCode && <span>{defaultAddress?.zipCode}</span>}
                          </p>
                          {/* Temporarily hard-disabling: county, district and state */}
                          {/* TODO: Must be re-enabled when back-end logic becomes available */}
                          {/* <p>
                          {defaultAddress?.county && <span>{defaultAddress?.county},&nbsp;</span>}
                          {defaultAddress?.district && (
                            <span>{defaultAddress?.district},&nbsp;</span>
                          )}

                          {defaultAddress?.federalState && (
                            <span>{defaultAddress?.federalState}</span>
                          )}
                        </p> */}
                          {defaultAddress?.countryCode && (
                            <p>
                              {getCountryName(
                                defaultAddress?.countryCode,
                                localeCodePathParam ?? ''
                              )}
                            </p>
                          )}
                        </div>
                      </div>

                      {defaultAddress?.email && (
                        <div className='grid grid-cols-[1.25rem_1fr] gap-2 items-start'>
                          <div className='justify-self-start'>
                            <AiOutlineMail size={17} className='inline-block' />
                          </div>

                          <div className='inline-block'>
                            <p>{defaultAddress?.email}</p>
                          </div>
                        </div>
                      )}

                      {(defaultAddress?.mobile1 || defaultAddress?.mobile2) && (
                        <div className='grid grid-cols-[1.25rem_1fr] gap-2 items-start'>
                          <div className='justify-self-start'>
                            <RxMobile size={20} className='-ml-0.5' />
                          </div>

                          <div className='inline-block'>
                            <p>{defaultAddress?.mobile1 || defaultAddress?.mobile2}</p>
                          </div>
                        </div>
                      )}

                      {(defaultAddress?.phone1 || defaultAddress?.phone2) && (
                        <div className='grid grid-cols-[1.25rem_1fr] gap-2 items-start'>
                          <div className='justify-self-start'>
                            <FiPhone size={17} className='inline-block' />
                          </div>

                          <div className='inline-block'>
                            <p>{defaultAddress?.phone1 || defaultAddress?.phone2}</p>
                          </div>
                        </div>
                      )}
                    </div>

                    <AnimatePresence>
                      {isSelectedDeliveryMethodShippingType && (
                        <motion.button
                          className='mx-auto flex justify-center items-center gap-2 text-primary'
                          onClick={() => setHasCustomAddress(true)}
                          initial={{ opacity: 0, x: '50%' }}
                          animate={{ opacity: 1, x: '0%' }}
                          exit={{ opacity: 0, x: '50%' }}
                          transition={{ duration: 0.3 }}
                        >
                          <BsFillPencilFill size={16} className='inline-block' />
                          <span className=''>{translated('Ship to a different address')}</span>
                        </motion.button>
                      )}
                    </AnimatePresence>
                  </motion.div>
                  // --------------- default address panel: end ---------------
                )}
              </AnimatePresence>
            </div>
          )}
          {/* --------------- address panels: end --------------- */}
        </div>
      </motion.div>
      {/* --------------- main content: end --------------- */}

      {/* --------------- footer: start --------------- */}
      <PersistentPortal containerElementSelector='#footer-buttons'>
        {/* --------------- buttons: start --------------- */}
        <div className='flex flex-row justify-between items-center gap-4'>
          <button
            className='flex items-center text-primary'
            onClick={() =>
              navigate(
                { pathname: '../delivery', search: location.search },
                { state: { source: 'checkout-address' } }
              )
            }
          >
            <RiArrowLeftSLine size={24} className='inline-block' />
            <span>{translated('Back')}</span>
          </button>

          <Button
            onClick={handleDeliveryMethodSubmission}
            disabled={
              submitDeliveryMethodMutation.isLoading || !isFormComplete || isPaid || isCanceled
            }
            contentClassName='flex items-center'
            variant='primary'
          >
            {submitDeliveryMethodMutation.isLoading ? (
              <ImSpinner2 size={24} className='animate-spin' />
            ) : (
              <>
                <span>{translated('Confirm')}</span>
                <RiArrowRightSLine size={24} className='-mr-2' />
              </>
            )}
          </Button>
        </div>
        {/* --------------- buttons: end --------------- */}
      </PersistentPortal>
      {/* --------------- footer: end --------------- */}
    </motion.div>
  )
}

export default DeliveryAddressPage
