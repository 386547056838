const NotFoundPage = () => {
  return (
    <div className='fixed inset-0 w-screen flex flex-col items-center justify-center overflow-x-hidden overflow-y-auto bg-page-bgcolor'>
      <h1 className='text-5xl md:text-9xl'>404 Error</h1>
      <h2 className='text-2xl md:text-5xl'>Page not found</h2>
    </div>
  )
}

export default NotFoundPage
