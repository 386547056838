import { FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import type { IOrderItem } from 'typings/orderTypes'

import { BsCalendar2Week, BsThreeDots } from 'react-icons/bs'
import { RiArrowRightSLine } from 'react-icons/ri'
import { FaFileInvoiceDollar } from 'react-icons/fa'

import PMPoints from 'components/common/PMPoints'

type OrderCardProps = {
  id: string
  referenceNumber: string
  date: Date
  price: string
  points: number
  customClasses?: string
  items: IOrderItem[]
}

const OrderCard: FC<OrderCardProps> = ({
  id,
  referenceNumber,
  date,
  price,
  points,
  items,
  customClasses
}) => {
  const { localeCode, shopId } = useParams()
  const { t: translated } = useTranslation()

  const numItems = items.length
  const numItemsInOrder = items.reduce((total, item) => total + item.quantity, 0)

  return (
    <div
      className={
        'max-w-lg md:max-w-xl mx-auto py-4 px-2' +
        ' rounded-lg border border-secondary-darker' +
        ' bg-white' +
        ' text-sm' +
        ' shadow-md' +
        (customClasses ? ` ${customClasses}` : '')
      }
    >
      <div className={'flex justify-between' + ' pb-3 px-1' + ' border-b border-secondary'}>
        <p className={'flex items-center gap-1' + ' text-gray-400'}>
          <span
            className={
              'grid place-content-center' +
              ' h-7 w-7' +
              ' rounded-full bg-secondary-darker text-white'
            }
          >
            <FaFileInvoiceDollar size={17} />
          </span>
          <span>#{referenceNumber}</span>
        </p>

        <p className='flex items-center gap-2'>
          <BsCalendar2Week size={18} />
          <span>{date.toLocaleDateString()}</span>
        </p>
      </div>

      {/* ---------- list of items in order ---------- */}
      <div className='py-3 px-2'>
        <ul className={numItems > 3 ? ' mask-bottom' : ''}>
          {items.slice(0, 3).map(item => (
            <li key={item.articleNumber}>
              <p className={'flex gap-1' + ' overflow-hidden whitespace-nowrap'}>
                <span className={'text-gray-500 text-right font-light'}>{item.quantity} x</span>

                <span className='overflow-hidden whitespace-nowrap text-ellipsis'>{item.name}</span>
              </p>
            </li>
          ))}
        </ul>
        {numItems > 3 ? (
          <p className='pl-5 pt-1 md:pt-2'>
            <BsThreeDots size={16} className='text-gray-500' />
          </p>
        ) : null}
      </div>

      {/* ---------- totals ---------- */}
      <div className={'flex justify-between' + ' py-2 px-2' + ' bg-secondary'}>
        <p className={'flex items-center gap-1' + ' font-bold'}>
          <span>{numItemsInOrder} x</span>
          <span className='test'>{translated('Items')}</span>
        </p>

        <PMPoints value={points} customClasses='text-sm font-bold' />

        <p className={'flex items-center gap-2' + ' font-bold'}>
          <span>{price}</span>
        </p>
      </div>

      {/* ---------- link to details ---------- */}
      <div className={'flex justify-end' + ' pt-3'}>
        <Link
          to={{ pathname: `/${localeCode}/shop/${shopId}/orders/${id}`, search: location.search }}
          className={
            'flex items-center text-primary' +
            ' py-1 pl-3 pr-1' +
            ' rounded-lg border border-transparent' +
            ' hover:bg-primary/5'
          }
        >
          <span>{translated('See details')}</span>
          <RiArrowRightSLine size={20} className='inline-block' />
        </Link>
      </div>
    </div>
  )
}

export default OrderCard
