import { FC, ReactNode } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'

import { AiOutlineShop } from 'react-icons/ai'
import { MdChevronRight } from 'react-icons/md'

import PersistentPortal from 'components/common/PersistentPortal'

type ShopBreadcrumbsProps = {
  routeSymbols: ReactNode[]
  customClasses?: string
}

const ShopBreadcrumbs: FC<ShopBreadcrumbsProps> = ({ routeSymbols, customClasses }) => {
  const location = useLocation()
  const { localeCode, shopId } = useParams()

  return (
    <PersistentPortal containerElementSelector='#header-additional-content'>
      <nav
        className={
          'h-14 container mx-auto mt-2 px-4 md:px-14 pb-7 overflow-hidden' +
          ' flex items-end gap-1' +
          (customClasses ? ` ${customClasses}` : '')
        }
      >
        <Link
          to={{
            pathname: `/${localeCode}/shop/${shopId}/products`,
            search: location.search,
            hash: location.hash
          }}
        >
          <AiOutlineShop size={24} className='inline-block text-primary' />
        </Link>

        {routeSymbols.map((routeNode, index) => (
          <div className='contents' key={`breadcrumb-item-${index}`}>
            <MdChevronRight
              size={24}
              className='inline-block text-gray-500'
              key={`breadcrumb-divider-${index}`}
            />
            {routeNode}
          </div>
        ))}
      </nav>
    </PersistentPortal>
  )
}

export default ShopBreadcrumbs
