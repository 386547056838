import { FC, useCallback } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { FaBriefcase } from 'react-icons/fa'

import type { IOrderListResponse } from 'typings/orderTypes'
import type { IApiError, IGenericServerError } from 'typings/errorTypes'

import config from 'config/index'
import { formatPrice } from 'utils/priceUtils'
import { fetchShopOrders } from 'services/orders'
import { useAuthContext } from 'context/auth'

import { containerVariants, itemVariants, listVariants } from './OrderListPage.motion'

import ScreenCover from 'components/common/ScreenCover'
import SpinnerPM from 'components/common/SpinnerPM'
import CloudDownloadIcon from 'components/common/icons/CloudDownloadIcon'
import MessageBox from 'components/common/MessageBox'
import ShopBreadcrumbs from 'components/shop/ShopBreadcrumbs'
import Select from 'components/common/Select'
import PaginationControls from 'components/common/PaginationControls'
import OrderCard from './OrderCard'

const pageSizeOptions = [5, 10, 20, 50]

const OrderListPage: FC = () => {
  const { localeCode, shopId } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const queryClient = useQueryClient()
  const { accessToken, isCustomer } = useAuthContext()
  const { t: translated } = useTranslation()

  const pageSizeParam = searchParams.get('pageSize')
  const pageSize = Number(pageSizeParam) || pageSizeOptions[0]
  const pageNumberParam = searchParams.get('pageNumber')
  const pageNumber = Number(pageNumberParam) || 1

  const setPageSize = useCallback(
    (pageSize: number) => {
      searchParams.set('pageSize', String(pageSize))
      searchParams.set('pageNumber', '1')
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const setPageNumber = useCallback(
    (pageNum: number) => {
      searchParams.set('pageNumber', String(pageNum))
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  const {
    data: orderListData,
    error: orderListQueryError,
    isLoading: isOrderListLoading,
    isError: isOrderListQueryError
  } = useQuery<IOrderListResponse, IApiError | IGenericServerError>({
    enabled: !!shopId && isCustomer,
    queryKey: ['orders', shopId, pageNumber, pageSize],
    queryFn: () =>
      fetchShopOrders({
        shopId: shopId ?? '',
        accessToken: accessToken ?? '',
        pageNumber,
        pageSize
      }) as Promise<IOrderListResponse>,
    staleTime: Infinity,
    onSuccess: orderListData => {
      orderListData?.results?.forEach(order =>
        queryClient.setQueryData(['order', order?.id], order)
      )
    },
    onError: basketContentError => {
      console.error(basketContentError)
    }
  })

  if (!isCustomer) {
    window.location.assign(`${config.officeAppUrl}/account/orders`)
    return (
      <ScreenCover>
        <SpinnerPM>
          <FaBriefcase size={25} />
        </SpinnerPM>
      </ScreenCover>
    )
  }

  const numTotalOrders = orderListData?.paginationInfo?.totalRecords ?? 0

  return (
    <>
      <ShopBreadcrumbs
        routeSymbols={[
          <p
            key='breadcrumb-order-list'
            className='inline-block max-w-prose overflow-hidden whitespace-nowrap text-ellipsis'
          >
            {translated('My orders')}
          </p>
        ]}
      />

      <motion.div
        className={
          'w-full max-w-3xl mx-auto' +
          ' grid grid-rows-[min-content_1fr_min-content]' +
          ' mb-4 md:mb-6 px-2 md:px-4 pt-0'
        }
        variants={containerVariants}
      >
        {numTotalOrders > pageSizeOptions[0] ? (
          <div className='w-full mb-2 md:mb-4 pb-2 px-4 rounded-md'>
            <div className='ml-auto flex items-center justify-end gap-2'>
              <p className='text-sm'>{translated('Number of items per page')}</p>
              <Select
                options={pageSizeOptions}
                selected={pageSize}
                setSelected={setPageSize}
                className='w-16 z-10'
              />
            </div>
          </div>
        ) : null}

        {isOrderListQueryError ? (
          <div className='min-h-full grid place-content-center'>
            <MessageBox
              type='error'
              text={
                'status' in orderListQueryError && 'title' in orderListQueryError
                  ? `${orderListQueryError?.status} ${orderListQueryError?.title}`
                  : `${orderListQueryError?.statusCode} ${orderListQueryError?.message}`
              }
              showIcon={true}
            />
          </div>
        ) : isOrderListLoading ? (
          <div className='min-h-full grid place-content-center'>
            <SpinnerPM>
              <CloudDownloadIcon />
            </SpinnerPM>
          </div>
        ) : orderListData?.results?.length > 0 ? (
          <motion.ul
            className={
              'w-full max-w-lg md:max-w-xl mx-auto pb-4' +
              ' overflow-x-hidden overflow-y-scroll' +
              ' space-y-3 md:space-y-4'
            }
            variants={listVariants}
          >
            <AnimatePresence>
              {orderListData.results.map((order, index) => {
                const orderId = order.id
                const orderReferenceNumber = order.reference
                const orderCreationDate = new Date(order.createdOn)
                const orderItems = order.items
                const totalOrderAmount = order.totals.roundedTotalGross
                const totalOrderPoints = order.totals.points
                const currencyCode = order.totals.currencyCode

                return (
                  <motion.li
                    key={orderId}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                    exit={{
                      opacity: 0,
                      x: '50%',
                      transition: { duration: 0.1, delay: 0 }
                    }}
                    variants={itemVariants}
                  >
                    <OrderCard
                      id={orderId}
                      referenceNumber={orderReferenceNumber}
                      date={orderCreationDate}
                      price={formatPrice(totalOrderAmount, currencyCode, localeCode ?? '')}
                      points={totalOrderPoints}
                      items={orderItems}
                    />
                  </motion.li>
                )
              })}
            </AnimatePresence>
          </motion.ul>
        ) : (
          <div className='grid items-center'>
            <div className='h-24 w-full p-4 grid place-content-center rounded-md border border-gray-200'>
              <p className='text-gray-500'>{translated("You haven't placed any orders yet")}</p>
            </div>
          </div>
        )}

        <PaginationControls
          currentPage={pageNumber}
          itemsPerPage={pageSize}
          totalItems={numTotalOrders}
          setCurrentPage={setPageNumber}
          className='mt-2 p-2'
        />
      </motion.div>
    </>
  )
}

export default OrderListPage
