import { forwardRef } from 'react'

import CheckSoidIcon from './icons/CheckSolidIcon'
import CrossIcon from './icons/CrossIcon'
import InfoSolidIcon from './icons/InfoSolidIcon'
import WarningSoidIcon from './icons/WarningSolidIcon'

type MessageBoxProps = {
  readonly text: string | JSX.Element
  readonly type?: 'info' | 'success' | 'warning' | 'error'
  readonly hasBackgroundColor?: boolean
  readonly showIcon?: boolean
  readonly className?: string
  readonly onClose?: () => void
}

const MessageBox = forwardRef<HTMLDivElement, MessageBoxProps>(
  ({ text, type = 'info', className, showIcon, onClose, hasBackgroundColor = true }, ref) => {
    const boxBaseStyle = 'py-2 md:py-3 px-3 md:px-5 rounded grid items-start gap-3'
    const boxGridStyle =
      showIcon && onClose
        ? 'grid-cols-[min-content_1fr_min-content]'
        : showIcon
        ? 'grid-cols-[min-content_1fr]'
        : onClose
        ? 'grid-cols-[1fr_min-content]'
        : 'grid-cols-1'
    const boxBackgroundStyle = !hasBackgroundColor
      ? ''
      : type === 'info'
      ? 'bg-blue-50'
      : type === 'success'
      ? 'bg-green-50'
      : type === 'warning'
      ? 'bg-orange-50'
      : type === 'error'
      ? 'bg-red-50'
      : ''
    const boxStyle =
      `${boxBaseStyle} ${boxGridStyle} ${boxBackgroundStyle}` + (className ? ` ${className}` : '')
    const textStyle =
      'self-center text-sm md:text-base font-light tracking-wide' +
      (type === 'success'
        ? ' text-green-600'
        : type === 'warning'
        ? ' text-orange-600'
        : type === 'error'
        ? ' text-red-600'
        : ' text-blue-600')
    const signIconStyle = 'h-4 w-4 md:h-5 md:w-5 flex-none fill-current self-start'
    const crossIconStyle =
      'h-4 w-4 md:h-5 md:w-5 self-start' +
      (type === 'success'
        ? ' text-green-400'
        : type === 'warning'
        ? ' text-orange-400'
        : type === 'error'
        ? ' text-red-400'
        : ' text-blue-400')

    return (
      <div className={boxStyle} ref={ref}>
        {showIcon && (
          <div className='py-1 md:py-0.5'>
            {type === 'success' ? (
              <CheckSoidIcon className={signIconStyle + ' text-green-400'} />
            ) : type === 'warning' ? (
              <WarningSoidIcon className={signIconStyle + ' text-orange-400'} />
            ) : type === 'error' ? (
              <WarningSoidIcon className={signIconStyle + ' text-red-400'} />
            ) : (
              <InfoSolidIcon className={signIconStyle + ' text-blue-400'} />
            )}
          </div>
        )}

        {typeof text === 'string' ? <p className={textStyle}>{text}</p> : text}

        {onClose && (
          <button onClick={onClose} className='py-1 md:py-0.5'>
            <CrossIcon className={crossIconStyle} />
          </button>
        )}
      </div>
    )
  }
)

MessageBox.displayName = 'MessageBox'
export default MessageBox
