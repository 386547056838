import { FC, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { BsListCheck } from 'react-icons/bs'
import { MdOutlinePayment, MdShoppingCartCheckout } from 'react-icons/md'
import { TbTruckDelivery } from 'react-icons/tb'
import { TfiLocationPin } from 'react-icons/tfi'

import { getCheckoutStep } from 'utils/checkoutUtils'

import type { StepType } from 'components/common/StepLine'
import StepLine from 'components/common/StepLine'

type StepName = 'cart' | 'delivery' | 'address' | 'summary' | 'payment'

const VWCheckoutSteps: FC = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { vcartId: vcartIdPathParam, localeCode: localeCodePathParam } = useParams()
  const { t: translated } = useTranslation()

  const steps: StepType<StepName>[] = useMemo(
    () => [
      {
        id: 'cart',
        label: translated('Cart'),
        icon: <MdShoppingCartCheckout size={20} />,
        click: () =>
          navigate({
            pathname: `/${localeCodePathParam}/vcart/${vcartIdPathParam}`,
            search: location.search
          })
      },
      {
        id: 'delivery',
        label: translated('Delivery'),
        icon: <TbTruckDelivery size={20} />,
        click: () =>
          navigate({
            pathname: `/${localeCodePathParam}/vcart/${vcartIdPathParam}/delivery`,
            search: location.search
          })
      },
      {
        id: 'address',
        label: translated('Address'),
        icon: <TfiLocationPin size={20} />,
        click: () =>
          navigate({
            pathname: `/${localeCodePathParam}/vcart/${vcartIdPathParam}/address`,
            search: location.search
          })
      },
      {
        id: 'summary',
        label: translated('Summary'),
        icon: <BsListCheck size={20} />,
        click: () =>
          navigate({
            pathname: `/${localeCodePathParam}/vcart/${vcartIdPathParam}/summary`,
            search: location.search
          })
      },
      {
        id: 'payment',
        label: translated('Payment'),
        icon: <MdOutlinePayment size={20} />,
        click: () => null
      }
    ],
    [localeCodePathParam, navigate, translated, vcartIdPathParam]
  )

  return (
    <nav className='relative pt-3'>
      <StepLine
        steps={steps}
        activeStep={getCheckoutStep(pathname)}
        customClasses='container max-w-3xl mx-auto mt-1 sm:mt-0'
      />
    </nav>
  )
}

export default VWCheckoutSteps
