import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'

import type { CheckoutConfirmationCta, CheckoutConfirmationStatus } from 'typings/checkoutApi'
import config from 'config/index'
import { useAppContext } from 'context/app'
import Button from 'components/common/buttons/Button'

const ConfirmationPageActions = () => {
  const location = useLocation()
  const { localeCode: localeCodePathParam } = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { t: translated } = useTranslation()
  const { shopId } = useAppContext()

  const checkoutConfirmationStatus = searchParams.get('status') as CheckoutConfirmationStatus
  const ctaSearchParam = searchParams.get('cta') as CheckoutConfirmationCta | null
  const urlSearchParam = searchParams.get('url')

  return checkoutConfirmationStatus !== 'success' ? (
    <div className='flex items-center justify-center gap-x-2'>
      <Link
        className='inline-block mt-4 text-primary hover:text-primary-darker text-lg'
        to={{ pathname: '..', search: location.search }}
      >
        <span>{translated('Try again')}</span>
      </Link>
    </div>
  ) : (
    <div className='flex flex-col md:flex-row items-center justify-center gap-x-2'>
      {ctaSearchParam === 'vw' ? (
        <Button
          containerClassName='mt-4'
          variant='outlined'
          onClick={() => window.location.assign(`${config.officeAppUrl}/virtual-warehouse`)}
        >
          <RiArrowLeftSLine size={24} className='inline-block' />
          {translated('To Virtual Warehouse')}
        </Button>
      ) : ctaSearchParam === 'webshop' || ctaSearchParam === 'pwd' ? (
        <Button
          containerClassName='mt-4'
          variant='primary'
          onClick={() =>
            navigate({
              pathname: `/${localeCodePathParam}/shop/${shopId}`,
              search: location.search
            })
          }
        >
          {translated('Buy more')}
          <RiArrowRightSLine size={24} className='inline-block' />
        </Button>
      ) : null}

      {ctaSearchParam === 'vw' && shopId ? (
        <Button
          containerClassName='mt-4'
          variant='primary'
          onClick={() =>
            navigate({
              pathname: `/${localeCodePathParam}/shop/${shopId}`,
              search: location.search
            })
          }
        >
          {translated('Add more products')}
        </Button>
      ) : ctaSearchParam === null && shopId ? (
        <Button
          containerClassName='mt-4'
          variant='primary'
          onClick={() =>
            navigate({
              pathname: `/${localeCodePathParam}/shop/${shopId}`,
              search: location.search
            })
          }
        >
          {translated('Buy more')}
        </Button>
      ) : ctaSearchParam === 'pwd' && urlSearchParam ? (
        <Button
          containerClassName='mt-4'
          variant='primary'
          onClick={() => window.location.assign(urlSearchParam)}
        >
          {translated('Activate your account')}
          <RiArrowRightSLine size={24} className='inline-block' />
        </Button>
      ) : null}
    </div>
  )
}

export default ConfirmationPageActions
