import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { BsFillShieldLockFill } from 'react-icons/bs'
import type { SignOutRedirect } from 'context/auth/AuthContext.types'
import config from 'config'
import useLocalStorage from 'hooks/useLocalStorage'
import ScreenCover from 'components/common/ScreenCover'
import SpinnerPM from 'components/common/SpinnerPM'

const SignOutCallbackPage = () => {
  const navigate = useNavigate()
  const [signoutRedirect, setSignoutRedirect] = useLocalStorage<SignOutRedirect>(
    config.signoutRedirectKey,
    null
  )

  useEffect(() => {
    if (signoutRedirect) {
      const navDestination = {
        pathname: signoutRedirect.pathname,
        ...(signoutRedirect.search ? { search: signoutRedirect.search } : {}),
        ...(signoutRedirect.hash ? { hash: signoutRedirect.hash } : {})
      }
      setSignoutRedirect(null)
      navigate(navDestination, { state: { source: 'singout-redirect' } })
    }
  }, [navigate, setSignoutRedirect, signoutRedirect])

  return (
    <ScreenCover>
      <SpinnerPM>
        <BsFillShieldLockFill size={25} />
      </SpinnerPM>
    </ScreenCover>
  )
}

export default SignOutCallbackPage
