const config = {
  companyName: 'PM-International',
  companyHomeUrl: 'https://www.pm-international.com/',
  companyFacebookUrl: 'https://www.facebook.com/rolfsorg/',
  companyInstagramUrl: 'https://www.instagram.com/rolfsorg/',
  companyYoutubeUrl: 'https://www.youtube.com/user/pminternationalag/',
  companyRedirectUrl: 'https://www.pmebusiness.com',
  companyPrivacyPolicyUrl: 'https://www.pmebusiness.com/en/data-security/footer/',
  identityServerApiUrl: process.env.REACT_APP_IDENTITY_SERVER_API,
  identityClientId: process.env.REACT_APP_IDENTITY_CLIENT_ID,
  shopApiUrl: `${process.env.REACT_APP_API_BASE}/shops`,
  checkoutApiUrl: `${process.env.REACT_APP_API_BASE}/shops`,
  orderApiUrl: `${process.env.REACT_APP_API_BASE}/orders`,
  registrationAppUrl: `${process.env.REACT_APP_REGISTRATION_URL}`,
  officeAppUrl: `${process.env.REACT_APP_OFFICE_URL}`,
  telemetryConsentKey: 'pm:telemetry',
  signoutRedirectKey: 'pm:auth:signoutRedirect',
  promoCodeStorageKey: 'pm:promocode',
  promoCodeQueryParamKey: 'promo',
  defaultPageNumber: '1',
  defaultProductPageSize: '20',
  pageSizeOptions: ['5', '10', '20', '50'],
  cartBufferSynchronizationDebounceSpan: 1000 // number of milliseconds
}

export default config
