import { FC } from 'react'

import { IDefaultIconProps } from './iconTypes'

const BoxIcon: FC<IDefaultIconProps> = ({ className, title }) => (
  <svg
    viewBox='0 0 24 24'
    stroke='currentColor'
    className={className}
    xmlns='http://www.w3.org/2000/svg'
  >
    <title>{title}</title>
    <g strokeLinecap='square' strokeLinejoin='miter' strokeMiterlimit='10' strokeWidth='2'>
      <rect height='5' width='22' fill='none' x='1' y='2' />
      <rect height='4' width='6' fill='none' x='9' y='11' />
      <polyline fill='none' points='21,11 21,22 3,22 3,11' />
    </g>
  </svg>
)

export default BoxIcon
