import { FC, useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'

import { RiArrowRightSLine } from 'react-icons/ri'

import config from 'config/index'
import { appUrl } from 'utils/urlUtils'
import { formatPrice } from 'utils/priceUtils'
import { useAuthContext } from 'context/auth'
import { useVWCartContext } from 'context/checkout'

import { buttonVariants, listVariants } from './VWCartContentPage.motion'

import PersistentPortal from 'components/common/PersistentPortal'
import ScreenCover from 'components/common/ScreenCover'
import CloudDownloadIcon from 'components/common/icons/CloudDownloadIcon'
import SpinnerPM from 'components/common/SpinnerPM'
import MessageBox from 'components/common/MessageBox'
import Button from 'components/common/buttons/Button'
import AnimatedNumber from 'components/effects/AnimatedNumber'
import VWCheckoutSteps from 'components/layout/VWCheckoutSteps'
import VWCartItem from 'components/checkout/VWCartItem'
import { measurementUnitMap } from 'utils/unitUtils'

const VWCartContentPage: FC = () => {
  const navigate = useNavigate()
  const { localeCode: localeCodePathParam, vcartId: vcartIdPathParam } = useParams()
  const { userData } = useAuthContext()
  const { t: translated } = useTranslation()
  const {
    basketQueryError,
    idOfBasketTargetCustomer,
    customerName,
    sharerName,
    cartItems,
    cartSubtotal,
    isBasketLoading,
    isBasketQueryError,
    cartCurrency,
    isPaid,
    isCanceled,
    affiliateDiscount
  } = useVWCartContext()

  const handleUserConfirmation = useCallback(() => {
    if (!userData) {
      window.location.assign(
        config.registrationAppUrl +
          `?lang=${localeCodePathParam?.slice(
            0,
            2
          )}&prospectId=${idOfBasketTargetCustomer}&redirectUrl=` +
          `${appUrl}/${localeCodePathParam}/vcart/${vcartIdPathParam}/delivery`
      )
    } else {
      navigate('delivery')
    }
  }, [idOfBasketTargetCustomer, localeCodePathParam, navigate, userData, vcartIdPathParam])

  let greetingMessage = customerName
    ? translated('Hi, {{personName}}!', { personName: customerName })
    : ''
  greetingMessage += sharerName
    ? (customerName ? '\n' : '') +
      translated(
        '{{personName}} has created and shared this shopping basket with you and is recommending these items specially for you.',
        {
          personName: sharerName
        }
      )
    : translated('Here is a shopping basket that was created specially for you.')

  if (isBasketLoading)
    return (
      <ScreenCover>
        <SpinnerPM>
          <CloudDownloadIcon />
        </SpinnerPM>
      </ScreenCover>
    )

  if (isBasketQueryError)
    return basketQueryError?.status === 404 ? (
      <div className='w-full grid place-content-center'>
        <MessageBox type='error' text={basketQueryError.title} showIcon={true} />
      </div>
    ) : basketQueryError?.status === 403 ? (
      <div className='w-full grid place-content-center'>
        <MessageBox
          type='error'
          text={translated('You are not authorized to access this shopping basket.')}
          showIcon={true}
        />
      </div>
    ) : (
      <div className='w-full grid place-content-center'>
        <MessageBox type='error' text={JSON.stringify(basketQueryError, null, 2)} showIcon={true} />
      </div>
    )

  if (cartItems?.length <= 0)
    return (
      <div className='w-full grid place-content-center'>
        <MessageBox
          type='error'
          text={String(translated('Your shopping cart is empty'))}
          showIcon={true}
        />
      </div>
    )

  return (
    <>
      {isPaid || isCanceled ? null : (
        <PersistentPortal containerElementSelector='#header-additional-content'>
          <VWCheckoutSteps />
        </PersistentPortal>
      )}

      {
        <div
          className={
            'w-full max-w-3xl mx-auto px-2' +
            ' flex-1 overflow-hidden' +
            ' bg-white' +
            ' border border-secondary'
          }
        >
          {isPaid ? (
            <MessageBox
              type='info'
              text={translated('This cart has already been paid.')}
              className='my-4'
              showIcon={true}
            />
          ) : isCanceled ? (
            <MessageBox
              type='info'
              text={translated('This cart has already been canceled.')}
              className='my-4'
              showIcon={true}
            />
          ) : greetingMessage && customerName !== sharerName ? (
            <MessageBox
              type='success'
              text={greetingMessage}
              className='my-4 whitespace-pre-line'
              showIcon={false}
            />
          ) : null}

          <motion.ul
            className={
              'w-full max-w-lg md:max-w-xl mx-auto' +
              ' overflow-x-hidden overflow-y-scroll' +
              ' divide-y divide-secondary-darker'
            }
            variants={listVariants}
          >
            <AnimatePresence>
              {cartItems.map((cartItem, index) => (
                <motion.li
                  key={cartItem.articleNumber}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  exit={{
                    opacity: 0,
                    x: '50%',
                    transition: { duration: 0.1, delay: 0 }
                  }}
                >
                  <VWCartItem
                    {...cartItem}
                    amountInUnits={cartItem?.amountInUnits}
                    unitSymbol={measurementUnitMap.get(cartItem?.unit ?? '')}
                  />
                </motion.li>
              ))}
            </AnimatePresence>
          </motion.ul>

          <PersistentPortal containerElementSelector='#sticky-footer'>
            <motion.div
              className={
                'w-full max-w-3xl mx-auto overflow-hidden' +
                ' py-2 sm:py-3 px-3' +
                ' space-y-2 sm:space-y-3' +
                ' bg-secondary'
              }
              variants={buttonVariants}
            >
              {/* --------------- totals: start --------------- */}
              <div>
                <div className='flex justify-between items-center'>
                  <span>{translated('Total')}</span>
                  <AnimatedNumber
                    value={formatPrice(cartSubtotal, cartCurrency, localeCodePathParam ?? '')}
                    className='font-bold'
                  />
                </div>
                {!!affiliateDiscount && (
                  <p className='mt-0 text-right text-sm text-gray-500'>
                    {translated(
                      'Including a one-time discount of {{discountAmount}} on eligible products',
                      {
                        discountAmount: `${affiliateDiscount}%`
                      }
                    )}
                  </p>
                )}
              </div>
              {/* --------------- totals: end --------------- */}

              {/* --------------- button: start --------------- */}
              {isPaid || isCanceled ? null : (
                <div className='flex justify-end items-center gap-4'>
                  <Button
                    contentClassName='flex items-center'
                    onClick={handleUserConfirmation}
                    variant='primary'
                    disabled={isPaid || isCanceled}
                  >
                    <span>{translated('Continue')}</span>
                    <RiArrowRightSLine size={24} className='-mr-2' />
                  </Button>
                </div>
              )}
              {/* --------------- button: end --------------- */}
            </motion.div>
          </PersistentPortal>
        </div>
      }
    </>
  )
}

export default VWCartContentPage
