/**
 * Verifies if input is at least one non-white-space character long
 * @param input string-type input
 * @returns boolean verification
 */
export const isAtLeastOneCharLong = (input: string) => /\S+/.test(input)

/**
 * Verifies if the input is an email address
 * @param input string-type input
 * @returns boolean verification
 * based on: https://emailregex.com/
 */
export const isEmailAddress = (input: string) =>
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    input
  )

export const isTwoLetterString = (input: string) => /^[a-zA-Z]{2}$/.test(input)
