import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { BiMinus, BiPlus } from 'react-icons/bi'
import { RiProhibitedLine } from 'react-icons/ri'
import { ImSpinner2 } from 'react-icons/im'

import TrashIcon from 'components/common/icons/TrashIcon'
import CartAmountInput from './CartAmountInput'

type CACProps = {
  id: string
  amount: string
  maxAmount?: number
  set: (value: string) => void
  increment: () => void
  decrement: () => void
  isBusy?: boolean
}

const CartAmountControls: FC<CACProps> = ({
  id,
  amount,
  maxAmount = Infinity,
  increment,
  decrement,
  set,
  isBusy
}) => {
  const { t: translated } = useTranslation()

  const isMaxAmountReached = parseInt(amount) >= maxAmount

  return (
    <div
      className={'overflow-hidden relative h-9' + ' rounded-full border border-secondary-darker'}
    >
      <div
        className={
          'z-10 opacity-100 h-9 -mt-px absolute left-0 right-0' +
          ' grid place-content-center' +
          ' text-secondary-darker hover:cursor-not-allowed' +
          ' transition-all duration-300' +
          (isBusy ? ' top-0' : ' top-9')
        }
      >
        <ImSpinner2 size={24} className='animate-spin' />
      </div>

      <div
        className={
          'z-0 relative flex grow-0' +
          ' text-sm md:text-base text-main' +
          ' transition-all duration-300' +
          (isBusy ? ' -top-9' : ' top-0')
        }
      >
        <button
          onClick={decrement}
          disabled={isBusy}
          className={
            'z-0 rounded-full h-9 w-9' +
            ' flex justify-center items-center' +
            ' leading-none' +
            ' hover:bg-secondary hover:text-primary hover:z-20'
          }
        >
          {parseInt(amount) <= 1 ? <TrashIcon className='h-5 w-5' /> : <BiMinus size={25} />}
        </button>

        <CartAmountInput
          name={id}
          label={translated('Amount')}
          finalValue={String(amount)}
          maxValue={maxAmount}
          className={
            'z-10 h-9 w-9 -mt-px' +
            ' text-center font-light' +
            ' hover:bg-secondary hover:text-primary'
          }
          submitValue={set}
          disabled={isBusy}
        />

        <button
          onClick={increment}
          disabled={isBusy || isMaxAmountReached}
          className={
            'z-0 rounded-full h-9 w-9' +
            ' flex justify-center items-center' +
            ' leading-none' +
            (isMaxAmountReached
              ? ' hover:cursor-not-allowed'
              : ' hover:bg-secondary hover:text-primary hover:z-20')
          }
        >
          {isMaxAmountReached ? (
            <RiProhibitedLine size={23} />
          ) : (
            <BiPlus size={25} style={{ marginTop: '-2px' }} />
          )}
        </button>
      </div>
    </div>
  )
}

export default CartAmountControls
