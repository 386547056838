import { FC, ReactNode } from 'react'
import { motion } from 'framer-motion'
import useMeasure from 'react-use-measure'

type CollapseProps = {
  children: ReactNode
  isExpanded: boolean
  duration?: number
}

const Collapse: FC<CollapseProps> = ({ children, isExpanded, duration = 0.3 }) => {
  const [ref, { height }] = useMeasure()

  return (
    <motion.div animate={{ height }} className='overflow-hidden'>
      <motion.div
        ref={ref}
        animate={{ opacity: isExpanded ? 1 : 0 }}
        transition={{
          duration: duration,
          tension: 190,
          friction: 70,
          mass: 0.4
        }}
        className='inner-wrapper'
      >
        {isExpanded ? children : null}
      </motion.div>
    </motion.div>
  )
}

export default Collapse
