import { FC, useState } from 'react'

import Collapse from 'components/animations/Collapse'
import MessageBox from './MessageBox'

type MessageListProps = {
  messages: {
    id: string | number
    text: string
    visible: boolean
  }[]
}

const MessageList: FC<MessageListProps> = ({ messages }) => {
  const [messageBoxes, setMessageBoxes] = useState(messages)

  return (
    <>
      {messageBoxes?.map(({ id, text, visible }) => (
        <Collapse isExpanded={visible} key={id}>
          <MessageBox
            type='info'
            text={text}
            className={'text-xs md:text-sm'}
            showIcon={true}
            onClose={() =>
              setMessageBoxes(prevMessageBoxes =>
                prevMessageBoxes.map(messageBox =>
                  messageBox.id === id
                    ? { ...messageBox, visible: !messageBox.visible }
                    : messageBox
                )
              )
            }
          />
        </Collapse>
      ))}
    </>
  )
}

export default MessageList
