export const containerVariants = {
  hidden: {
    opacity: 0,
    transition: { when: 'afterChildren', duration: 0.3 }
  },
  visible: {
    opacity: 1,
    transition: { when: 'beforeChildren', duration: 0.3 }
  }
}

export const logoVariants = {
  hidden: { opacity: 0, scale: 0.6 },
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } }
}

export const menuVariants = {
  hidden: { translateX: '200%', rotate: '360deg', opacity: 0 },
  visible: {
    translateX: '0%',
    rotate: '0deg',
    opacity: 1,
    transition: { delay: 1.3, duration: 0.8 }
  }
}

export const secondHeaderButtonVariants = {
  hidden: { opacity: 0, scale: 0.5 },
  visible: { opacity: 1, scale: 1, transition: { delay: 1.6, duration: 0.6 } }
}

export const additionalContentVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { delay: 1.2, duration: 0.6 }
  }
}
