import { FC } from 'react'

import { IDefaultIconProps } from './iconTypes'

const LanguageIcon: FC<IDefaultIconProps> = ({ className }) => (
  <svg xmlns='http://www.w3.org/2000/svg' className={className} viewBox='0 0 512 512'>
    <title>Language</title>
    <path
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='32'
      d='M48 112h288M192 64v48M272 448l96-224 96 224M301.5 384h133M281.3 112S257 206 199 277 80 384 80 384'
    />
    <path
      d='M256 336s-35-27-72-75-56-85-56-85'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='32'
    />
  </svg>
)

export default LanguageIcon
