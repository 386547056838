import { FC } from 'react'
import {
  ToastContainer as ReactToastifyContainer,
  toast,
  ToastContent,
  ToastOptions
} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import Icon, { TIconType } from 'components/common/icons/Icon'

interface IToastParameters {
  readonly bgColor: string
  readonly textColor: string
  readonly icon: TIconType
}

type ToastType = 'success' | 'info' | 'warning' | 'error'

type ToastConfigurations = { [k in ToastType]: IToastParameters }

const toastConfig: ToastConfigurations = {
  success: {
    bgColor: 'bg-success',
    textColor: 'text-success',
    icon: 'success'
  },
  info: {
    bgColor: 'bg-info',
    textColor: 'text-info',
    icon: 'information'
  },
  warning: {
    bgColor: 'bg-warn',
    textColor: 'text-warn',
    icon: 'warning'
  },
  error: {
    bgColor: 'bg-error',
    textColor: 'text-error',
    icon: 'warning'
  }
}

type ShowToastProps = {
  readonly type: ToastType
  readonly title: string
  readonly message?: string
}

export const showToast = ({ type = 'info', title, message }: ShowToastProps) => {
  const toastOptions: ToastOptions = {
    type,
    icon: (
      <Icon name={toastConfig[type].icon} className={'w-4 h-5 ' + toastConfig[type].textColor} />
    )
  }

  const toastContent: ToastContent = (
    <>
      <h4 className={'font-bold ' + toastConfig[type].textColor}>{title}</h4>
      {message && <p className={'whitespace-pre-wrap ' + toastConfig[type].textColor}>{message}</p>}
    </>
  )

  toast(toastContent, toastOptions)
}

const ToastContainer: FC = () => (
  <ReactToastifyContainer
    closeButton={true}
    closeOnClick={true}
    autoClose={5000}
    draggable={false}
    role='alert'
    position='bottom-right'
    className='relative space-y-2 mx-2 xs:mx-0'
    bodyClassName='text-sm font-app items-start'
  />
)

export default ToastContainer
