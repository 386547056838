import { ButtonHTMLAttributes, FC } from 'react'

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  readonly containerClassName?: string
  readonly contentClassName?: string
  readonly variant?: 'primary' | 'secondary' | 'outlined' | 'text'
}

const Button: FC<IButtonProps> = ({
  children,
  containerClassName,
  contentClassName,
  variant = 'primary',
  ...otherProps
}) => (
  <button
    className={
      'relative py-2 px-6 rounded-full border-2 border-solid ' +
      ' transform transition duration-300' +
      ' group overflow-hidden' +
      ' enabled:hover:animate-pulsate' +
      ' enabled:focus:outline-none enabled:focus-visible:animate-pulsate' +
      ' enabled:active:animate-pulsate' +
      ' disabled:opacity-20' +
      ' disabled:hover:shadow-none disabled:hover:scale-1' +
      ' disabled:focus:scale-1 disabled:focus-visible:outline-none' +
      ' disabled:active:scale-1' +
      ' disabled:cursor-not-allowed' +
      (variant === 'primary'
        ? ' border-primary text-white bg-primary hover:bg-primary-darker hover:shadow-primary/40 disabled:bg-primary shadow-md disabled:active:shadow-md'
        : variant === 'secondary'
        ? ' border-primary/10 text-primary! bg-primary/20 hover:bg-primary/40 hover:shadow-primary/40 disabled:bg-primary/20 shadow-md disabled:active:shadow-md'
        : variant === 'outlined'
        ? ' border-primary text-primary bg-transparent hover:bg-primary/20 hover:shadow-primary/40 disabled:bg-transparent disabled:opacity-20 shadow-md disabled:active:shadow-md'
        : variant === 'text'
        ? ' border-transparent text-primary bg-transparent hover:bg-primary/20 disabled:bg-transparent disabled:opacity-20 shadow-none'
        : '') +
      ` ${containerClassName ?? ''}`
    }
    {...otherProps}
  >
    <span
      className={
        'inline-block ' +
        'group-hover:animate-drop-down-drop-in group-focus:animate-drop-down-drop-in group-focus-within:animate-drop-down-drop-in ' +
        'group-disabled:animate-none' +
        ` ${contentClassName ?? ''}`
      }
    >
      {children}
    </span>
  </button>
)

export default Button
