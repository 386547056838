import { useCallback } from 'react'
import { motion } from 'framer-motion'
import { TiWarningOutline } from 'react-icons/ti'

interface ITIProps<T extends string> extends React.InputHTMLAttributes<HTMLInputElement> {
  id: T
  value: string
  changeHandler: (value: string) => void
  label?: string
  placeholder?: string
  errors?: string[]
  customWrapperClasses?: string
  customLabelClasses?: string
  customInputClasses?: string
  customErrorClasses?: string
  required?: boolean
  readOnly?: boolean
  disabled?: boolean
}

const TextInput = <T extends string>({
  value,
  errors,
  changeHandler,
  label,
  placeholder,
  id,
  customWrapperClasses,
  customLabelClasses,
  customInputClasses,
  customErrorClasses,
  required,
  readOnly,
  disabled,
  ...otherInputProps
}: ITIProps<T>) => {
  const handleChange = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      if (readOnly) return
      changeHandler(event.currentTarget.value)
    },
    [changeHandler, readOnly]
  )

  return (
    <div className={'flex flex-col gap-1 ' + customWrapperClasses}>
      {label && (
        <label
          htmlFor={id}
          className={'block text-xs font-light tracking-wider text-gray-500 ' + customLabelClasses}
        >
          {label}
          {required && !readOnly && !disabled && (
            <span className='ml-1 text-rose-500 font-bold'>*</span>
          )}
        </label>
      )}

      <input
        {...otherInputProps}
        type='text'
        name={id}
        id={id}
        className={
          'block w-full py-3.5 px-4' +
          ' rounded-md border' +
          ' sm:text-sm' +
          ' bg-secondary-lighter focus:bg-transparent' +
          ' transition duration-200' +
          (errors?.length
            ? ' border-rose-500 focus:ring-red-200 focus-visible:ring-red-200'
            : required && !readOnly && !disabled && !value
            ? ' border-secondary-darker focus:ring-secondary focus-visible:ring-secondary-lighter'
            : ' border-secondary focus:ring-secondary focus-visible:ring-secondary-lighter') +
          (readOnly || disabled ? ' text-gray-400 select-none' : ' text-gray-700') +
          ` ${customInputClasses}`
        }
        placeholder={placeholder}
        value={value}
        disabled={disabled || readOnly}
        onChange={handleChange}
        onBlur={handleChange}
      />

      {!!errors?.length && (
        <ul className={'pt-1 text-sm text-rose-500 font-light ' + customErrorClasses}>
          {(errors ?? []).map((error, index) => (
            <motion.li
              key={index}
              className='flex items-end gap-1 text-xs'
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.2 }}
            >
              <TiWarningOutline size={16} />
              <span className='tracking-wider'>{error}</span>
            </motion.li>
          ))}
        </ul>
      )}
    </div>
  )
}

export default TextInput
