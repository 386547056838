import { FC } from 'react'
import RadioInputIcon from 'components/common/icons/RadioInputIcon'

type DOCProps = {
  id: string
  title: string | JSX.Element
  details: string | JSX.Element
  isSelected: boolean
  handleSelection: () => void
  customClasses?: string
  disabled?: boolean
  customFooter?: JSX.Element
  additionalInfo?: JSX.Element
}

const DestinationOptionCard: FC<DOCProps> = ({
  id,
  title,
  details,
  isSelected,
  handleSelection,
  customClasses,
  disabled,
  customFooter,
  additionalInfo
}) => {
  return (
    <div className={'w-96 max-w-full relative'}>
      <input
        type='radio'
        id={`destination-option-${id}`}
        value={id}
        disabled={disabled}
        checked={isSelected}
        onChange={handleSelection}
        className='sr-only peer'
      />
      <label
        htmlFor={`destination-option-${id}`}
        className={
          'relative block w-full mb-2 py-4 px-4' +
          ' transition duration-200' +
          ' rounded-md border-2' +
          (isSelected ? ' border-secondary-darker' : ' border-secondary') +
          (disabled
            ? ' hover:cursor-not-allowed select-none'
            : ' hover:cursor-pointer hover:bg-secondary-lighter') +
          ' peer-focus-visible:ring' +
          ` ${customClasses}`
        }
      >
        <div className={'grid grid-cols-[min-content_1fr] items-center gap-4'}>
          {<RadioInputIcon size={20} selected={isSelected} />}

          <div className=''>
            {typeof title === 'string' ? (
              <p className='mb-2 text-base font-normal'>{title}</p>
            ) : (
              title
            )}

            {typeof details === 'string' ? (
              <p className='text-sm text-justify font-light tracking-wide text-gray-500'>
                {details}
              </p>
            ) : (
              details
            )}
          </div>
        </div>

        {customFooter}
      </label>

      {additionalInfo}
    </div>
  )
}

export default DestinationOptionCard
