import { FC, useCallback, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { AnimatePresence } from 'framer-motion'
import { MdOutlineSearch, MdOutlineSearchOff } from 'react-icons/md'

import { showToast } from 'components/layout/ToastNotification'
import PersistentPortal from 'components/common/PersistentPortal'

type PSProps = {
  setPageNumber: (pageNum: number) => void
}

const minAcceptableProductQueryLength = 3

const ProductSearch: FC<PSProps> = ({ setPageNumber }) => {
  const { t: translated } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const searchParamValue = searchParams.get('search')
  const searchQuery = searchParamValue ? decodeURI(searchParamValue) : ''

  const searchInputRef = useRef<HTMLInputElement>(null)

  const [isSearchBoxVisible, setIsSearchBoxVisible] = useState(searchQuery?.length > 0)
  const [searchInput, setSearchInput] = useState(() => searchQuery)

  const handleSearchButtonClick = useCallback(() => {
    setIsSearchBoxVisible(prevState => {
      if (prevState && searchParams.get('search')) {
        searchParams.delete('search')
        setSearchParams(searchParams)
        setSearchInput('')
      }
      if (!prevState) searchInputRef.current?.focus()
      return !prevState
    })
  }, [searchParams, setSearchParams])

  const handleSearchSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      const trimmedSearchInput = searchInput.trim()
      setSearchInput(trimmedSearchInput)
      if (trimmedSearchInput.length < minAcceptableProductQueryLength) {
        return showToast({
          type: 'warning',
          title: translated('Invalid search') ?? ''
        })
      }

      setPageNumber(1)
      setSearchParams({ search: encodeURI(trimmedSearchInput) })
    },
    [searchInput, setPageNumber, setSearchParams, translated]
  )

  return (
    <>
      <PersistentPortal containerElementSelector='#header-additional-buttons'>
        <button
          onClick={handleSearchButtonClick}
          className={
            'z-20 relative h-10 w-10' +
            ' flex justify-center items-center' +
            ' rounded-full border-solid' +
            ' hover:bg-primary hover:text-white'
          }
        >
          <AnimatePresence mode='wait'>
            {isSearchBoxVisible ? (
              <MdOutlineSearchOff size={24} className='' />
            ) : (
              <MdOutlineSearch size={24} className='' />
            )}
          </AnimatePresence>
        </button>
      </PersistentPortal>

      <PersistentPortal containerElementSelector='#header-additional-content'>
        <div
          className={
            'absolute top-0 h-10 w-full px-2' +
            ' transition-all duration-300' +
            (isSearchBoxVisible ? ' left-0' : ' -left-full')
          }
        >
          <form
            className={
              'h-full w-full max-w-4xl mx-auto px-3' +
              ' flex justify-between items-center gap-2' +
              ' rounded-full border-2 border-secondary-darker bg-white'
            }
            onSubmit={handleSearchSubmit}
          >
            <input
              type='text'
              className={
                'w-full' +
                ' bg-transparent' +
                ' placeholder:text-gray-400' +
                ' focus:outline-none focus:ring-transparent focus-visible:ring'
              }
              placeholder={translated('Search by product name')}
              ref={searchInputRef}
              value={searchInput}
              onChange={event => setSearchInput(event.target.value)}
              tabIndex={isSearchBoxVisible ? 0 : -1}
              name='product-search-input'
              id='product-search-input-field'
            />

            <button type='submit' className='custom' tabIndex={isSearchBoxVisible ? 0 : -1}>
              <MdOutlineSearch size={24} className='animate-pulse' />
            </button>
          </form>
        </div>
      </PersistentPortal>
    </>
  )
}

export default ProductSearch
