import { useReducer } from 'react'
import { useTranslation } from 'react-i18next'

const CookieIntroduction = () => {
  const { t: translated } = useTranslation()
  const [isExpanded, toggleExpanded] = useReducer(state => !state, false)

  return (
    <div className='flex flex-col gap-2 transition-all duration-300'>
      <div className={isExpanded ? 'line-clamp-none' : 'line-clamp-3'}>
        <div className='text-sm'>
          <span>
            {translated(
              'This website uses cookies to improve your experience while navigating through the website.'
            )}
          </span>
          <span className='ml-1'>
            {translated(
              'Cookies categorized as necessary are stored in your browser; these cookies enable the basic functionalities of our website.'
            )}
          </span>
          <span className='ml-1'>
            {translated(
              'We also use third-party cookies that help us analyze and understand how you use this website.'
            )}
          </span>
          <span className='ml-1'>
            {translated('These cookies will be stored in your browser only with your consent.')}
          </span>
          <span className='ml-1'>
            {translated('You also have the option to opt out of these cookies.')}
          </span>
          <span className='ml-1'>
            {translated(
              'But opting out of some of these cookies may affect your browsing experience.'
            )}
          </span>
        </div>
      </div>

      <button className='text-sm underline' onClick={toggleExpanded}>
        {isExpanded ? translated('Show less') : translated('Show more')}
      </button>
    </div>
  )
}

export default CookieIntroduction
