import { useReducer } from 'react'

type ConditionConsentMapType = Map<
  string,
  {
    required: boolean
    accepted: boolean
  }
>

type ConditionType = {
  name: string
  required: boolean
}

const conditionConsentReducer = (state: ConditionConsentMapType, action: string) => {
  const selectedCondition = state.get(action)

  if (selectedCondition === undefined) return state

  const newState = new Map(state)
  newState.set(action, { ...selectedCondition, accepted: !selectedCondition.accepted })
  return newState
}

const useConditionListConsent = (
  conditionList: ConditionType[]
): {
  conditionConsentState: ConditionConsentMapType
  toggleCondition: (conditionId: string) => void
  areSomeConditionsUnfulfilled: boolean
} => {
  const [conditionConsentState, conditionConsentDispatch] = useReducer(
    conditionConsentReducer,
    new Map(
      conditionList.map(condition => [
        condition.name,
        { required: condition.required, accepted: false }
      ])
    )
  )

  const toggleCondition = (conditionId: string) => conditionConsentDispatch(conditionId)

  const areSomeConditionsUnfulfilled = Array.from(conditionConsentState.values()).some(
    condition => condition.required && !condition.accepted
  )

  return { conditionConsentState, toggleCondition, areSomeConditionsUnfulfilled }
}

export default useConditionListConsent
