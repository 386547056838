import { FC } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'

import { useCheckoutContext } from 'context/checkout'

import { containerVariants, contentVariants } from './MidnightMadnessPage.motion'
import PersistentPortal from 'components/common/PersistentPortal'
import Button from 'components/common/buttons/Button'
import Collapse from 'components/animations/Collapse'
import MidnightMadnessOptionCard from 'components/midnightmadness/MidnightMadnessOptionCard'

const MidnightMadnessPage: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t: translated } = useTranslation()
  const {
    midnightMadnessPointAllocationMonth,
    setMidnightMadnessPointAllocationMonth,
    isPaid,
    isCanceled
  } = useCheckoutContext()

  return (
    <motion.div
      className={
        'w-full max-w-3xl mx-auto sm:p-4' +
        ' flex-1 overflow-hidden' +
        ' flex flex-col' +
        ' bg-white' +
        ' border border-secondary rounded-t-md' +
        ' shadow-md'
      }
      variants={containerVariants}
    >
      {/* --------------- main content: start --------------- */}
      <motion.div
        className={'flex-1 container p-4 md:p-8' + ' space-y-6 md:space-y-0'}
        variants={contentVariants}
      >
        {/* --------------- midnight madness point-allocation month selection: start --------------- */}
        <div
          className={
            'w-full mt-2 md:mt-4' +
            ' flex flex-col justify-center items-center' +
            (midnightMadnessPointAllocationMonth ? '' : ' gap-y-2 md:gap-y-4')
          }
        >
          <Collapse isExpanded={!midnightMadnessPointAllocationMonth}>
            <p className='text-sm text-rose-500'>
              {translated(
                'Choose the month to which you want to allocate the points gained when you pay for this order.'
              )}
            </p>
          </Collapse>

          <form
            className={
              'w-full mt-2 md:mt-4 flex flex-col justify-center items-center gap-2 md:gap-4'
            }
          >
            <MidnightMadnessOptionCard
              id={'previous-month'}
              title={translated('Previous month')}
              details={translated(
                'All the points gained when completing this order will be allocated to the previous month.'
              )}
              isSelected={midnightMadnessPointAllocationMonth === 'previousMonth'}
              handleSelection={() => setMidnightMadnessPointAllocationMonth('previousMonth')}
            />

            <MidnightMadnessOptionCard
              id={'current-month'}
              title={translated('Current month')}
              details={translated(
                'All the points gained when completing this order will be allocated to the current month.'
              )}
              isSelected={midnightMadnessPointAllocationMonth === 'currentMonth'}
              handleSelection={() => setMidnightMadnessPointAllocationMonth('currentMonth')}
            />
          </form>
        </div>
        {/* --------------- midnight madness point-allocation month selection: end --------------- */}
      </motion.div>
      {/* --------------- main content: end --------------- */}

      {/* --------------- footer: start --------------- */}
      <PersistentPortal containerElementSelector='#footer-buttons'>
        {/* --------------- buttons: start --------------- */}
        <div className='flex flex-row justify-between items-center gap-4'>
          <button
            className='flex items-center text-primary'
            onClick={() =>
              navigate(
                { pathname: '../address', search: location.search },
                { state: { source: 'midnight-madness' } }
              )
            }
          >
            <RiArrowLeftSLine size={24} className='inline-block' />
            <span>{translated('Back')}</span>
          </button>

          <Button
            onClick={() => {
              navigate(
                { pathname: '../summary', search: location.search },
                { state: { source: 'midnight-madness' } }
              )
            }}
            disabled={!midnightMadnessPointAllocationMonth || isPaid || isCanceled}
            contentClassName='flex items-center'
            variant='primary'
          >
            <span>{translated('Confirm')}</span>
            <RiArrowRightSLine size={24} className='-mr-2' />
          </Button>
        </div>
        {/* --------------- buttons: end --------------- */}
      </PersistentPortal>
      {/* --------------- footer: end --------------- */}
    </motion.div>
  )
}

export default MidnightMadnessPage
