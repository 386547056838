import { motion } from 'framer-motion'
import { ComponentPropsWithoutRef } from 'react'

const CheckIconAnimated = (props: ComponentPropsWithoutRef<'svg'>) => (
  <svg {...props} fill='none' viewBox='0 0 24 24' stroke='currentColor' strokeWidth={3}>
    <motion.path
      initial={{ pathLength: 0 }}
      animate={{ pathLength: 1 }}
      transition={{ delay: 0.1, type: 'tween', ease: 'easeOut', duration: 0.2 }}
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M5 13l4 4L19 7'
    />
  </svg>
)

export default CheckIconAnimated
