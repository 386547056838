import { FC, ReactNode, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

type COPProps = {
  readonly containerElementSelector?: string
  readonly children: ReactNode
  readonly mustRetryMount?: boolean
  readonly replaceContent?: boolean
}

const PersistentPortal: FC<COPProps> = ({
  children,
  containerElementSelector = 'body',
  mustRetryMount = true,
  replaceContent = false
}) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const foundElementMapRef = useRef<Map<string, Element>>(new Map())

  const [containerElement, setContainerElement] = useState(
    document.querySelector(containerElementSelector)
  )

  useEffect(() => {
    if (foundElementMapRef.current.get(containerElementSelector) === containerElement) return

    const selectedContainer = document.querySelector(containerElementSelector)
    if (selectedContainer) {
      foundElementMapRef.current.set(containerElementSelector, selectedContainer)
      setContainerElement(selectedContainer)
    } else if (mustRetryMount) {
      timeoutRef.current = setTimeout(() => {
        const selectedContainer = document.querySelector(containerElementSelector)
        if (selectedContainer) {
          foundElementMapRef.current.set(containerElementSelector, selectedContainer)
          setContainerElement(selectedContainer)
        }
      }, 1000)

      return () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current)
      }
    }
  }, [containerElement, containerElementSelector, mustRetryMount])

  if (!!containerElement && replaceContent) containerElement.innerHTML = ''

  return containerElement ? createPortal(children, containerElement as HTMLElement) : null
}

export default PersistentPortal
