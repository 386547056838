import { useTranslation } from 'react-i18next'
import { RxCross2 } from 'react-icons/rx'
import useSessionStorage from 'hooks/useSessionStorage'
import PercentageTicketIcon from 'components/common/icons/PercentageTicketIcon'
import Collapse from 'components/animations/Collapse'

const PromoCodeBanner = ({ promoCode }: { promoCode: string | null | undefined }) => {
  const { t: translated } = useTranslation()
  const [isPromoCodeVisible, setIsPromoCodeVisible] = useSessionStorage<boolean>(
    'pm:promoCodeVisible',
    true
  )

  return (
    <Collapse isExpanded={isPromoCodeVisible && !!promoCode}>
      <div
        className={
          'p-2 grid grid-cols-[min-content_auto_min-content] items-center gap-1' +
          ' bg-primary text-white'
        }
      >
        <PercentageTicketIcon className='h-5 w-5 text-white' />

        <p className='text-center'>
          {translated(
            'Customer promo code for {{discountPercent}}% will be applied at checkout for eligible products',
            {
              discountPercent: '5'
            }
          )}
        </p>

        <button
          type='button'
          onClick={() => setIsPromoCodeVisible(false)}
          className={
            ' h-6 w-6 md:h-8 md:w-8 rounded-full' +
            ' bg-transparent hover:bg-primary-lightest' +
            ' grid place-content-center' +
            ' text-white hover:text-primary'
          }
        >
          <span className='sr-only'>{translated('Close')}</span>
          <RxCross2 className='text-lg md:text-xl' />
        </button>
      </div>
    </Collapse>
  )
}

export default PromoCodeBanner
