import { IBasicBasketItem } from 'typings/checkoutApi'

type AddUnitsAction = {
  type: 'addUnits'
  payload: IBasicBasketItem
}

type RemoveUnitsAction = {
  type: 'removeUnits'
  payload: IBasicBasketItem
}

type EmptyBufferAction = { type: 'emptyBuffer' }

type CartAction = AddUnitsAction | RemoveUnitsAction | EmptyBufferAction

export const bufferReducer = (
  state: Map<string, IBasicBasketItem>,
  action: CartAction
): Map<string, IBasicBasketItem> => {
  switch (action.type) {
    case 'addUnits': {
      const {
        payload: { articleNumber: payloadId, quantity: payloadAmount }
      } = action
      const newState = new Map(state)
      const currentAmount = newState.get(payloadId)?.quantity
      newState.set(payloadId, {
        articleNumber: payloadId,
        quantity: currentAmount ? currentAmount + payloadAmount : payloadAmount
      })
      return newState
    }
    case 'removeUnits': {
      const {
        payload: { articleNumber: payloadId, quantity: payloadAmount }
      } = action
      const newState = new Map(state)
      const currentAmount = newState.get(payloadId)?.quantity
      newState.set(payloadId, {
        articleNumber: payloadId,
        quantity: currentAmount ? currentAmount - payloadAmount : -payloadAmount
      })
      return newState
    }
    case 'emptyBuffer': {
      return new Map()
    }
    default: {
      return state
    }
  }
}

export const mergeRemoteBasketWithBuffer = (
  remoteItemArray: IBasicBasketItem[],
  localItemMap: Map<string, IBasicBasketItem>
): IBasicBasketItem[] => {
  const remoteItemMap = new Map(remoteItemArray.map(item => [item.articleNumber, item]))

  localItemMap.forEach(localItem => {
    const remoteItemWithSameId = remoteItemMap.get(localItem.articleNumber)

    if (remoteItemWithSameId) {
      remoteItemMap.set(remoteItemWithSameId.articleNumber, {
        ...remoteItemWithSameId,
        quantity: remoteItemWithSameId.quantity + localItem.quantity
      })
    } else {
      remoteItemMap.set(localItem.articleNumber, localItem)
    }
  })

  // keep only the items whose aggregate quantity is greater than zero
  return Array.from(remoteItemMap.values()).filter(({ quantity }) => quantity > 0)
}
