import { FC } from 'react'

type FLLProps = {
  className?: string
}

const PMLogo: FC<FLLProps> = ({ className }) => (
  <svg
    viewBox='0 0 89 83'
    xmlns='http://www.w3.org/2000/svg'
    className={'h-12 sm:h-14 flex-none ' + (className ?? '')}
  >
    <g fillRule='nonzero' fill='none'>
      <path
        d='M60.88 45.978C60.756 58.74 49.754 65.05 42.074 64.68c-10.034-.48-20.083-6.326-22.649-17.776L14 46.899c.62 17.294 14.967 26.952 28.074 27.099 19.524.218 31.123-14.9 32.672-28.021.18-1.53.254-3.015.254-4.47H60.305c.404 1.633.587 3.176.574 4.47z'
        fill='#0E4B8D'
      />
      <path
        d='M29.678 31.422c1.516-.146 2.812-.358 3.886-.634-.262.737-.541 1.7-.844 2.89l-1.162 4.435 1.445.098c.67.028 1.394-.138 2.175-.51.8-.363 1.502-1.017 2.12-1.943.626-.922.944-1.921.944-2.995 0-1.067-.387-1.907-1.145-2.526-.754-.627-1.916-.933-3.467-.933-1.779 0-3.517.445-5.22 1.345-1.702.883-3.023 2.015-3.952 3.406-.922 1.38-1.377 2.745-1.377 4.09 0 .662.123 1.243.36 1.736.24.488.667.966 1.29 1.439.51.377.764.744.764 1.102 0 .231-.085.43-.253.583a.814.814 0 0 1-.597.236c-.616 0-1.209-.424-1.785-1.275-.575-.855-.86-1.932-.86-3.235 0-2.394.906-4.573 2.73-6.538 2.378-2.588 5.573-3.885 9.59-3.885 1.529 0 2.775.201 3.75.6.981.4 1.713.949 2.19 1.643.49.697.74 1.449.74 2.259 0 1.029-.342 2.05-1.034 3.046-.682.99-1.69 1.788-3.03 2.4-1.325.61-2.803.915-4.432.915-.255 0-.642-.013-1.18-.048l-1.236 4.91a36.555 36.555 0 0 1-.706 2.46c.816.201 1.526.304 2.132.304.762 0 1.587-.148 2.464-.44l-.19.88c-.811.22-1.509.335-2.073.335-.536 0-1.334-.06-2.38-.181-.837-.104-1.514-.154-2.04-.154-1.01 0-2.052.12-3.12.363l.193-.836c1.084-.178 1.826-.437 2.225-.774.563-.473 1.02-1.424 1.377-2.858l2.365-9.456c.105-.44.157-.754.157-.934a.59.59 0 0 0-.2-.44.665.665 0 0 0-.487-.208l-.317.037.19-.71z'
        fill='#A7A9AC'
      />
      <path
        d='m41.06 60.352-1.284-17.29c-.279 2.91-.792 5.634-1.537 8.163-.748 2.515-1.599 4.47-2.544 5.847-.955 1.373-1.933 2.338-2.923 2.896-.996.552-1.974.832-2.947.832-.587 0-1.044-.12-1.363-.371-.307-.23-.462-.523-.462-.865 0-.292.11-.538.335-.753.232-.215.528-.32.886-.32.255 0 .62.059 1.103.176.436.117.799.171 1.09.171.497 0 1.021-.184 1.568-.558.825-.558 1.634-1.554 2.435-2.99.812-1.448 1.535-3.453 2.166-6.03.641-2.581 1.042-5.096 1.202-7.551-2.428 0-4.4.604-5.913 1.813-1.21.965-1.814 2.04-1.814 3.231 0 .526.289 1.158.866 1.896.308.4.467.722.467.968a.97.97 0 0 1-.273.691.834.834 0 0 1-.625.272c-.42 0-.787-.236-1.106-.707-.436-.636-.659-1.384-.659-2.252 0-1.222.406-2.384 1.216-3.503.824-1.124 1.956-1.977 3.399-2.555 1.452-.587 3.153-.886 5.096-.899 1.05 0 1.89-.1 2.518-.29l-.05 1.56.05.661.945 12.21c2.573-4.506 5.091-8.22 7.548-11.139 1.742-2.063 3.258-3.462 4.55-4.19-.738 2.037-1.53 4.718-2.369 8.053l-1.956 7.714c-.107.43-.25 1.054-.434 1.879l-.383 1.718c.812.192 1.552.29 2.223.29a8.52 8.52 0 0 0 1.752-.192l-.209.933c-.543.1-.97.148-1.267.148-.436 0-1.07-.05-1.892-.148a15.275 15.275 0 0 0-1.792-.13c-.56 0-1.187.106-1.892.325.587-1.542 1.2-3.617 1.843-6.221l1.233-4.917c.52-2.123.978-3.577 1.36-4.374-.855.978-2.173 2.768-3.957 5.37-1.43 2.101-3.034 4.758-4.805 7.973l-1.395 2.455z'
        fill='#0E4B8D'
      />
      <path
        d='M41.952 16.83c-1.227 0-8.343 1.224-14.146 4.533-5.48 3.124-13.888 11.521-13.805 24.271.003.317.02.634.033.95h5.428c-.076-.319-.16-.626-.22-.95-1.599-8.763 1.29-16.712 7.987-22.288 4.226-3.517 9.788-5.743 14.969-6.513l-.246-.002zM42.55 8l-.47.003-.005 18.886c11.481.03 16.65 7.924 18.238 14.341L75 41.212c-.01-9.204-3.501-16.789-8.023-21.938C59.954 11.282 49.84 8 42.55 8z'
        fill='#A7A9AC'
      />
    </g>
  </svg>
)

export default PMLogo
