import { FC } from 'react'
import { GoTriangleLeft, GoTriangleRight } from 'react-icons/go'
import { RxDotsHorizontal } from 'react-icons/rx'

type PaginationProps = {
  currentPage: number
  itemsPerPage: number
  totalItems: number
  setCurrentPage: (pageNumber: number) => void
  className?: string
  label?: string
}

const PaginationControls: FC<PaginationProps> = ({
  currentPage,
  itemsPerPage,
  totalItems,
  setCurrentPage,
  className,
  label
}) => {
  if (totalItems <= itemsPerPage) return null

  const numPages = Math.ceil(totalItems / itemsPerPage)
  const setOfVisiblePages = new Set<number>()
  setOfVisiblePages.add(1) // first page always visible
  setOfVisiblePages.add(numPages) // last page always visible
  setOfVisiblePages.add(currentPage) // current page always visible
  if (currentPage - 1 > 1) setOfVisiblePages.add(currentPage - 1) // previous page always visible
  if (currentPage + 1 < numPages) setOfVisiblePages.add(currentPage + 1) // next page always visible
  const numbersVisiblePages = Array.from(setOfVisiblePages).sort((a, b) => a - b)
  const paginationSegments = numbersVisiblePages.reduce(
    (output, currentPageNumber, index, arrayNumbers) =>
      currentPageNumber === 1
        ? [currentPageNumber]
        : arrayNumbers[index - 1] + 1 !== currentPageNumber
        ? [...output, 0, currentPageNumber]
        : [...output, currentPageNumber],
    [] as number[]
  )

  return (
    <nav
      className={'w-full text-center' + (className ? ` ${className}` : '')}
      aria-label={label ?? 'Page navigation'}
    >
      <ul
        className={
          'inline-grid grid-flow-col auto-cols-fr items-center' +
          ' rounded-2xl border border-primary overflow-hidden' +
          ' leading-[0]'
        }
      >
        {/* ---------- previous page button ---------- */}
        <li className={'contents'}>
          <button
            onClick={() => {
              if (currentPage <= 1) return
              setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage)
            }}
            className={
              'group relative block w-full h-full py-1 px-2' +
              (currentPage === 1 ? ' cursor-default' : ' cursor-pointer')
            }
            aria-label='previous page'
          >
            {/* slanting background */}
            <div
              className={
                'z-0 absolute inset-0 -skew-x-12 bg-transparent scale-0' +
                ' -ml-px' +
                ' transition-all duration-200' +
                (currentPage === 1 ? '' : ' group-hover:bg-primary-lightest group-hover:scale-100')
              }
            />

            {/* slanting right border */}
            <div className='z-0 absolute inset-0 -skew-x-12 border-r border-primary' />

            {/* left arrow */}
            <GoTriangleLeft
              className={
                'relative z-10 h-6 w-6 mx-auto leading-[0]' +
                ' transition-all duration-200' +
                (currentPage === 1 ? '' : ' group-hover:text-primary')
              }
              aria-hidden='true'
            />
          </button>
        </li>

        {/* ---------- buttons for all page numbers ---------- */}
        {paginationSegments.map((pageNumber, index) => {
          const isCurrentPage = currentPage === pageNumber
          const isClickable = !!pageNumber

          return (
            <li className='contents' key={index}>
              <button
                onClick={() => {
                  if (!isClickable) return
                  setCurrentPage(pageNumber)
                }}
                className={
                  'group relative block w-full h-full py-1 px-2' +
                  (isClickable && !isCurrentPage ? ' cursor-pointer' : ' cursor-default')
                }
              >
                {/* slanting background */}
                <div
                  className={
                    'z-0 absolute inset-0 -skew-x-12' +
                    ' transition-all duration-200' +
                    (isCurrentPage
                      ? ' bg-primary scale-100'
                      : !isClickable
                      ? ' bg-transparent scale-100'
                      : ' scale-0 group-hover:bg-primary-lightest group-hover:scale-100')
                  }
                />

                {/* slanting right border */}
                <div className='z-0 absolute inset-0 -skew-x-12 border-r border-primary' />

                {/* page number */}
                {pageNumber === 0 ? (
                  <RxDotsHorizontal
                    className={'relative z-10 text-gray-300'}
                    size={22}
                    aria-hidden='true'
                  />
                ) : (
                  <p
                    className={
                      'relative z-10' +
                      ' transition-all duration-200' +
                      (isCurrentPage
                        ? ' text-white'
                        : isClickable
                        ? ' group-hover:text-primary'
                        : '')
                    }
                  >
                    {pageNumber}
                  </p>
                )}
              </button>
            </li>
          )
        })}

        {/* ---------- next page button ---------- */}
        <li className={'contents'}>
          <button
            onClick={() => {
              if (currentPage === numPages) return
              setCurrentPage(currentPage < numPages ? currentPage + 1 : currentPage)
            }}
            className={
              'group relative block w-full h-full py-1 px-2' +
              (currentPage === numPages ? ' cursor-default' : ' cursor-pointer')
            }
            aria-label='next page'
          >
            {/* slanting background */}
            <div
              className={
                'z-0 absolute inset-0 -skew-x-12 bg-transparent scale-0' +
                ' -mr-px' +
                ' transition-all duration-200' +
                (currentPage === numPages
                  ? ''
                  : ' group-hover:bg-primary-lightest group-hover:scale-100')
              }
            />

            {/* right arrow */}
            <GoTriangleRight
              className={
                'relative z-10 h-6 w-6 mx-auto leading-[0]' +
                ' transition-all duration-200' +
                (currentPage === numPages ? '' : ' group-hover:text-primary')
              }
              aria-hidden='true'
            />
          </button>
        </li>
      </ul>
    </nav>
  )
}

export default PaginationControls
