import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import Collapse from 'components/animations/Collapse'

type CDProps = {
  expanded: boolean
}

const CookieDetails: FC<CDProps> = ({ expanded }) => {
  const { t: translated } = useTranslation()

  return (
    <Collapse isExpanded={expanded}>
      <div className='py-2 text-sm flex flex-col gap-2'>
        <p>
          <span>
            {translated(
              'Analytical cookies are used to understand how visitors interact with the website.'
            )}
          </span>
          <span>
            {translated(
              'These cookies help provide information on metrics, such as the number of visitors, bounce rate, traffic source, etc.'
            )}
          </span>
        </p>

        <table className='table-auto border-collapse'>
          <thead className='bg-slate-600 text-white'>
            <tr>
              <td className='p-2'>{translated('Cookies')}</td>
              <td className='p-2'>{translated('Duration')}</td>
              <td className='p-2'>{translated('Description')}</td>
            </tr>
          </thead>

          <tbody className='bg-body text-sm'>
            <tr className='border-b-[1px] border-gray-300'>
              <td className='p-2 break-all'>ai_user</td>
              <td className='p-2'>{`1 ${translated('Year')}`}</td>
              <td className='p-2'>
                {translated('Anonymous user ID. Represents the end-user of the application.')}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://docs.microsoft.com/en-us/azure/azure-monitor/app/data-model-context#anonymous-user-id'
                  className='underline text-gray-500 hover:text-gray-700'
                >
                  {translated('More information')}
                </a>
              </td>
            </tr>

            <tr className='border-b-[1px] border-gray-300'>
              <td className='p-2 break-all'>ai_session</td>
              <td className='p-2'>{`24 ${translated('Hours')}`}</td>
              <td className='p-2'>
                {translated("The instance of the user's interaction with the app.")}
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://docs.microsoft.com/en-us/azure/azure-monitor/app/data-model-context#session-id'
                  className='underline text-gray-500 hover:text-gray-700'
                >
                  {translated('More information')}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Collapse>
  )
}

export default CookieDetails
