import { FC } from 'react'

import { IDefaultIconProps } from './iconTypes'

const CheckIcon: FC<IDefaultIconProps> = ({ className }) => (
  <svg fill='currentColor' viewBox='0 0 12 12' className={className}>
    <path d='M10.293,1.293,4,7.586,1.707,5.293A1,1,0,0,0,.293,6.707l3,3a1,1,0,0,0,1.414,0l7-7a1,1,0,0,0-1.414-1.414Z'></path>
  </svg>
)

export default CheckIcon
