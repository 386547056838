import { ButtonHTMLAttributes, FC } from 'react'

interface IBasicButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
  dark?: boolean
}

const BasicButton: FC<IBasicButtonProps> = ({
  children,
  className,
  dark = true,
  ...otherProps
}) => (
  <button
    className={
      'relative transition duration-300 py-2 px-8 rounded-full shadow-md transform scale-1 ' +
      'enabled:hover:scale-[1.02] enabled:hover:shadow-lg ' +
      'enabled:focus:outline-none enabled:focus-visible:ring ' +
      'enabled:active:scale-[0.98] enabled:active:shadow-sm ' +
      'disabled:opacity-40  ' +
      'disabled:hover:shadow-none disabled:hover:scale-1 ' +
      'disabled:focus:scale-1 disabled:focus-visible:outline-none ' +
      'disabled:active:scale-1 disabled:active:shadow-md ' +
      'disabled:cursor-not-allowed ' +
      (dark
        ? ' text-white bg-primary hover:bg-primary-darker hover:shadow-primary/40 disabled:bg-primary'
        : ' text-primary bg-blue-100 hover:bg-blue-200 hover:shadow-blue-200/60 disabled:bg-blue-100') +
      ` ${className ?? ''}`
    }
    {...otherProps}
  >
    {children}
  </button>
)

export default BasicButton
