import { FC, useState } from 'react'
import { useCartContext } from 'context/cart'

import PersistentPortal from 'components/common/PersistentPortal'
import CartButton from 'components/cart/CartButton'
import CartDrawer from 'components/cart/CartDrawer'
import PromoCodeBanner from 'components/checkout/PromoCodeBanner'

const ShopCartLayout: FC = () => {
  const { validatedPromoCode } = useCartContext()
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState(false)

  return (
    <>
      <PersistentPortal containerElementSelector='#top-notification'>
        <PromoCodeBanner promoCode={validatedPromoCode} />
      </PersistentPortal>

      <PersistentPortal containerElementSelector='#header-additional-buttons'>
        <CartButton setCartDrawerState={setIsCartDrawerOpen} />
      </PersistentPortal>

      <CartDrawer isOpen={isCartDrawerOpen} setIsOpen={setIsCartDrawerOpen} />
    </>
  )
}

export default ShopCartLayout
