export const formatPrice = (
  price: number | undefined,
  currency: string | undefined,
  locale: string
) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency ?? 'EUR'
  }).format(price ?? 0)

export const getUnitPrice = ({
  price,
  amountInUnits,
  currencyCode,
  localeCode,
  unitSymbol
}: {
  price?: number
  amountInUnits?: number
  currencyCode?: string
  localeCode?: string
  unitSymbol?: string
}) =>
  price && amountInUnits && currencyCode && localeCode && unitSymbol
    ? `${formatPrice(
        (1000 * price) / amountInUnits,
        currencyCode,
        localeCode ?? ''
      )} / ${unitSymbol}`
    : ''
