import { FC } from 'react'
import { Outlet } from 'react-router-dom'

import PersistentPortal from 'components/common/PersistentPortal'
import ShopCartLayout from 'components/cart/ShopCartLayout'
import ShopTerms from 'components/shop/ShopTerms'

const ShopLayout: FC = () => {
  return (
    <>
      <ShopCartLayout />

      <PersistentPortal containerElementSelector='#page-footer'>
        <ShopTerms />
      </PersistentPortal>

      <Outlet />
    </>
  )
}

export default ShopLayout
