import { FC, ReactNode, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import FocusTrap from 'focus-trap-react'

import {
  bodyElement,
  createModalContainer,
  disableBodyScroll,
  enableBodyScroll,
  isBodyScrollDisabled
} from 'utils/domUtils'

import CrossIcon from './icons/CrossIcon'
import PersistentPortal from './PersistentPortal'

createModalContainer()

type ModalProps = {
  readonly isOpen: boolean
  readonly onClose?: () => void
  readonly allowEasyClose?: boolean
  readonly size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl'
  readonly title?: string
  readonly className?: string
  readonly contentClassName?: string
  readonly showCloseButton?: boolean
  readonly outsideCloseClick?: boolean
  readonly children: ReactNode
}

export const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  allowEasyClose = true,
  size = 'md',
  children,
  title,
  className,
  contentClassName
}) => {
  const { t: translated } = useTranslation()
  const isEventListenerActive = useRef<boolean>(false)

  const keydownEventHandler = useCallback(
    (event: KeyboardEvent) => {
      if (!allowEasyClose || typeof onClose !== 'function') return
      if (event.key === 'Escape' || event.code === 'Escape') onClose()
    },
    [allowEasyClose, onClose]
  )

  // add body-scroll-block when modal opens and remove when modal closes
  useEffect(() => {
    if (!bodyElement) return
    if (isOpen && !isBodyScrollDisabled()) disableBodyScroll()
    if (!isOpen && isBodyScrollDisabled()) enableBodyScroll()

    // remove body-scroll-block whenever the modal unmounts
    return () => enableBodyScroll()
  }, [isOpen])

  // close modal when `Escape` key pressed
  useEffect(() => {
    if (isOpen && !isEventListenerActive.current) {
      isEventListenerActive.current = true
      window.addEventListener('keydown', keydownEventHandler)
    }

    return () => {
      if (isEventListenerActive.current) {
        isEventListenerActive.current = false
        window.removeEventListener('keydown', keydownEventHandler)
      }
    }
  }, [isOpen, keydownEventHandler])

  if (!isOpen) return null

  const shouldRenderHeader = title || allowEasyClose

  return (
    <PersistentPortal containerElementSelector='#modal'>
      <FocusTrap>
        <div className='w-screen h-screen fixed inset-0 overflow-auto grid place-items-center'>
          <div
            className='fixed inset-0 bg-primary-transparent-background'
            onClick={() => {
              if (!allowEasyClose || typeof onClose !== 'function') return
              onClose()
            }}
          />

          <div
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-title'
            aria-describedby='modal-title'
            className={
              'relative w-11/12 bg-white rounded overflow-auto origin-center animate-drop-in ' +
              (size === 'sm'
                ? 'sm:max-w-sm'
                : size === 'md'
                ? 'sm:max-w-md'
                : size === 'lg'
                ? 'sm:max-w-lg'
                : size === 'xl'
                ? 'sm:max-w-xl'
                : size === '2xl'
                ? 'sm:max-w-2xl'
                : '') +
              (className ? ` ${className}` : '')
            }
          >
            {shouldRenderHeader && (
              <div
                className={
                  'flex items-center gap-2 px-4 pt-3' +
                  (title ? ' border-b border-gray-200 pb-2' : '')
                }
              >
                {title && (
                  <h3 id='modal-title' className='flex-1 text-xl text-center'>
                    {title}
                  </h3>
                )}

                {allowEasyClose && typeof onClose === 'function' && (
                  <button
                    autoFocus
                    onClick={onClose}
                    className='flex-none ml-auto focus:outline-none focus-visible:ring'
                    aria-label={translated('Close')}
                  >
                    <CrossIcon className='w-6' />
                  </button>
                )}
              </div>
            )}

            <div
              className={
                'px-4 ' +
                (shouldRenderHeader && title
                  ? 'pt-3 pb-5'
                  : shouldRenderHeader && !title
                  ? 'pt-1 pb-4'
                  : !shouldRenderHeader
                  ? 'pt-4 pb-3'
                  : contentClassName
                  ? ` ${contentClassName}`
                  : '')
              }
            >
              {children}
            </div>
          </div>
        </div>
      </FocusTrap>
    </PersistentPortal>
  )
}

export default Modal
