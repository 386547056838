import { useEffect, useRef } from 'react'
import type { CustomErrorMessage } from 'typings/errorTypes'
import TranslatedError from './TranslatedError'
import MessageBox from './MessageBox'

const TranslatedErrorBox = ({
  errorMessage,
  shouldFocus = false
}: {
  errorMessage?: CustomErrorMessage
  shouldFocus?: boolean
}) => {
  const messageBoxRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (errorMessage && shouldFocus)
      messageBoxRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
  }, [errorMessage, shouldFocus])

  return errorMessage ? (
    <MessageBox
      type='error'
      text={<TranslatedError error={errorMessage} />}
      className='mb-4'
      showIcon={true}
      ref={messageBoxRef}
    />
  ) : null
}

export default TranslatedErrorBox
