import { FC, ReactNode, useEffect } from 'react'

import PersistentPortal from './PersistentPortal'

type ScreenCoverProps = {
  readonly enabled?: boolean
  readonly children?: ReactNode
}

const preventEvent = (event: Event) => event.preventDefault()

const ScreenCover: FC<ScreenCoverProps> = ({ children }) => {
  useEffect(() => {
    document.addEventListener('keydown', preventEvent)
    return () => document.removeEventListener('keydown', preventEvent)
  }, [])

  return (
    <PersistentPortal containerElementSelector='#modal'>
      <div className='w-screen h-screen fixed inset-0 overflow-hidden grid place-items-center bg-secondary-lighter'>
        {children}
      </div>
    </PersistentPortal>
  )
}

export default ScreenCover
