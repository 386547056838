import { i18n } from 'context/localization'
import { codeDefaultLocale, supportedLanguageCodes } from 'localization'
import { ILocale, LocaleCodeType } from 'typings/languageTypes'

export const getLocaleContent = async (localeCode: LocaleCodeType) =>
  import(`./translations/${localeCode}.json`)

export const loadLanguageTranslations = async (localeCode: LocaleCodeType) => {
  if (
    !i18n.hasResourceBundle(localeCode, 'translation') &&
    supportedLanguageCodes.includes(localeCode)
  ) {
    return getLocaleContent(localeCode).then(module =>
      i18n.addResourceBundle(localeCode, 'translation', module.default)
    )
  }

  return Promise.resolve()
}

export const loadLanguageListTranslations = async (languageList: ReadonlyArray<ILocale> = []) => {
  if (languageList.length === 0) return Promise.resolve()
  await Promise.all(
    languageList
      .filter(({ code }) => code !== codeDefaultLocale)
      .map(locale => loadLanguageTranslations(locale.code))
  )
}
