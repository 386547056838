import { FC } from 'react'

import { IDefaultIconProps } from './iconTypes'

const CrossIcon: FC<IDefaultIconProps> = ({ className }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className={className}
    width='22'
    height='22'
    viewBox='0 0 24 24'
    fill='none'
    stroke='currentColor'
    strokeWidth='2'
    strokeLinecap='round'
    strokeLinejoin='round'
  >
    <line x1='18' y1='6' x2='6' y2='18'></line>
    <line x1='6' y1='6' x2='18' y2='18'></line>
  </svg>
)

export default CrossIcon
