import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { showToast } from 'components/layout/ToastNotification'

type CAIProps = {
  name: string
  finalValue: string
  maxValue?: number
  label?: string
  className?: string
  submitValue: (value: string) => void
  disabled?: boolean
}

const inputSubmitDebouncePeriod = 500 // number of milliseconds

const CartAmountInput: FC<CAIProps> = ({
  finalValue,
  maxValue = Infinity,
  name,
  label,
  className,
  submitValue,
  disabled = false
}) => {
  const { t: translated } = useTranslation()

  const previousFinalValueRef = useRef('')
  const inputDebounceRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const [temporaryValue, setTemporaryValue] = useState('')

  useEffect(() => {
    if (finalValue !== previousFinalValueRef.current) {
      setTemporaryValue('')
      previousFinalValueRef.current = finalValue
    }
  }, [finalValue])

  useEffect(() => {
    if (temporaryValue === '') return

    const temporaryValueInt = parseInt(temporaryValue)

    if (isNaN(temporaryValueInt) || temporaryValueInt <= 0) {
      setTemporaryValue(finalValue)
      showToast({
        type: 'warning',
        title: translated('Please enter a valid amount!') ?? ''
      })
      return
    }

    if (temporaryValueInt > maxValue) {
      setTemporaryValue(finalValue)
      showToast({
        type: 'warning',
        title: translated('You can purchase up to {{maxNumber}} units of this product.', {
          maxNumber: maxValue
        })
      })
      return
    }

    if (temporaryValue !== finalValue) {
      if (inputDebounceRef.current) clearTimeout(inputDebounceRef.current)
      inputDebounceRef.current = setTimeout(() => {
        submitValue(temporaryValue)
      }, inputSubmitDebouncePeriod)

      return () => {
        if (inputDebounceRef.current) clearTimeout(inputDebounceRef.current)
      }
    }
  }, [finalValue, maxValue, submitValue, temporaryValue, translated])

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const digitOnlyInput = event.target.value.replace(/[^\d]/g, '')
      if (temporaryValue !== digitOnlyInput) setTemporaryValue(digitOnlyInput)
    },
    [temporaryValue]
  )

  return (
    <>
      <label htmlFor={name} className='sr-only'>
        {label}
      </label>

      <input
        type='string'
        pattern='\d+'
        id={name}
        name={name}
        disabled={disabled}
        value={temporaryValue || finalValue}
        onChange={handleInputChange}
        className={'bg-transparent ' + className}
      />
    </>
  )
}

export default CartAmountInput
