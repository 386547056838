import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuthContext } from 'context/auth'
import AnimatedNumber from 'components/effects/AnimatedNumber'

type PMPProps = {
  value: number | undefined
  animated?: boolean
  customClasses?: string
}

const PMPoints: FC<PMPProps> = ({ value, animated = false, customClasses }) => {
  const { t: translated } = useTranslation()
  const { isCustomer } = useAuthContext()

  const formattedValue = Number.isInteger(value) ? value : value?.toFixed(2)

  return !isCustomer && formattedValue != null ? (
    <span
      className={
        ' flex items-center gap-0.5' +
        (customClasses ? ` ${customClasses}` : ' text-base leading-3')
      }
    >
      {animated ? (
        <AnimatedNumber value={formattedValue} className='' />
      ) : (
        <span>{formattedValue}</span>
      )}
      <span className='text-silver'>Ⓟ</span>
      <span className='sr-only'>{translated('Points')}</span>
    </span>
  ) : (
    <span />
  )
}

export default PMPoints
