import { FC } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { IProductDataResponse, IShopDataResponse } from 'typings/shopApi'
import { IApiError, IGenericServerError } from 'typings/errorTypes'
import config from 'config'
import { fetchProductDetails, fetchShopData } from 'services/shop'
import { usePrivacyContext } from 'context/privacy'
import { useAuthContext } from 'context/auth'
import { useThemeContext } from 'context/theme'
import Collapse from 'components/animations/Collapse'

const defaultPageNumber = config.defaultPageNumber
const defaultProductPageSize = config.defaultProductPageSize

const ShopTerms: FC = () => {
  const {
    shopId: shopIdPathParam,
    productId: productIdPathParam,
    localeCode: localeCodePathParam
  } = useParams()
  const [searchParams] = useSearchParams()
  const { t: translated } = useTranslation()
  const { accessToken } = useAuthContext()
  const { setIsCookieConsentModalVisible } = usePrivacyContext()
  const { setTheme } = useThemeContext()

  const pageNumber = searchParams.get('pageNumber') ?? defaultPageNumber
  const pageSize = searchParams.get('pageSize') ?? defaultProductPageSize
  const searchQuery = searchParams.get('search') ?? ''

  const { data: shopData } = useQuery<IShopDataResponse, IApiError | IGenericServerError>({
    enabled: !!shopIdPathParam && !!accessToken && !!localeCodePathParam,
    queryKey: ['products', shopIdPathParam, localeCodePathParam, pageNumber, pageSize, searchQuery],
    queryFn: () =>
      fetchShopData({
        shopId: shopIdPathParam ?? '',
        accessToken: accessToken ?? '',
        localeCode: localeCodePathParam ?? '',
        pageNumber,
        pageSize,
        search: searchQuery
      }) as Promise<IShopDataResponse>
  })

  const { data: productQueryResponse } = useQuery<
    IProductDataResponse,
    IApiError | IGenericServerError
  >({
    enabled: !!shopIdPathParam && !!productIdPathParam && !!accessToken && !!localeCodePathParam,
    queryKey: ['product', productIdPathParam, localeCodePathParam],
    queryFn: () =>
      fetchProductDetails({
        shopId: shopIdPathParam ?? '',
        productId: productIdPathParam ?? '',
        accessToken: accessToken ?? '',
        localeCode: localeCodePathParam ?? ''
      }) as Promise<IProductDataResponse>,
    onSuccess: productData => setTheme(productData.theme)
  })

  const legalLinks = shopData?.legalLinks ?? productQueryResponse?.legalLinks ?? []

  return (
    <aside className='mt-2 md:mt-4 py-8 md:py-12 bg-secondary'>
      <div
        className={
          'w-full max-w-lg md:max-w-xl mx-auto' +
          ' overflow-x-hidden overflow-y-scroll' +
          ' flex flex-col items-center gap-y-1' +
          ' text-xs md:text-sm text-gray-700'
        }
      >
        <p>{`© 1993-${new Date().getFullYear()} PM-International - All rights reserved.`}</p>

        <button className='text-primary' onClick={() => setIsCookieConsentModalVisible(true)}>
          {translated('Cookie settings')}
        </button>

        <Collapse isExpanded={legalLinks.length > 0}>
          <ul
            className={
              ' flex flex-col justify-center items-center gap-y-1' +
              ' md:flex-row md:gap-x-6 md:justify-around md:flex-wrap'
            }
          >
            {legalLinks.map(({ localizedLabels }, index) => (
              <li key={index}>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={localizedLabels[0].url}
                  className='underline hover:text-primary'
                >
                  {localizedLabels[0].name}
                </a>
              </li>
            ))}
          </ul>
        </Collapse>
      </div>
    </aside>
  )
}

export default ShopTerms
