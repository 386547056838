import { FC, ReactNode } from 'react'
import { motion } from 'framer-motion'
import { Navigate, useLocation, useParams } from 'react-router-dom'

import { useAppContext } from 'context/app'
import { useLocalizationContext } from 'context/localization'
import { usePrivacyContext } from 'context/privacy'

import { containerVariants, mainContentVariants } from './PageLayout.motion'
import ScreenCover from 'components/common/ScreenCover'
import SpinnerPM from 'components/common/SpinnerPM'
import CloudDownloadIcon from 'components/common/icons/CloudDownloadIcon'
import LanguageSelection from 'components/language/LanguageSelection'
import PageHeader from './PageHeader'
import CookieConsentModal from 'components/cookies/CookieConsentModal'

type PLProps = {
  readonly children: ReactNode
}

export const PageLayout: FC<PLProps> = ({ children }) => {
  const location = useLocation()
  const {
    localeCode: localeCodePathParam,
    shopId: shopIdPathParam,
    cartId: cartIdPathParam,
    vcartId: vcartIdPathParam
  } = useParams()
  const { isCookieConsentModalVisible } = usePrivacyContext()
  const { configuredLanguages } = useAppContext()
  const { appLocale } = useLocalizationContext()

  const isShopIdPathParamPresent = !!shopIdPathParam?.length
  const isCartIdPathParamPresent = !!cartIdPathParam?.length
  const isVcartIdPathParamPresent = !!vcartIdPathParam?.length

  if (!appLocale && configuredLanguages?.length === 1) {
    const newPathName =
      !!localeCodePathParam && location.pathname.includes(localeCodePathParam)
        ? location.pathname.replace(localeCodePathParam, configuredLanguages[0].code)
        : location.pathname

    return (
      <Navigate
        to={{
          pathname: newPathName,
          search: location.search,
          hash: location.hash
        }}
        replace={true}
      />
    )
  } else if (!appLocale && configuredLanguages?.length > 1) {
    return <LanguageSelection isOpen={true} locales={configuredLanguages} />
  } else if (isShopIdPathParamPresent || isCartIdPathParamPresent || isVcartIdPathParamPresent) {
    return (
      <motion.div
        className='fixed inset-0 w-screen flex flex-col overflow-x-hidden overflow-y-auto bg-page-bgcolor'
        variants={containerVariants}
        initial='hidden'
        animate='visible'
      >
        <PageHeader />

        <motion.main
          className='relative z-10 w-screen flex-1 flex bg-transparent'
          variants={mainContentVariants}
        >
          {children}
        </motion.main>

        <footer id='page-footer' />

        <footer id='sticky-footer' className='z-20 sticky bottom-0 left-0 w-full m-0 p-0' />

        {isCookieConsentModalVisible && <CookieConsentModal />}
      </motion.div>
    )
  } else {
    return (
      <ScreenCover>
        <SpinnerPM>
          <CloudDownloadIcon />
        </SpinnerPM>
      </ScreenCover>
    )
  }
}

export default PageLayout
