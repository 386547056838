import { FC, ReactNode, useRef } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useAuthContext } from 'context/auth'

const noRetryStatusCodes = [400, 401, 403, 404, 500]

type RQCPProps = {
  children: ReactNode
}

const ReactQueryContextProvider: FC<RQCPProps> = ({ children }) => {
  const { isAuthenticated, signIn } = useAuthContext()
  const queryClientRef = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: (failureCount: number, error: unknown) => {
            if (
              noRetryStatusCodes.includes(Number((error as { status: number | string })?.status)) ||
              noRetryStatusCodes.includes(
                Number((error as { statusCode: number | string })?.statusCode)
              )
            )
              return false
            if (failureCount < 3) return true
            return false
          },
          onError: error => {
            if (
              !isAuthenticated &&
              error &&
              typeof error === 'object' &&
              'status' in error &&
              error.status === 401
            ) {
              // Automatically sign in
              signIn()
            }
          }
        }
      }
    })
  )

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <ReactQueryDevtools initialIsOpen={false} position='top-left' />
      {children}
    </QueryClientProvider>
  )
}

export default ReactQueryContextProvider
