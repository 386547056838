import { createContext, Dispatch, FC, ReactNode, SetStateAction, useContext } from 'react'

import type { ILocale } from 'typings/languageTypes'

interface IAppContext {
  readonly shopId?: string | undefined
  readonly setShopId: Dispatch<SetStateAction<string | undefined>>
  readonly cartId?: string | undefined
  readonly setCartId: Dispatch<SetStateAction<string | undefined>>
  readonly configuredLanguages: ILocale[]
  readonly setConfiguredLanguages: Dispatch<SetStateAction<ILocale[]>>
  readonly userPromoCode?: string | null
  readonly isPromoCodeEnabled: boolean
  readonly setIsPromoCodeEnabled: Dispatch<SetStateAction<boolean>>
}

type AppContextProviderProps = {
  value: IAppContext
  children: ReactNode
}

const AppContext = createContext<IAppContext | null>(null)

const AppContextProvider: FC<AppContextProviderProps> = ({ children, value }) => {
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppContext = () => {
  const context = useContext(AppContext)
  if (context === null) throw new Error('useAppContext must be used within AppProvider')
  return context
}

export default AppContextProvider
