import { FC, ReactNode, useEffect } from 'react'

import { RxCross2 } from 'react-icons/rx'

import {
  bodyElement,
  disableBodyScroll,
  enableBodyScroll,
  isBodyScrollDisabled
} from 'utils/domUtils'

import PersistentPortal from './PersistentPortal'

type DrawerProps = {
  children: ReactNode
  isOpen: boolean
  setIsOpen: (state: boolean) => void
}

const Drawer: FC<DrawerProps> = ({ children, isOpen, setIsOpen }) => {
  // add body-scroll-block when the drawer opens and remove when the drawer closes
  useEffect(() => {
    if (!bodyElement) return
    if (isOpen && !isBodyScrollDisabled()) disableBodyScroll()
    if (!isOpen && isBodyScrollDisabled()) enableBodyScroll()

    // remove body-scroll-block whenever the drawer unmounts
    return () => enableBodyScroll()
  }, [isOpen])

  return (
    <PersistentPortal containerElementSelector='#drawer'>
      {/* ------------------ container ------------------ */}
      <div
        className={
          'z-30 fixed inset-0 h-full w-screen overflow-hidden ' +
          'bg-gray-900 bg-opacity-25 transform ease-in-out ' +
          (isOpen
            ? 'visible transition-opacity opacity-100 duration-500 translate-x-0'
            : 'invisible transition-all delay-500 opacity-0 translate-x-full')
        }
        aria-labelledby='drawer-title'
        role='dialog'
        aria-modal='true'
      >
        {/* ------------------ content ------------------ */}
        <aside
          className={
            'z-0 absolute h-full w-full max-w-lg right-0 overflow-hidden ' +
            'bg-white shadow-xl ' +
            'delay-400 duration-500 ease-in-out transition-all transform ' +
            (isOpen ? 'translate-x-0' : 'translate-x-full')
          }
        >
          <button
            className={
              'z-10 absolute top-2 md:top-2 left-2 md:left-2' +
              ' h-6 w-6 md:h-8 md:w-8 rounded-full' +
              ' bg-transparent hover:bg-primary-lightest' +
              ' grid place-content-center' +
              ' text-main hover:text-primary'
            }
            onClick={() => setIsOpen(false)}
          >
            <span className='sr-only'>Close drawer</span>
            <RxCross2 className='text-lg md:text-xl' />
          </button>

          {children}
        </aside>

        {/* ------------------ backdrop ------------------ */}
        <div className='h-full w-screen cursor-pointer ' onClick={() => setIsOpen(false)} />
      </div>
    </PersistentPortal>
  )
}

export default Drawer
