import type { ILocale, IDetailedLocale, LocaleCodeType } from 'typings/languageTypes'

export const codeDefaultLocale: LocaleCodeType = 'en-US'
export const codeUnknownLocale = 'int'
export const defaultLanguage: ILocale = { name: 'English', code: 'en-US' }

export const supportedLanguageCodes: LocaleCodeType[] = [
  'cs-CZ',
  'da-DK',
  'de-DE',
  'en-US',
  'es-ES',
  'fi-FI',
  'fr-FR',
  'hu-HU',
  'id-ID',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nl-NL',
  'nn-NO',
  'pl-PL',
  'ru-RU',
  'sv-SE',
  'tr-TR',
  'uk-UA',
  'zh-CN'
]

export const localeMap = new Map<string, IDetailedLocale>([
  [
    'cs-cz',
    {
      countryCode: 'CZ',
      languageCode: 'cs',
      languageName: 'Česky',
      localeCode: 'cs-CZ'
    }
  ],
  [
    'da-dk',
    {
      countryCode: 'DK',
      languageCode: 'da',
      languageName: 'Dansk',
      localeCode: 'da-DK'
    }
  ],
  [
    'de-de',
    {
      countryCode: 'DE',
      languageCode: 'de',
      languageName: 'Deutsch',
      localeCode: 'de-DE'
    }
  ],
  [
    'en-us',
    {
      countryCode: 'US',
      languageCode: 'en',
      languageName: 'English',
      localeCode: 'en-US'
    }
  ],
  [
    'es-es',
    {
      countryCode: 'ES',
      languageCode: 'es',
      languageName: 'Español',
      localeCode: 'es-ES'
    }
  ],
  [
    'fi-fi',
    {
      countryCode: 'FI',
      languageCode: 'fi',
      languageName: 'Suomi',
      localeCode: 'fi-FI'
    }
  ],
  [
    'fr-fr',
    {
      countryCode: 'FR',
      languageCode: 'fr',
      languageName: 'Français',
      localeCode: 'fr-FR'
    }
  ],
  [
    'hu-hu',
    {
      countryCode: 'HU',
      languageCode: 'hu',
      languageName: 'Magyar',
      localeCode: 'hu-HU'
    }
  ],
  [
    'id-id',
    {
      countryCode: 'ID',
      languageCode: 'id',
      languageName: 'Indonesia',
      localeCode: 'id-ID'
    }
  ],
  [
    'it-it',
    {
      countryCode: 'IT',
      languageCode: 'it',
      languageName: 'Italiano',
      localeCode: 'it-IT'
    }
  ],
  [
    'ja-jp',
    {
      countryCode: 'JP',
      languageCode: 'ja',
      languageName: '日本語',
      localeCode: 'ja-JP'
    }
  ],
  [
    'ko-kr',
    {
      countryCode: 'KR',
      languageCode: 'ko',
      languageName: '한국어',
      localeCode: 'ko-KR'
    }
  ],
  [
    'nl-nl',
    {
      countryCode: 'NL',
      languageCode: 'nl',
      languageName: 'Nederlands',
      localeCode: 'nl-NL'
    }
  ],
  [
    'nn-no',
    {
      countryCode: 'NO',
      languageCode: 'nn',
      languageName: 'Nørsk',
      localeCode: 'nn-NO'
    }
  ],
  [
    'pl-pl',
    {
      countryCode: 'PL',
      languageCode: 'pl',
      languageName: 'Polski',
      localeCode: 'pl-PL'
    }
  ],
  [
    'ru-ru',
    {
      countryCode: 'RU',
      languageCode: 'ru',
      languageName: 'Русский',
      localeCode: 'ru-RU'
    }
  ],
  [
    'sv-se',
    {
      countryCode: 'SE',
      languageCode: 'sv',
      languageName: 'Svenska',
      localeCode: 'sv-SE'
    }
  ],
  [
    'tr-tr',
    {
      countryCode: 'TR',
      languageCode: 'tr',
      languageName: 'Türkçe',
      localeCode: 'tr-TR'
    }
  ],
  [
    'uk-ua',
    {
      countryCode: 'UA',
      languageCode: 'uk',
      languageName: 'Українська',
      localeCode: 'uk-UA'
    }
  ],
  [
    'zh-cn',
    {
      countryCode: 'CN',
      languageCode: 'zh',
      languageName: '中文',
      localeCode: 'zh-CN'
    }
  ]
])
