export const containerVariants = {
  hidden: {
    opacity: 0,
    transition: { when: 'afterChildren', duration: 3 }
  },
  visible: {
    opacity: 1,
    transition: { when: 'beforeChildren', duration: 0.3, staggerChildren: 0.3 }
  }
}

export const titleVariants = {
  hidden: {
    opacity: 0,
    y: 20,
    scale: 0.9
  },
  visible: {
    opacity: 1,
    y: 0,
    scale: 1,
    transition: { duration: 0.3 }
  }
}

export const listVariants = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: { duration: 0.3 }
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.2, staggerChildren: 0.05 }
  }
}

export const itemVariants = {
  hidden: {
    opacity: 0,
    y: 20,
    transition: { duration: 0.3 }
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.3 }
  }
}
