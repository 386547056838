import type { IApiError, IGenericServerError } from 'typings/errorTypes'
import type { IShopDataResponse, IProductDataResponse, IShopBasicsResponse } from 'typings/shopApi'

import config from 'config'
const shopApiUrl = config.shopApiUrl

export const fetchShopBasics = async ({
  shopId
}: {
  shopId: string
}): Promise<IShopBasicsResponse> => {
  const response = await fetch(`${shopApiUrl}/${shopId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'en-US'
    }
  })

  if (response.ok) return response.json() as unknown as IShopBasicsResponse
  throw (await response.json()) as IApiError | IGenericServerError
}

export const fetchShopData = async ({
  shopId,
  accessToken,
  pageNumber,
  pageSize,
  search,
  localeCode
}: {
  shopId: string
  accessToken?: string
  pageNumber?: number | string
  pageSize?: number | string
  search?: string
  localeCode?: string
}): Promise<IShopDataResponse> => {
  const url = new URL(`${shopApiUrl}/${shopId}/products`)
  if (localeCode) url.searchParams.set('locale', localeCode)
  if (pageNumber) url.searchParams.set('page', String(pageNumber))
  if (pageSize) url.searchParams.set('perPage', String(pageSize))
  if (search) url.searchParams.set('search', search)

  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'en-US',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
    }
  })

  if (response.ok) return response.json() as unknown as IShopDataResponse
  throw (await response.json()) as IApiError | IGenericServerError
}

export const fetchProductDetails = async ({
  shopId,
  accessToken,
  productId,
  localeCode
}: {
  shopId: string
  accessToken?: string
  productId?: number | string
  localeCode?: string
}): Promise<IProductDataResponse> => {
  const url = new URL(`${shopApiUrl}/${shopId}/products/${productId}`)
  if (localeCode) url.searchParams.set('locale', localeCode)

  const response = await fetch(url.toString(), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': 'en-US',
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
    }
  })

  if (response.ok) return response.json() as unknown as IProductDataResponse
  throw (await response.json()) as IApiError | IGenericServerError
}
