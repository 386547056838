import { Dispatch, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import type {
  AddressFormAction,
  AddressFormInput,
  AddressFormState,
  InputFieldNames
} from 'typings/addressFormTypes'
import { isAtLeastOneCharLong } from 'utils/validationUtils'

export const addressFormReducer = (
  state: AddressFormState,
  action: AddressFormAction
): AddressFormState => {
  switch (action.type) {
    // set the newly entered value, perform validation, and set input field error messages
    case 'setInputValue': {
      const {
        payload: { id: payloadId, value: payloadValue }
      } = action
      const currentInputState = state.get(payloadId) as AddressFormInput
      const newState = new Map(state)
      const validationErrorMessages = currentInputState?.validationRules.reduce((output, rule) => {
        if (!rule.validator(payloadValue)) return [...output, rule.errorMessage]
        return output
      }, [] as string[])
      newState.set(payloadId, {
        ...(currentInputState as AddressFormInput),
        value: payloadValue,
        errors: validationErrorMessages
      })
      return newState
    }
    // bulk-populate form fields, and clear the errors of the populated fields
    case 'populateFormFields': {
      const newState = new Map(state)
      action.payload.forEach(({ id: payloadId, value: payloadValue }) => {
        const currentInputState = state.get(payloadId) as AddressFormInput
        newState.set(payloadId, {
          ...(currentInputState as AddressFormInput),
          value: payloadValue,
          errors: []
        })
      })

      return newState
    }
    case 'clearFormErrors': {
      const newState = new Map(state)
      state.forEach((value, key) => newState.set(key, { ...value, errors: [] }))
      return newState
    }
    default: {
      return state
    }
  }
}

const validationDebouncePeriod = 300 // number of milliseconds

export const useAddressForm = (): {
  addressFormFields: Map<InputFieldNames, AddressFormInput>
  isAddressFormValid: boolean
  addressFormDispatch: Dispatch<AddressFormAction>
} => {
  const { localeCode: localeCodePathParam } = useParams()
  const { t: translated } = useTranslation()
  const validationDebounceRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [isFormValid, setIsFormValid] = useState(false)

  const inputStateMap = useMemo(
    () =>
      new Map<InputFieldNames, AddressFormInput>([
        [
          'delivery-address-01',
          {
            label: translated('Full Name'),
            value: '',
            errors: [],
            required: true,
            validationRules: [
              {
                validator: isAtLeastOneCharLong,
                errorMessage: translated('This field is required')
              }
            ],
            customClasses: { wrapper: 'col-span-6' },
            readOnly: false
          }
        ],
        [
          'delivery-po-box',
          {
            label: translated('Street'),
            value: '',
            errors: [],
            required: true,
            validationRules: [
              {
                validator: isAtLeastOneCharLong,
                errorMessage: translated('This field is required')
              }
            ],
            customClasses: { wrapper: 'col-span-6 sm:col-span-3' },
            readOnly: false
          }
        ],
        [
          'delivery-address-02',
          {
            label: translated('Additional Address Information'),
            value: '',
            errors: [],
            required: false,
            validationRules: [],
            customClasses: { wrapper: 'col-span-6' },
            readOnly: false
          }
        ],
        [
          'delivery-zip-code',
          {
            label: translated('Zip / Postal Code'),
            value: '',
            errors: [],
            required: true,
            validationRules: [
              {
                validator: isAtLeastOneCharLong,
                errorMessage: translated('This field is required')
              }
            ],
            customClasses: { wrapper: 'col-span-6 sm:col-span-3' },
            readOnly: false
          }
        ],
        [
          'delivery-city',
          {
            label: translated('City'),
            value: '',
            errors: [],
            required: true,
            validationRules: [
              {
                validator: isAtLeastOneCharLong,
                errorMessage: translated('This field is required')
              }
            ],
            customClasses: { wrapper: 'col-span-6 sm:col-span-3' },
            readOnly: false
          }
        ],
        // [
        //   'delivery-state',
        //   {
        //     label: translated('State') + '/' + translated('Province'),
        //     value: '',
        //     errors: [],
        //     required: false,
        //     validationRules: [],
        //     customClasses: { wrapper: 'col-span-6 sm:col-span-3' },
        //     readOnly: false
        //   }
        // ],
        // [
        //   'delivery-district',
        //   {
        //     label: translated('District'),
        //     value: '',
        //     errors: [],
        //     required: false,
        //     validationRules: [],
        //     customClasses: { wrapper: 'col-span-6 sm:col-span-3' },
        //     readOnly: false
        //   }
        // ],
        // [
        //   'delivery-county',
        //   {
        //     label: translated('County'),
        //     value: '',
        //     errors: [],
        //     required: false,
        //     validationRules: [],
        //     customClasses: { wrapper: 'col-span-6 sm:col-span-3' },
        //     readOnly: false
        //   }
        // ],
        [
          'delivery-country-code',
          {
            label: translated('Country'),
            value: '',
            errors: [],
            required: true,
            validationRules: [
              {
                validator: isAtLeastOneCharLong,
                errorMessage: translated('This field is required')
              }
            ],
            customClasses: { wrapper: 'col-span-6 sm:col-span-2' },
            readOnly: true
          }
        ],
        [
          'delivery-email',
          {
            label: translated('Email address'),
            value: '',
            errors: [],
            required: false,
            validationRules: [],
            customClasses: { wrapper: 'col-span-6 sm:col-span-4' },
            readOnly: false
          }
        ],
        [
          'delivery-phone-01',
          {
            label: translated('Phone'),
            value: '',
            errors: [],
            required: false,
            validationRules: [],
            customClasses: { wrapper: 'col-span-6 sm:col-span-3' },
            readOnly: false
          }
        ],
        // [
        //   'delivery-phone-02',
        //   {
        //     label: translated('Phone'),
        //     value: '',
        //     errors: [],
        //     required: false,
        //     validationRules: [],
        //     customClasses: { wrapper: 'col-span-6 sm:col-span-3' },
        //     readOnly: false
        //   }
        // ],
        [
          'delivery-mobile-01',
          {
            label: translated('Mobile phone'),
            value: '',
            errors: [],
            required: false,
            validationRules: [],
            customClasses: { wrapper: 'col-span-6 sm:col-span-3' },
            readOnly: false
          }
        ]
        // [
        //   'delivery-mobile-02',
        //   {
        //     label: translated('Mobile phone'),
        //     value: '',
        //     errors: [],
        //     required: false,
        //     validationRules: [],
        //     customClasses: { wrapper: 'col-span-6 sm:col-span-3' },
        //     readOnly: false
        //   }
        // ]
      ]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translated, localeCodePathParam]
  )

  const [addressFormState, addressFormDispatch] = useReducer(addressFormReducer, inputStateMap)

  useEffect(() => {
    if (validationDebounceRef.current) clearTimeout(validationDebounceRef.current)
    validationDebounceRef.current = setTimeout(() => {
      const isFormValid = Array.from(addressFormState.values()).every(
        ({ validationRules, value, required }) =>
          validationRules.every(rule => (required ? rule.validator(value) === true : true))
      )
      setIsFormValid(isFormValid)
    }, validationDebouncePeriod)

    return () => {
      if (validationDebounceRef.current) clearTimeout(validationDebounceRef.current)
    }
  }, [addressFormState])

  return {
    addressFormFields: addressFormState,
    isAddressFormValid: isFormValid,
    addressFormDispatch
  }
}
