import { FC } from 'react'
import RadioInputIcon from 'components/common/icons/RadioInputIcon'

type MMOCProps = {
  id: string
  title: string
  details: string
  isSelected: boolean
  handleSelection: () => void
  customClasses?: string
  disabled?: boolean
}

const MidnightMadnessOptionCard: FC<MMOCProps> = ({
  id,
  title,
  details,
  isSelected,
  handleSelection,
  customClasses,
  disabled
}) => {
  return (
    <div className={'w-96 max-w-full relative'}>
      <input
        type='radio'
        name='midnight-madness-option'
        id={`midnight-madness-option-${id}`}
        value={id}
        disabled={disabled}
        checked={isSelected}
        onChange={handleSelection}
        className='sr-only peer'
      />
      <label
        htmlFor={`midnight-madness-option-${id}`}
        className={
          'relative block w-full py-4 px-4' +
          ' transition duration-200' +
          ' rounded-md border-2' +
          (isSelected ? ' border-secondary-darker' : ' border-secondary') +
          (disabled
            ? ' hover:cursor-not-allowed select-none'
            : ' hover:cursor-pointer hover:bg-secondary-lighter') +
          ' peer-focus-visible:ring' +
          ` ${customClasses}`
        }
      >
        <div className={'grid grid-cols-[min-content_1fr] items-center gap-4'}>
          {<RadioInputIcon size={20} selected={isSelected} />}

          <div className=''>
            <p className='mb-2 text-base font-normal'>{title}</p>

            <p className='text-sm text-justify font-light tracking-wide text-gray-500'>{details}</p>
          </div>
        </div>
      </label>
    </div>
  )
}

export default MidnightMadnessOptionCard
