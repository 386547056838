import { CSSProperties, DetailedHTMLProps, forwardRef, InputHTMLAttributes, ReactNode } from 'react'

export interface IToggleSwitchBaseProps {
  readonly label?: ReactNode
  readonly labelPosition?: 'left' | 'right'

  readonly toggleColor?: string
  readonly bgColor?: string
  readonly bgCheckedColor?: string
}

interface IToggleSwitchProps<T = HTMLInputElement>
  extends IToggleSwitchBaseProps,
    DetailedHTMLProps<InputHTMLAttributes<T>, T> {}

const ToggleSwitch = forwardRef<HTMLInputElement, IToggleSwitchProps>(
  (
    {
      label = null,
      labelPosition = 'right',
      disabled,
      className,

      toggleColor = '#ffffff',
      bgColor = '#d1d5db',
      bgCheckedColor = '#4ade80',

      ...props
    },
    ref
  ) => (
    <label
      style={
        {
          [`--toggle-color`]: toggleColor,
          [`--bg-color`]: bgColor,
          [`--bg-checked-color`]: bgCheckedColor
        } as CSSProperties
      }
      className={'relative group flex items-center' + (className ? ` ${className}` : '')}
    >
      {labelPosition === 'left' && label}
      <input
        type='checkbox'
        className='absolute w-full h-full left-1/2 -translate-x-1/2 peer appearance-none rounded-md cursor-pointer'
        disabled={disabled}
        ref={ref}
        {...props}
      />
      <span
        className={
          'flex items-center flex-shrink-0' +
          ' p-1 w-10 h-6 after:w-4 after:h-4' +
          ' rounded-full after:rounded-full after:shadow-md' +
          ' after:[background-color:var(--toggle-color)]' +
          ' [background-color:var(--bg-color)] peer-checked:[background-color:var(--bg-checked-color)]' +
          ' ease-in-out duration-300' +
          ' after:duration-300 after:cursor-pointer peer-checked:after:translate-x-4' +
          ' peer-disabled:opacity-70 peer-disabled:cursor-not-allowed'
        }
      />
      {labelPosition === 'right' && label}
    </label>
  )
)

ToggleSwitch.displayName = 'ToggleSwitch'

export default ToggleSwitch
