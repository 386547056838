import { FC, useCallback, useEffect } from 'react'
import { Navigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

import { FaCartPlus } from 'react-icons/fa'
import { RiExternalLinkLine } from 'react-icons/ri'
import { ImSpinner2 } from 'react-icons/im'

import {
  containerVariants,
  detailVariants,
  imageVariants,
  mainInfoVariants
} from './ProductItemPage.motion'

import type { IProductDataResponse } from 'typings/shopApi'
import type { IApiError, IGenericServerError } from 'typings/errorTypes'

import { formatPrice, getUnitPrice } from 'utils/priceUtils'
import { measurementUnitMap } from 'utils/unitUtils'
import { fetchProductDetails } from 'services/shop'
import { useAuthContext } from 'context/auth'
import { useAppContext } from 'context/app'
import { useShopContext } from 'context/shop'
import { useCartContext } from 'context/cart'
import { useThemeContext } from 'context/theme'
import { useLocalizationContext } from 'context/localization'

import BasicButton from 'components/common/buttons/BasicButton'
import ShopBreadcrumbs from 'components/shop/ShopBreadcrumbs'
import PMPoints from 'components/common/PMPoints'
import AnimatedNumber from 'components/effects/AnimatedNumber'
import SpinnerPM from 'components/common/SpinnerPM'
import CloudDownloadIcon from 'components/common/icons/CloudDownloadIcon'
import MessageBox from 'components/common/MessageBox'
import ProductImageDisplay from 'components/product/ProductImageDisplay'

const ProductItemPage: FC = () => {
  const {
    shopId: shopIdPathParam,
    productId: productIdPathParam,
    localeCode: localeCodePathParam
  } = useParams()
  const { t: translated } = useTranslation()
  const { accessToken, isAuthenticated, setIsLoginModalOpen } = useAuthContext()
  const { configuredLanguages, setConfiguredLanguages, setIsPromoCodeEnabled } = useAppContext()
  const { appLocale } = useLocalizationContext()
  const { setTheme } = useThemeContext()
  const { productMap, addProductsToMap } = useShopContext()
  const { cartItems, addUnitsToCart, isCartContentLoading, isCartUploading } = useCartContext()

  const cachedProductData = productMap.get(productIdPathParam ?? '')
  const cachedProductMatchesCurrentAppLocale =
    !!cachedProductData &&
    !!appLocale &&
    cachedProductData?.details.localeCode?.toLowerCase() === appLocale?.toLowerCase()

  const {
    data: productQueryResponse,
    error: productDataQueryError,
    isSuccess: isProductDataQuerySuccess,
    isLoading: isProductDataLoading,
    isError: isProductDataQueryError
  } = useQuery<IProductDataResponse, IApiError | IGenericServerError>({
    enabled:
      (!cachedProductData || !cachedProductMatchesCurrentAppLocale) &&
      !!localeCodePathParam &&
      !!shopIdPathParam &&
      !!productIdPathParam,
    queryKey: ['product', productIdPathParam, localeCodePathParam],
    queryFn: () =>
      fetchProductDetails({
        shopId: shopIdPathParam ?? '',
        productId: productIdPathParam ?? '',
        accessToken: accessToken,
        localeCode: localeCodePathParam ?? ''
      }) as Promise<IProductDataResponse>,
    staleTime: Infinity,
    onSuccess: productData => setTheme(productData.theme)
  })

  useEffect(() => {
    if (!isProductDataQuerySuccess || productQueryResponse == null) return

    if (productQueryResponse?.isPromoCodeEnabled != null)
      setIsPromoCodeEnabled(productQueryResponse?.isPromoCodeEnabled)

    if (
      productQueryResponse.languages?.length > 0 &&
      configuredLanguages?.length <= 1 &&
      configuredLanguages?.length != productQueryResponse.languages?.length
    )
      setConfiguredLanguages(productQueryResponse.languages)

    addProductsToMap([productQueryResponse?.product])
  }, [
    addProductsToMap,
    configuredLanguages?.length,
    isProductDataQuerySuccess,
    productQueryResponse,
    setConfiguredLanguages,
    setIsPromoCodeEnabled
  ])

  const productData = cachedProductMatchesCurrentAppLocale
    ? cachedProductData
    : productQueryResponse?.product
  const articleNumber = productData?.articleNumber

  const handleIncrement = useCallback(() => {
    if (!isAuthenticated) setIsLoginModalOpen(true)
    if (!articleNumber || isCartContentLoading || isCartUploading) return
    addUnitsToCart(articleNumber, 1)
  }, [
    addUnitsToCart,
    articleNumber,
    isAuthenticated,
    isCartContentLoading,
    isCartUploading,
    setIsLoginModalOpen
  ])

  const restrictions = productData?.restrictions
  const isRestrictedProduct =
    restrictions?.maxQuantityPerCustomer === 0 || restrictions?.maxQuantityPerOrder === 0

  if (isRestrictedProduct) return <Navigate to='..' replace={true} />

  const id = productData?.articleNumber
  const name = productData?.details?.attributes?.name?.value
  const currencyCode = productData?.details?.currencyCode
  const basePrice = productData?.details?.basePrice
  const finalPrice = productData?.details?.finalPrice
  const isPriceDiscounted = !!basePrice && !!finalPrice && finalPrice < basePrice
  const netWeight = productData?.details?.netWeight
  const netWeightUnit = productData?.details?.netWeightUnit
  const measurementUnitDisplayed = measurementUnitMap.get(netWeightUnit ?? '')
  const points = productData?.details?.points
  const inStock = productData?.inStock
  const attributes = productData?.details?.attributes
  const genericAttributes = Object.entries(attributes ?? {})
    .filter(([attributeName]) => attributeName !== 'name')
    .map(([attributeName, attributeObj]) => ({ ...attributeObj, attributeName }))
  const images = productData?.details?.media.filter(mediaObj => mediaObj?.type === 'image') ?? []
  const currentCartItem = cartItems.find(cartItem => cartItem.articleNumber === articleNumber)
  const quantity = currentCartItem?.quantity
  const restrictionValueList = Object.values(restrictions ?? {})
  const maxAmount = restrictionValueList?.length > 0 ? Math.min(...restrictionValueList) : Infinity
  const isMaxAmountReached = (quantity ?? 0) >= maxAmount
  const basePriceDisplayed =
    basePrice && currencyCode && localeCodePathParam
      ? formatPrice(basePrice, currencyCode, localeCodePathParam)
      : null
  const finalPriceDisplayed =
    finalPrice && currencyCode && localeCodePathParam
      ? formatPrice(finalPrice, currencyCode, localeCodePathParam)
      : null
  const unitPriceDisplayed =
    finalPrice && netWeight && currencyCode && localeCodePathParam && measurementUnitDisplayed
      ? getUnitPrice({
          price: finalPrice,
          amountInUnits: netWeight,
          currencyCode,
          localeCode: localeCodePathParam,
          unitSymbol: measurementUnitDisplayed
        })
      : null

  const isValidProduct = !!name && !!articleNumber

  return (
    <>
      <ShopBreadcrumbs
        routeSymbols={[
          <p
            key='breadcrumb-product-details'
            className='inline-block max-w-prose overflow-hidden whitespace-nowrap text-ellipsis'
          >
            {isProductDataLoading || isProductDataQueryError ? productIdPathParam : name}
          </p>
        ]}
      />

      <motion.div
        className='flex-1 relative container h-full mx-auto mt-0 mb-4 px-8 md:pt-10 md:px-14 rounded-xl'
        variants={containerVariants}
      >
        {isProductDataQueryError ? (
          <div className='min-h-full grid place-content-center'>
            <MessageBox
              type='error'
              text={
                'status' in productDataQueryError && 'title' in productDataQueryError
                  ? `${productDataQueryError?.status} ${productDataQueryError?.title}`
                  : `${productDataQueryError?.statusCode} ${productDataQueryError?.message}`
              }
              showIcon={true}
            />
          </div>
        ) : !productData && isProductDataLoading ? (
          <div className='min-h-full grid place-content-center'>
            <SpinnerPM>
              <CloudDownloadIcon />
            </SpinnerPM>
          </div>
        ) : isValidProduct ? (
          <>
            <div className='flex flex-col md:flex-row justify-evenly gap-4'>
              <motion.div
                className='flex-shrink-0 flex-grow-1 my-0 mx-auto md:mx-0'
                variants={imageVariants}
              >
                <ProductImageDisplay images={images} />
              </motion.div>

              <motion.div
                className='flex-grow-1 flex flex-col justify-center items-center gap-1 md:gap-2'
                variants={mainInfoVariants}
              >
                <h1 className='text-center md:text-left text-xl md:text-2xl'>{name}</h1>

                <h2 className='text-center md:text-left text-silver font-main font-normal text-xs md:text-sm'>
                  #{id}
                </h2>

                <p
                  className={
                    'w-full flex flex-wrap justify-center items-center gap-2' +
                    ' text-center font-normal'
                  }
                >
                  {isPriceDiscounted && basePriceDisplayed && (
                    <span className='font-normal text-xs md:text-sm text-silver line-through'>
                      {basePriceDisplayed}
                    </span>
                  )}
                  {finalPriceDisplayed && (
                    <span className='font-bold text-xl md:text-2xl'>{finalPriceDisplayed}</span>
                  )}
                </p>

                <p
                  className={
                    'w-full flex justify-around' + ' text-center text-base md:text-lg font-normal'
                  }
                >
                  <PMPoints value={points} customClasses={'text-base md:text-lg'} />
                </p>

                <p
                  className={
                    'flex justify-center items-center gap-2' +
                    ' font-normal text-silver text-xs md:text-sm'
                  }
                >
                  {netWeight && netWeightUnit && <span>{`${netWeight} ${netWeightUnit}`}</span>}
                  {unitPriceDisplayed && <span>{`(${unitPriceDisplayed})`}</span>}
                </p>

                <BasicButton
                  className={'relative flex gap-2 mt-2 mx-auto font-normal'}
                  disabled={
                    isAuthenticated &&
                    (!inStock || isMaxAmountReached || isCartContentLoading || isCartUploading)
                  }
                  onClick={handleIncrement}
                >
                  <span className='inline-flex items-center gap-2'>
                    <FaCartPlus size={24} className='-ml-0.5' />
                    {!inStock ? (
                      <span>{translated('Out of stock')}</span>
                    ) : isMaxAmountReached ? (
                      <span>{translated('Maximum amount reached')}</span>
                    ) : isCartUploading ? (
                      <ImSpinner2 size={24} className='animate-spin' />
                    ) : (
                      <span>{translated('Add to cart')}</span>
                    )}
                  </span>

                  {quantity ? (
                    <div
                      className={
                        'absolute -top-2 -right-2 px-1.5 py-1' +
                        ' inline-flex justify-center items-center' +
                        ' rounded-full border-2 border-primary' +
                        ' bg-secondary text-primary text-xs font-bold leading-none'
                      }
                    >
                      <AnimatedNumber value={quantity} />
                    </div>
                  ) : null}
                </BasicButton>
              </motion.div>
            </div>

            <motion.div className='mt-6' variants={detailVariants}>
              {genericAttributes.map(({ attributeName, label, value, type }) =>
                attributeName === 'sizeChart' ? (
                  <div className='mt-4' key={label}>
                    <h3 className=''>{label}</h3>
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      href={value}
                      className={'flex items-center gap-1' + ' underline hover:text-primary'}
                    >
                      <span>
                        {translated('See details about the sizes available for this product')}
                      </span>
                      <RiExternalLinkLine
                        className={'text-base sm:text-lg' + ' group-hover:scale-110'}
                      />
                    </a>
                  </div>
                ) : !!label && !!value ? (
                  <div className='mt-4' key={label}>
                    {type === 'Text' ? (
                      <>
                        <h3>{label}</h3>
                        <p className='whitespace-pre-line'>{value}</p>
                      </>
                    ) : type === 'Url' ? (
                      <a
                        target='_blank'
                        rel='noopener noreferrer'
                        href={value}
                        className={'flex items-center gap-1' + ' underline hover:text-primary'}
                      >
                        <span>{label}</span>
                        <RiExternalLinkLine
                          className={'text-base sm:text-lg' + ' group-hover:scale-110'}
                        />
                      </a>
                    ) : null}
                  </div>
                ) : null
              )}
            </motion.div>
          </>
        ) : (
          <div className='min-h-full grid place-content-center'>
            <MessageBox type='error' text={String(translated('Unknown product'))} showIcon={true} />
          </div>
        )}
      </motion.div>
    </>
  )
}

export default ProductItemPage
