import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'

import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
import { ImSpinner2 } from 'react-icons/im'

import type { CustomErrorMessage } from 'typings/errorTypes'
import type {
  GlobalCartValidationError,
  ICartContentValidationError,
  IGlobalCartValidationError
} from 'typings/checkoutApi'

import { measurementUnitMap } from 'utils/unitUtils'
import { validateBasketContent } from 'services/checkout'
import { useAuthContext } from 'context/auth'
import { useTelemetryContext } from 'context/insights'
import { useAppContext } from 'context/app'
import { useCheckoutContext } from 'context/checkout'

import { listVariants } from './CartContentPage.motion'

import PersistentPortal from 'components/common/PersistentPortal'
import ScreenCover from 'components/common/ScreenCover'
import CloudDownloadIcon from 'components/common/icons/CloudDownloadIcon'
import SpinnerPM from 'components/common/SpinnerPM'
import MessageBox from 'components/common/MessageBox'
import Button from 'components/common/buttons/Button'
import CartItem from 'components/checkout/CartItem'
import TranslatedError from 'components/common/TranslatedError'

const CartContentPage: FC = () => {
  const queryClient = useQueryClient()
  const location = useLocation()
  const navigate = useNavigate()
  const { cartId: cartIdPathParam, localeCode: localeCodePathParam } = useParams()
  const { accessToken, isCustomer, partnerId } = useAuthContext()
  const { t: translated } = useTranslation()
  const { appInsights } = useTelemetryContext()
  const { shopId, cartId } = useAppContext()
  const {
    basketQueryError,
    cartItems,
    isBasketLoading,
    isBasketQueryError,
    itemValidationErrorMap,
    setItemValidationErrorMap,
    addUnitsToCart,
    removeUnitsFromCart,
    setCartArticleUnits,
    isCartUploading,
    isPaid,
    isCanceled,
    cartContentQueryKey
  } = useCheckoutContext()

  const messageBoxRef = useRef<HTMLDivElement>(null)

  const [cartValidationErrors, setCartValidationErrors] = useState<GlobalCartValidationError[]>([])

  const validateCartContentMutation = useMutation<
    boolean,
    ICartContentValidationError | IGlobalCartValidationError
  >({
    mutationFn: () => validateBasketContent(cartIdPathParam ?? '', accessToken ?? ''),
    onSuccess: () => {
      appInsights?.trackEvent({
        name: 'CheckoutBasketValidation',
        properties: {
          isTeamPartner: !isCustomer,
          customerId: partnerId,
          shopId,
          cartId,
          status: 'success'
        }
      })
      queryClient.invalidateQueries(cartContentQueryKey)
      navigate(
        { pathname: 'destination', search: location.search },
        { state: { source: 'cart-content' } }
      )
    },
    onError: cartValidationError => {
      appInsights?.trackEvent({
        name: 'CheckoutBasketValidation',
        properties: {
          isTeamPartner: !isCustomer,
          customerId: partnerId,
          shopId,
          cartId,
          status: 'error',
          error: cartValidationError
        }
      })
      if ('itemValidationResults' in cartValidationError) {
        const itemErrorMap = new Map<string, CustomErrorMessage>()
        cartValidationError.itemValidationResults.forEach(itemValidationError => {
          if (itemValidationError.result === 'ok') return
          itemErrorMap.set(itemValidationError.articleNumber, itemValidationError.result)
        })
        setItemValidationErrorMap(itemErrorMap)
      } else if ('globalValidationResults' in cartValidationError) {
        setCartValidationErrors(cartValidationError.globalValidationResults)
      } else {
        setCartValidationErrors(['basket_validation_failure'])
      }
    }
  })

  useEffect(() => {
    if (cartValidationErrors?.length > 0)
      messageBoxRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
  }, [cartValidationErrors])

  const handleIncrement = useCallback(
    (articleNumber: string) => {
      if (!articleNumber || !cartIdPathParam || isCartUploading) return
      addUnitsToCart(articleNumber, 1)
      setCartValidationErrors([])
    },
    [addUnitsToCart, cartIdPathParam, isCartUploading]
  )

  const handleDecrement = useCallback(
    (articleNumber: string) => {
      if (!articleNumber || !cartIdPathParam || isCartUploading) return
      removeUnitsFromCart(articleNumber, 1)
      setCartValidationErrors([])
    },
    [cartIdPathParam, isCartUploading, removeUnitsFromCart]
  )

  const handleSetValue = useCallback(
    (articleNumber: string, value: number) => {
      if (!articleNumber || !cartIdPathParam || isCartUploading) return
      setCartArticleUnits(articleNumber, value)
      setCartValidationErrors([])
    },
    [cartIdPathParam, isCartUploading, setCartArticleUnits]
  )

  const handleCartSubmission = useCallback(() => {
    validateCartContentMutation.mutate()
  }, [validateCartContentMutation])

  if (isBasketLoading)
    return (
      <ScreenCover>
        <SpinnerPM>
          <CloudDownloadIcon />
        </SpinnerPM>
      </ScreenCover>
    )

  if (isBasketQueryError && basketQueryError?.title)
    return (
      <div className='grid place-content-center'>
        <MessageBox type='error' text={basketQueryError.title} showIcon={true} />
      </div>
    )

  if (cartItems?.length <= 0)
    return (
      <div className='w-full grid place-content-center'>
        <MessageBox
          type='info'
          text={String(translated('Your shopping cart is empty'))}
          showIcon={true}
        />
      </div>
    )

  return (
    <div
      className={
        'w-full max-w-3xl mx-auto px-2' +
        ' flex-1 overflow-hidden' +
        ' bg-white' +
        ' border border-secondary rounded-t-md' +
        ' shadow-md'
      }
    >
      {/* --------------- message box: start --------------- */}
      {cartValidationErrors?.map(error => (
        <MessageBox
          key={error}
          type='error'
          text={<TranslatedError error={error} />}
          className='my-4'
          showIcon={true}
          ref={messageBoxRef}
        />
      ))}
      {/* --------------- message box: end --------------- */}

      <motion.ul
        className={
          'w-full max-w-lg md:max-w-xl mx-auto' +
          ' overflow-x-hidden overflow-y-scroll' +
          ' divide-y divide-secondary-darker'
        }
        variants={listVariants}
      >
        <AnimatePresence>
          {cartItems.map((cartItem, index) => (
            <motion.li
              key={cartItem.articleNumber}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              exit={{
                opacity: 0,
                x: '50%',
                transition: { duration: 0.1, delay: 0 }
              }}
            >
              <CartItem
                {...cartItem}
                isDisabled={validateCartContentMutation.isLoading}
                inStock={cartItem.inStock}
                maxAmount={cartItem.maxAmount}
                incrementValue={() => handleIncrement(cartItem.articleNumber)}
                decrementValue={() => handleDecrement(cartItem.articleNumber)}
                setValue={value => handleSetValue(cartItem.articleNumber, value)}
                validationError={itemValidationErrorMap?.get(cartItem.articleNumber)}
                clearError={() =>
                  setItemValidationErrorMap(prevMap => {
                    const newErrorMap = new Map(prevMap)
                    newErrorMap?.delete(cartItem.articleNumber)
                    return newErrorMap
                  })
                }
                isBusy={isCartUploading}
                amountInUnits={cartItem?.amountInUnits}
                unitSymbol={measurementUnitMap.get(cartItem?.unit ?? '')}
              />
            </motion.li>
          ))}
        </AnimatePresence>
      </motion.ul>

      <PersistentPortal containerElementSelector='#footer-buttons'>
        {/* --------------- buttons: start --------------- */}
        <div className='flex flex-row justify-between items-center gap-4'>
          <button
            className='flex items-center text-primary'
            onClick={() =>
              navigate(
                { pathname: `/${localeCodePathParam}/shop/${shopId}`, search: location.search },
                { state: { source: 'cart-content' } }
              )
            }
          >
            <RiArrowLeftSLine size={24} className='inline-block' />
            <span>{translated('Back')}</span>
          </button>

          <Button
            contentClassName='flex items-center'
            onClick={handleCartSubmission}
            disabled={
              isBasketLoading ||
              isBasketQueryError ||
              !cartItems?.length ||
              !!itemValidationErrorMap?.size ||
              validateCartContentMutation.isLoading ||
              isPaid ||
              isCanceled
            }
            variant='primary'
          >
            {validateCartContentMutation.isLoading ? (
              <ImSpinner2 size={24} className='animate-spin' />
            ) : (
              <>
                <span>{translated('Confirm')}</span>
                <RiArrowRightSLine size={24} className='-mr-2' />
              </>
            )}
          </Button>
        </div>
        {/* --------------- buttons: end --------------- */}
      </PersistentPortal>
    </div>
  )
}

export default CartContentPage
