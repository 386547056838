import { FC, useCallback, useEffect, useReducer, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'
import { motion } from 'framer-motion'

import { RiArrowRightSLine, RiExternalLinkLine } from 'react-icons/ri'
import { RiArrowLeftSLine } from 'react-icons/ri'
import { HiMinusCircle, HiPlusCircle } from 'react-icons/hi'
import { ImSpinner2 } from 'react-icons/im'

import type {
  CartDeliveryValidationError,
  CheckoutConfirmationStatus,
  GlobalCartValidationError,
  IGlobalCartValidationSuccess
} from 'typings/checkoutApi'
import type { CustomErrorMessage } from 'typings/errorTypes'

import { appUrl } from 'utils/urlUtils'
import { formatPrice } from 'utils/priceUtils'
import { validateCompleteCart } from 'services/checkout'
import useConditionListConsent from 'hooks/useConditionConsent'

import { useAuthContext } from 'context/auth'
import { useAppContext } from 'context/app'
import { usePrivacyContext } from 'context/privacy'
import { useTelemetryContext } from 'context/insights'
import { useCheckoutContext } from 'context/checkout'
import { useThemeContext } from 'context/theme'

import { containerVariants, contentVariants } from './CheckoutSummaryPage.motion'
import PersistentPortal from 'components/common/PersistentPortal'
import Button from 'components/common/buttons/Button'
import CheckIconAnimated from 'components/common/icons/CheckIconAnimated'
import MessageBox from 'components/common/MessageBox'
import TranslatedError from 'components/common/TranslatedError'
import VWInfo from 'components/vw/VWInfo'
import CheckoutPromoCode from 'components/checkout/CheckoutPromoCode'

const getPostPaymentReturnUrl = ({
  appUrl,
  pathname,
  searchParams,
  status,
  theme
}: {
  appUrl: string
  pathname: string
  searchParams: URLSearchParams
  status: CheckoutConfirmationStatus
  theme: 'pm' | 'fitline'
}) => {
  const url = new URL(appUrl)
  url.pathname = pathname.replace('summary', 'confirmation')
  url.search = searchParams.toString()
  url.searchParams.append('status', status)
  if (!url.searchParams.has('theme')) url.searchParams.set('theme', theme)
  return url.toString()
}

const CheckoutSummaryPage: FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const {
    localeCode: localeCodePathParam,
    cartId: cartIdPathParam,
    vcartId: vcartIdPathParam
  } = useParams()
  const [searchParams] = useSearchParams()
  const { accessToken, isCustomer, partnerId } = useAuthContext()
  const { t: translated } = useTranslation()
  const { appInsights } = useTelemetryContext()
  const { shopId } = useAppContext()
  const { isCookieConsentGiven } = usePrivacyContext()
  const { theme } = useThemeContext()

  const {
    cartItems,
    setItemValidationErrorMap,
    conditions,
    additionalCharges,
    totalAdditionalCharges,
    totalDeliveryCharges,
    cartSubtotal,
    totalTaxes,
    taxGroups,
    goesToVirtualWarehouse,
    isPaid,
    isCanceled,
    askAboutMidnightMadness,
    midnightMadnessPointAllocationMonth
  } = useCheckoutContext()

  const cartId = cartIdPathParam || vcartIdPathParam || ''

  const [isTaxBreakdownVisible, toggleIsTaxBreakdownVisible] = useReducer(state => !state, false)

  const { conditionConsentState, toggleCondition, areSomeConditionsUnfulfilled } =
    useConditionListConsent(
      conditions.map(condition => ({
        name: condition.localizedLabels[0].name,
        required: condition.enforce
      }))
    )

  const messageBoxRef = useRef<HTMLDivElement>(null)

  const [cartValidationErrors, setCartValidationErrors] = useState<GlobalCartValidationError[]>([])

  useEffect(() => {
    if (askAboutMidnightMadness && !midnightMadnessPointAllocationMonth)
      navigate(
        { pathname: '../mm', search: location.search },
        { state: { source: 'checkout-summary' } }
      )
  }, [askAboutMidnightMadness, midnightMadnessPointAllocationMonth, navigate])

  useEffect(() => {
    if (cartValidationErrors?.length > 0)
      messageBoxRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
  }, [cartValidationErrors])

  const validateCompleteCartMutation = useMutation<
    IGlobalCartValidationSuccess,
    CartDeliveryValidationError
  >({
    mutationFn: () =>
      validateCompleteCart(
        cartId ?? '',
        accessToken ?? '',
        {
          urls: {
            cancel: getPostPaymentReturnUrl({
              appUrl,
              pathname,
              searchParams,
              status: 'cancel',
              theme
            }),
            failure: getPostPaymentReturnUrl({
              appUrl,
              pathname,
              searchParams,
              status: 'failure',
              theme
            }),
            success: getPostPaymentReturnUrl({
              appUrl,
              pathname,
              searchParams,
              status: 'success',
              theme
            })
          },
          ...(askAboutMidnightMadness
            ? { midnightMadnessCommissionPeriodDate: midnightMadnessPointAllocationMonth }
            : {})
        },
        localeCodePathParam ?? ''
      ),
    onSuccess: deliveryValidationData => {
      appInsights?.trackEvent({
        name: 'CheckoutReadyToPay',
        properties: {
          isTeamPartner: !isCustomer,
          customerId: partnerId,
          shopId,
          cartId,
          status: 'success'
        }
      })

      try {
        appInsights?.flush(false)
      } catch (error) {
        console.error('Failed to flush MSAI', error)
      } finally {
        const paymentUrlString = deliveryValidationData.paymentUrl
        if (paymentUrlString) {
          const paymentUrl = new URL(paymentUrlString)
          paymentUrl.searchParams.set('tc', isCookieConsentGiven.toString())
          paymentUrl.searchParams.set('locale', localeCodePathParam ?? '')
          window.location.assign(paymentUrl.toString())
        }
      }
    },
    onError: cartValidationError => {
      appInsights?.trackEvent({
        name: 'CheckoutReadyToPay',
        properties: {
          isTeamPartner: !isCustomer,
          customerId: partnerId,
          shopId,
          cartId,
          status: 'error',
          error: cartValidationError
        }
      })
      if ('itemValidationResults' in cartValidationError) {
        const itemErrorMap = new Map<string, CustomErrorMessage>()
        cartValidationError.itemValidationResults.forEach(itemValidationError => {
          if (itemValidationError.result === 'ok') return
          itemErrorMap.set(itemValidationError.articleNumber, itemValidationError.result)
        })
        setItemValidationErrorMap(itemErrorMap)
        navigate('..')
      } else if ('globalValidationResults' in cartValidationError) {
        setCartValidationErrors(cartValidationError.globalValidationResults)
        navigate('..')
      } else {
        setCartValidationErrors(['basket_validation_failure'])
        navigate('..')
      }
    }
  })

  const conditionToggleHandler = useCallback(
    (conditionName: string, isRequired: boolean) => {
      if (!isRequired) return () => null
      return () => toggleCondition(conditionName)
    },
    [toggleCondition]
  )

  const handleCheckoutSubmit = useCallback(() => {
    validateCompleteCartMutation.mutate()
  }, [validateCompleteCartMutation])

  const cartCurrency = cartItems[0]?.currency
  const hasAdditionalCostItems =
    totalDeliveryCharges > 0 || totalAdditionalCharges > 0 || totalTaxes > 0

  return (
    <motion.div
      className={
        'w-full max-w-3xl mx-auto sm:p-4' +
        ' flex-1 overflow-hidden' +
        ' grid grid-cols-1 grid-rows-[min-content_auto_min-content]' +
        ' bg-white' +
        ' border border-secondary rounded-t-md' +
        ' shadow-md'
      }
      variants={containerVariants}
    >
      <VWInfo isVisible={goesToVirtualWarehouse} />

      {/* --------------- error box: start --------------- */}
      {cartValidationErrors?.map(error => (
        <MessageBox
          key={error}
          type='error'
          text={<TranslatedError error={error} />}
          className='mb-4'
          showIcon={true}
          ref={messageBoxRef}
        />
      ))}
      {/* --------------- error box: end --------------- */}

      {/* --------------- content: start --------------- */}
      <motion.div variants={contentVariants}>
        <div
          className={
            'grow-0 w-full max-w-3xl mx-auto py-3 sm:py-6 md:py-9 px-3 sm:px-4 md:px-6 overflow-hidden' +
            ' divide-y divide-secondary-darker'
          }
        >
          <h4 className='pt-2 pb-4 md:text-lg text-center'>{translated('Summary')}</h4>

          {/* --------------- cart item list: start --------------- */}
          <ul className='w-full grid grid-cols-[min-content_1fr_auto] gap-x-4 gap-y-1 md:gap-y-1.5 py-3 text-sm sm:text-base'>
            {cartItems.map(cartItem => (
              <li key={cartItem.articleNumber} className='contents leading-relaxed'>
                <span className='whitespace-nowrap text-gray-500 text-right font-light'>
                  {cartItem.quantity} x
                </span>

                <span>{cartItem.name}</span>

                <span className='text-right'>
                  {formatPrice(
                    cartItem.quantity * cartItem.price,
                    cartCurrency,
                    localeCodePathParam ?? ''
                  )}
                </span>
              </li>
            ))}
          </ul>
          {/* --------------- cart item list: end --------------- */}

          {/* --------------- subtotal: start --------------- */}
          {cartItems.length > 1 && hasAdditionalCostItems ? (
            <div className='py-2 sm:py-3 flex justify-between text-sm sm:text-base'>
              <span>{translated('Subtotal')}</span>
              <span>{formatPrice(cartSubtotal, cartCurrency, localeCodePathParam ?? '')}</span>
            </div>
          ) : null}
          {/* --------------- subtotal: end --------------- */}

          {/* --------------- additional cost items: start --------------- */}
          {hasAdditionalCostItems && (
            <div className='py-2 sm:py-3 text-sm sm:text-base'>
              {totalDeliveryCharges > 0 ? (
                <div className='py-0 sm:py-0.5 flex justify-between leading-relaxed'>
                  <span className='capitalize'>{translated('Shipping cost')}</span>
                  <span>
                    {formatPrice(totalDeliveryCharges, cartCurrency, localeCodePathParam ?? '')}
                  </span>
                </div>
              ) : null}

              {totalAdditionalCharges > 0 &&
                additionalCharges.map(additionalCharge => {
                  const additionalChargeName = additionalCharge.localizedLabels?.[0]?.name
                  return (
                    <div
                      key={additionalChargeName}
                      className='py-0 sm:py-0.5 flex justify-between leading-normal'
                    >
                      <span className='capitalize'>{additionalChargeName}</span>
                      <span>
                        {formatPrice(
                          additionalCharge.cost.final,
                          additionalCharge.cost.currencyCode,
                          localeCodePathParam ?? ''
                        )}
                      </span>
                    </div>
                  )
                })}

              {totalTaxes > 0 ? (
                <button
                  className='w-full py-0 sm:py-0.5 flex justify-between leading-relaxed'
                  onClick={toggleIsTaxBreakdownVisible}
                  disabled={taxGroups.length <= 0}
                >
                  <span className='flex items-center gap-1 capitalize'>
                    {translated('Total taxes')}
                    {isTaxBreakdownVisible ? (
                      <HiMinusCircle size={20} className='inline-block text-secondary-darker' />
                    ) : (
                      <HiPlusCircle size={20} className='inline-block text-secondary-darker' />
                    )}
                  </span>
                  <span>{formatPrice(totalTaxes, cartCurrency, localeCodePathParam ?? '')}</span>
                </button>
              ) : null}

              {taxGroups.length > 0 ? (
                <ul
                  className={
                    'relative h-fit overflow-hidden' +
                    ' transition-all ease-in-out duration-500' +
                    (isTaxBreakdownVisible ? ' max-h-screen opacity-100' : ' max-h-0 opacity-0')
                  }
                >
                  {taxGroups.map(({ taxRate, taxAmount }) => (
                    <li
                      key={`tax-rate-${taxRate}`}
                      className={
                        'flex py-0 sm:py-0.5 pl-6' +
                        ' before:content-["*"] before:mr-1' +
                        ' text-smoke-500'
                      }
                    >
                      <span className='inline-block'>
                        {translated('Tax collected at a rate of {{taxRate}}%', { taxRate })}
                      </span>
                      <span className='inline-block ml-auto'>
                        {formatPrice(taxAmount, cartCurrency, localeCodePathParam ?? '')}
                      </span>
                    </li>
                  ))}
                </ul>
              ) : null}
            </div>
          )}
          {/* --------------- additional cost items: end --------------- */}
        </div>
      </motion.div>
      {/* --------------- content: end --------------- */}

      <PersistentPortal containerElementSelector='#promo-code'>
        <CheckoutPromoCode />
      </PersistentPortal>

      <PersistentPortal containerElementSelector='#footer-terms'>
        {/* --------------- terms / conditions: start --------------- */}
        {conditions.length > 0 && (
          <ul className='grid place-content-center gap-y-2.5 text-sm sm:text-base'>
            {conditions.map(condition => {
              const conditionName = condition.localizedLabels[0].name
              const conditionText = condition.localizedLabels[0].info
              const isConditionRequired =
                conditionConsentState.get(conditionName)?.required ?? false
              const isConditionAccepted = conditionConsentState.get(conditionName)?.accepted

              return (
                <li key={conditionName} className='flex justify-start items-center gap-1'>
                  <label
                    htmlFor={`consent-checkbox-${conditionName}`}
                    className={
                      'flex justify-center items-center gap-2' +
                      (isConditionRequired ? ' cursor-pointer' : ' cursor-default')
                    }
                  >
                    <input
                      type='checkbox'
                      id={`consent-checkbox-${conditionName}`}
                      className='sr-only peer'
                      checked={isConditionAccepted}
                      onChange={conditionToggleHandler(conditionName, isConditionRequired)}
                    />
                    <div
                      className={
                        'h-4 w-4 sm:h-[18px] sm:w-[18px] shrink-0 inline-grid place-content-center' +
                        ' border rounded leading-none' +
                        (isConditionRequired && isConditionAccepted
                          ? ' border-success text-success'
                          : isConditionRequired
                          ? ' border-rose-500 text-primary'
                          : ' opacity-0 invisible') +
                        ' peer-focus:outline-none peer-focus-visible:animate-pulsate' +
                        ' peer-active:animate-pulsate'
                      }
                    >
                      {isConditionAccepted ? (
                        <CheckIconAnimated className={'h-3 w-3 sm:h-4 sm:w-4'} />
                      ) : null}
                    </div>
                    <span className='select-none'>{conditionText}</span>
                  </label>

                  <a
                    href={condition.localizedLabels[0].url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className={
                      'text-gray-500' +
                      ' hover:scale-110 hover:text-primary' +
                      ' focus:outline-none focus-visible:animate-pulsate' +
                      ' active:animate-pulsate '
                    }
                  >
                    <RiExternalLinkLine className='text-base sm:text-lg' />
                  </a>
                </li>
              )
            })}
          </ul>
        )}
        {/* --------------- terms / conditions: end --------------- */}
      </PersistentPortal>

      <PersistentPortal containerElementSelector='#footer-buttons'>
        {/* --------------- buttons: start --------------- */}
        <div className='flex flex-row justify-between items-center gap-4'>
          <button
            className='flex items-center text-primary'
            onClick={() => {
              navigate(
                { pathname: '../mm', search: location.search },
                { state: { source: 'checkout-summary' } }
              )
            }}
          >
            <RiArrowLeftSLine size={24} className='inline-block' />
            <span>{translated('Back')}</span>
          </button>

          <Button
            onClick={handleCheckoutSubmit}
            disabled={
              areSomeConditionsUnfulfilled ||
              validateCompleteCartMutation.isLoading ||
              isPaid ||
              isCanceled
            }
            contentClassName='flex items-center'
            variant='primary'
          >
            {validateCompleteCartMutation.isLoading ? (
              <ImSpinner2 size={24} className='animate-spin' />
            ) : (
              <>
                <span>{translated('Confirm')}</span>
                <RiArrowRightSLine size={24} className='-mr-2' />
              </>
            )}
          </Button>
        </div>
        {/* --------------- buttons: end --------------- */}
      </PersistentPortal>
    </motion.div>
  )
}

export default CheckoutSummaryPage
