/* eslint-disable camelcase */
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useLocation } from 'react-router-dom'
import { UserManager, User as UserAuthData } from 'oidc-client-ts'

import type { IAuthContextProvider, SignOutRedirect } from './AuthContext.types'
import { codeDefaultLocale } from 'localization'
import config from 'config'
import { appUrl } from 'utils/urlUtils'
import useLocalStorage from 'hooks/useLocalStorage'

const { identityClientId, identityServerApiUrl } = config

const defaultUserManagerSettings = {
  authority: identityServerApiUrl ?? '',
  client_id: identityClientId ?? '',
  ui_locales: codeDefaultLocale,
  redirect_uri: `${appUrl}/authcallback`,
  post_logout_redirect_uri: `${appUrl}/signoutcallback`,
  scope: 'offline_access profile openid shop checkout order',
  response_type: 'code',
  automaticSilentRenew: true,
  loadUserInfo: true,
  accessTokenExpiringNotificationTimeInSeconds: -1,
  acr_values: 'tenant:fitline'
}

export const AuthContext = createContext<IAuthContextProvider | null>(null)

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { pathname, search, hash } = useLocation()
  // https://authts.github.io/oidc-client-ts/classes/UserManager.html
  const [userManager, setUserManager] = useState(() => new UserManager(defaultUserManagerSettings))
  const [isAuthLoading, setIsAuthLoading] = useState(false)
  const [authError, setAuthError] = useState<string | null>(null)
  const [userData, setUserData] = useState<UserAuthData | null>(null)
  const [_signoutRedirect, setSignoutRedirect] = useLocalStorage<SignOutRedirect>(
    config.signoutRedirectKey,
    null
  )
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false)

  const userManagerLanguage = userManager?.settings?.ui_locales

  const updateUserData = useCallback(async () => {
    if (isAuthLoading) return

    try {
      setIsAuthLoading(true)
      setAuthError(null)
      const user = await userManager.getUser()
      setUserData(user)
    } catch (error) {
      console.error(`ACP failed to get user data: ${JSON.stringify(error)}`)
      setAuthError(JSON.stringify(error))
    } finally {
      setIsAuthLoading(false)
    }
  }, [isAuthLoading, userManager])

  useEffect(() => {
    ;(async () => {
      if (!userData) {
        const user = await userManager.getUser()
        if (user?.access_token && user?.expired === false) return setUserData(user)
      }
    })()
  }, [userData, userManager])

  useEffect(() => {
    userManager.events.addUserLoaded(updateUserData)
    return () => userManager.events.removeUserLoaded(updateUserData)
  }, [updateUserData, userManager.events])

  const updateAuthLanguage = useCallback((newLanguageCode: string) => {
    setUserManager(new UserManager({ ...defaultUserManagerSettings, ui_locales: newLanguageCode }))
  }, [])

  const userEmail = userData?.profile?.email ?? ''
  const familyName = userData?.profile?.family_name
  const givenName = userData?.profile?.given_name
  const partnerId = userData?.profile?.sub ?? ''
  const userInitials =
    (givenName?.length ? givenName.charAt(0).toUpperCase() : '') +
    (familyName?.length ? familyName.charAt(0).toUpperCase() : '')
  const userFullName =
    (givenName?.length ? givenName : '') + (familyName?.length ? ` ${familyName}` : '')
  const isCustomer = (userData?.profile.is_customer as boolean | undefined) ?? true

  const signIn = useCallback(async () => {
    const cleanSearch = new URLSearchParams(search)
    cleanSearch.delete('autosignin')
    const state = `${pathname}?${cleanSearch.toString()}${hash}`
    await userManager.signinRedirect({ state })
  }, [hash, pathname, search, userManager])

  const signOut = useCallback(async () => {
    setSignoutRedirect({ pathname, search, hash, timestamp: Date.now() })
    await userManager.signoutRedirect()
  }, [hash, pathname, search, setSignoutRedirect, userManager])

  const getUser = useCallback(async () => userManager.getUser(), [userManager])
  const removeUser = useCallback(async () => userManager.removeUser(), [userManager])
  const signinRedirectCallback = useCallback(
    async () => userManager.signinRedirectCallback(),
    [userManager]
  )
  const signoutRedirectCallback = useCallback(
    async () => userManager.signoutRedirectCallback(),
    [userManager]
  )

  const authContextValue = useMemo(
    () => ({
      isAuthLoading,
      authError,
      isAuthenticated: !!userData?.access_token?.length && userData?.expired === false,
      userData,
      userInitials,
      userFullName,
      userEmail,
      partnerId,
      accessToken: userData?.access_token,
      getUser,
      removeUser,
      setUserData,
      signIn,
      signOut,
      signinRedirectCallback,
      signoutRedirectCallback,
      userManagerLanguage,
      updateAuthLanguage,
      isCustomer,
      isLoginModalOpen,
      setIsLoginModalOpen
    }),
    [
      authError,
      getUser,
      isAuthLoading,
      isCustomer,
      isLoginModalOpen,
      partnerId,
      removeUser,
      signIn,
      signOut,
      signinRedirectCallback,
      signoutRedirectCallback,
      updateAuthLanguage,
      userData,
      userEmail,
      userFullName,
      userInitials,
      userManagerLanguage
    ]
  )

  useEffect(() => {
    if (!authContextValue.isAuthenticated && !isAuthLoading && search.includes('autosignin')) {
      signIn()
    }
  }, [authContextValue.isAuthenticated, isAuthLoading, search, signIn])

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (context == null) {
    throw new Error('useAuthContext must be used within AuthContextProvider')
  }
  return context
}
